import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    useClickOutside(this, { element: this.details })
  }

  open() {
    this.details.open = true
  }

  close() {
    this.details.open = false
  }

  toggle() {
    this.details.open = !this.details.open
  }

  // Private

  clickOutside(event) {
    this.close()
  }

  get details() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }
}
