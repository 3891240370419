import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["root", "startFade", "endFade", "icon"]
  static values = {
    direction: {
      type: String,
      default: "vertical",
    },
    icons: Object,
  }
  static classes = ["fade"]

  connect() {
    if (!this.hasIconsValue) {
      if (this.directionVertical) {
        this.iconsValue = {
          scrollable: "swipe_down",
          scrolling: "swipe_vertical",
          scrolled: "swipe_up",
        }
      }
      if (this.directionHorizontal) {
        this.iconsValue = {
          scrollable: "swipe_right",
          scrolling: "swipe",
          scrolled: "swipe_left",
        }
      }
    }

    this.showHideFades()
    this.changeIcon()
  }

  scroll() {
    this.showHideFades()
    this.changeIcon()
  }

  // Private

  showHideFades() {
    if (this.hasStartFadeTarget && this.hasFadeClass) {
      if (this.scrollable) {
        this.fadeClasses.forEach((className) => {
          this.startFadeTarget.classList.add(className)
        })
      } else {
        this.fadeClasses.forEach((className) => {
          this.startFadeTarget.classList.remove(className)
        })
      }
    }

    if (this.hasEndFadeTarget && this.hasFadeClass) {
      if (this.scrolled) {
        this.fadeClasses.forEach((className) => {
          this.endFadeTarget.classList.add(className)
        })
      } else {
        this.fadeClasses.forEach((className) => {
          this.endFadeTarget.classList.remove(className)
        })
      }
    }
  }

  changeIcon() {
    if (this.hasIconTarget) {
      if (this.scrollable)
        this.iconTarget.innerText = this.iconsValue.scrollable
      if (this.scrolling) this.iconTarget.innerText = this.iconsValue.scrolling
      if (this.scrolled) this.iconTarget.innerText = this.iconsValue.scrolled
    }
  }

  get scrollable() {
    if (this.directionVertical) return this.rootTarget.scrollTop == 0
    if (this.directionHorizontal) return this.rootTarget.scrollLeft <= 0
  }

  get scrolling() {
    return !this.scrollable && !this.scrolled
  }

  get scrolled() {
    if (this.directionVertical)
      return (
        this.rootTarget.scrollTop + this.rootTarget.clientHeight >=
        this.rootTarget.scrollHeight
      )
    if (this.directionHorizontal)
      return (
        this.rootTarget.scrollLeft + this.rootTarget.clientWidth >=
        this.rootTarget.scrollWidth
      )
  }

  get directionVertical() {
    return this.directionValue == "vertical"
  }

  get directionHorizontal() {
    return this.directionValue == "horizontal"
  }
}
