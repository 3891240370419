import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    active: {
      type: Boolean,
      default: false,
    },
  }

  toggle() {
    this.activeValue = !this.activeValue
  }

  start() {
    this.activeValue = true
  }

  stop() {
    this.activeValue = false
  }

  activeValueChanged() {
    this.element.dataset.zoom = this.activeValue

    if (this.activeValue) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = null
    }
  }
}
