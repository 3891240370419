{
  "type": "Feature",
  "properties": {
    "AREA_ID": 8,
    "AREA_NAME": "Piambello e Valli del Verbano",
    "center": {
      "longitude": 8.769111949577251,
      "latitude": 45.943389414717245
    },
    "zoom": 9
  },
  "id": 8,
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [
          8.737996671120776,
          45.867202077019584
        ],
        [
          8.73760142403977,
          45.866409141697304
        ],
        [
          8.737605065880354,
          45.865635092970116
        ],
        [
          8.737481016991527,
          45.86461776540601
        ],
        [
          8.73686973053429,
          45.863194271441245
        ],
        [
          8.736347613544915,
          45.861996007266136
        ],
        [
          8.73626169122574,
          45.86109576315922
        ],
        [
          8.73631806756658,
          45.860069835736596
        ],
        [
          8.736347331280168,
          45.85933186378784
        ],
        [
          8.736259373008638,
          45.85886362846028
        ],
        [
          8.735708079854039,
          45.858394321418906
        ],
        [
          8.734564510628026,
          45.85786964747843
        ],
        [
          8.733550511918606,
          45.857192250003415
        ],
        [
          8.732872720334724,
          45.85616461199084
        ],
        [
          8.732775119187167,
          45.855111320931684
        ],
        [
          8.73277826612177,
          45.85445428346812
        ],
        [
          8.732536996036218,
          45.853733688043036
        ],
        [
          8.732345782870485,
          45.85331921458275
        ],
        [
          8.732323800962003,
          45.852617122359874
        ],
        [
          8.732260507326874,
          45.852382966178865
        ],
        [
          8.732532543752814,
          45.85205958594158
        ],
        [
          8.732663119054262,
          45.85169086135882
        ],
        [
          8.733012832747994,
          45.85119665957649
        ],
        [
          8.733156530590822,
          45.85077395793485
        ],
        [
          8.7331746882864,
          45.84975695592899
        ],
        [
          8.733138714431098,
          45.84919882932639
        ],
        [
          8.732908395199672,
          45.84888326979149
        ],
        [
          8.732278048003097,
          45.84880979537201
        ],
        [
          8.731879371591841,
          45.84869184757484
        ],
        [
          8.732241893466197,
          45.84829668170704
        ],
        [
          8.73300406260635,
          45.8477314254005
        ],
        [
          8.733071030710517,
          45.84719155014372
        ],
        [
          8.732329079804204,
          45.846226760426354
        ],
        [
          8.731535948670205,
          45.84519884044606
        ],
        [
          8.731125745906267,
          45.84480185889065
        ],
        [
          8.730637565709523,
          45.844638702068366
        ],
        [
          8.729915896887988,
          45.84473600241483
        ],
        [
          8.729019867683055,
          45.844733881023544
        ],
        [
          8.728602723895479,
          45.84473289101903
        ],
        [
          8.728242348204619,
          45.844687038751125
        ],
        [
          8.728232558279226,
          45.84461212243234
        ],
        [
          8.728168228577834,
          45.84411982415956
        ],
        [
          8.728105689179005,
          45.84374165040746
        ],
        [
          8.72752621094526,
          45.84373127399211
        ],
        [
          8.727554997469099,
          45.84362080002768
        ],
        [
          8.727591847145685,
          45.84347941383183
        ],
        [
          8.727634485154796,
          45.84322749790664
        ],
        [
          8.727643719004739,
          45.84317282779801
        ],
        [
          8.727713330773597,
          45.84275967013813
        ],
        [
          8.727721398026372,
          45.842711799889436
        ],
        [
          8.727737717059412,
          45.8426157106057
        ],
        [
          8.728137705029216,
          45.84254014528463
        ],
        [
          8.729000511036508,
          45.84237569007424
        ],
        [
          8.730096906848487,
          45.84200927320014
        ],
        [
          8.7306255240652,
          45.84179450355956
        ],
        [
          8.730922720946474,
          45.841588180143624
        ],
        [
          8.731451899458852,
          45.84133742629521
        ],
        [
          8.731646637668934,
          45.841195545638605
        ],
        [
          8.731753561098364,
          45.84111762019818
        ],
        [
          8.73180194131476,
          45.84110422206443
        ],
        [
          8.73228217313388,
          45.84088982888364
        ],
        [
          8.732323377080355,
          45.84087143728373
        ],
        [
          8.732522258257232,
          45.84078325880322
        ],
        [
          8.732626231583687,
          45.84073714185332
        ],
        [
          8.733594161977948,
          45.84029837415396
        ],
        [
          8.734689894785275,
          45.83996791124023
        ],
        [
          8.735501453872835,
          45.83982577497584
        ],
        [
          8.735734048956092,
          45.839655308027055
        ],
        [
          8.735723496025374,
          45.83916024425212
        ],
        [
          8.735919652325308,
          45.838530674509876
        ],
        [
          8.736217724698218,
          45.83812633226412
        ],
        [
          8.736618395186326,
          45.8378122335843
        ],
        [
          8.737186359637084,
          45.837516529493314
        ],
        [
          8.737676468109127,
          45.83726563877745
        ],
        [
          8.737944477511224,
          45.83717940150544
        ],
        [
          8.738191578676147,
          45.83710264554051
        ],
        [
          8.738379878523206,
          45.83727351137223
        ],
        [
          8.738566195536325,
          45.837208928239754
        ],
        [
          8.738758332767125,
          45.83730937699469
        ],
        [
          8.738994638733786,
          45.837379153256826
        ],
        [
          8.739121117801403,
          45.83739867548076
        ],
        [
          8.739164818480905,
          45.837468011493186
        ],
        [
          8.739577976342778,
          45.837376637364684
        ],
        [
          8.739820196862965,
          45.83735795665818
        ],
        [
          8.73983857028179,
          45.83695796211585
        ],
        [
          8.739580574293354,
          45.83674379365629
        ],
        [
          8.73971320519887,
          45.836703257407024
        ],
        [
          8.739830085328506,
          45.8364875045545
        ],
        [
          8.739831175829814,
          45.83625349848321
        ],
        [
          8.739819439572184,
          45.8360104688907
        ],
        [
          8.73973102522949,
          45.835641236359265
        ],
        [
          8.739489007003534,
          45.83516365444878
        ],
        [
          8.739467976219832,
          45.835138242786336
        ],
        [
          8.739220120453087,
          45.834839025513574
        ],
        [
          8.738989826969018,
          45.83452347569744
        ],
        [
          8.738965225157543,
          45.834280416554165
        ],
        [
          8.739296337396977,
          45.83411464861171
        ],
        [
          8.7393782204572,
          45.83407433716081
        ],
        [
          8.740266499425099,
          45.83402235157568
        ],
        [
          8.740318415478614,
          45.83401797196806
        ],
        [
          8.740568728709468,
          45.83399302923159
        ],
        [
          8.741245510717196,
          45.83392556693607
        ],
        [
          8.741490545598412,
          45.83389609552105
        ],
        [
          8.741649327260326,
          45.833876989012786
        ],
        [
          8.74261070448625,
          45.83376664288732
        ],
        [
          8.74394807358278,
          45.833612135981376
        ],
        [
          8.744439631776736,
          45.833554730665945
        ],
        [
          8.745006375114613,
          45.83342099305419
        ],
        [
          8.74625663303201,
          45.833045749724086
        ],
        [
          8.74665692851761,
          45.83279463882436
        ],
        [
          8.746777118897349,
          45.832661172353994
        ],
        [
          8.746851036205637,
          45.83257905164529
        ],
        [
          8.746934954590577,
          45.83252972426549
        ],
        [
          8.747650669494957,
          45.83210378974886
        ],
        [
          8.748863886840939,
          45.831458427502184
        ],
        [
          8.74975312279994,
          45.831181354909816
        ],
        [
          8.75009478577791,
          45.83105681321157
        ],
        [
          8.751029436366897,
          45.83071610833288
        ],
        [
          8.751739860913785,
          45.83020462259958
        ],
        [
          8.752243985061218,
          45.829665683429894
        ],
        [
          8.751203558393147,
          45.82920439942943
        ],
        [
          8.750884179633768,
          45.82864567879527
        ],
        [
          8.750409722017805,
          45.82822160175618
        ],
        [
          8.750142608510915,
          45.827581979426526
        ],
        [
          8.750106728646003,
          45.82687987329098
        ],
        [
          8.750300842687084,
          45.82665526592358
        ],
        [
          8.751407691213265,
          45.82672969959573
        ],
        [
          8.752732818766289,
          45.82684956940802
        ],
        [
          8.753364773902218,
          45.82650892690603
        ],
        [
          8.754139794984244,
          45.82604965729301
        ],
        [
          8.754488852039078,
          45.825849745247304
        ],
        [
          8.754566959058094,
          45.82580506384199
        ],
        [
          8.755114483542261,
          45.82549112588134
        ],
        [
          8.75523649595812,
          45.82542162707885
        ],
        [
          8.755276197865646,
          45.82494693733684
        ],
        [
          8.75468479525452,
          45.824855660242356
        ],
        [
          8.754079892899963,
          45.824818360175996
        ],
        [
          8.753487422294356,
          45.82497009682848
        ],
        [
          8.752959254113966,
          45.82504995095664
        ],
        [
          8.752857198429,
          45.82483371259553
        ],
        [
          8.752923494124062,
          45.82440183950389
        ],
        [
          8.75341489893175,
          45.82389886512405
        ],
        [
          8.753647614222752,
          45.823584343395105
        ],
        [
          8.753468894317592,
          45.823241949444544
        ],
        [
          8.753354955496688,
          45.82289967794408
        ],
        [
          8.753858442591516,
          45.82248673568264
        ],
        [
          8.754555013280953,
          45.82216421687209
        ],
        [
          8.754956470354818,
          45.821526036586384
        ],
        [
          8.754993117317184,
          45.82107533034557
        ],
        [
          8.754859022925649,
          45.82095346223025
        ],
        [
          8.75482735416533,
          45.820836386092566
        ],
        [
          8.754756964172831,
          45.820742628482336
        ],
        [
          8.754576849565955,
          45.820589264347156
        ],
        [
          8.75428115800223,
          45.82033748695858
        ],
        [
          8.75407189928348,
          45.82016510851039
        ],
        [
          8.753920739788066,
          45.8200405887604
        ],
        [
          8.754004548593333,
          45.820009268810765
        ],
        [
          8.753920953887283,
          45.81999198761146
        ],
        [
          8.753417151765705,
          45.81980728682322
        ],
        [
          8.753354147250718,
          45.81979094970384
        ],
        [
          8.752959116766647,
          45.81975679578372
        ],
        [
          8.75261951794479,
          45.81970205726659
        ],
        [
          8.752386571467506,
          45.81965000698125
        ],
        [
          8.752245291445863,
          45.81961844063978
        ],
        [
          8.752167824484362,
          45.81956768369076
        ],
        [
          8.752039924840552,
          45.81948388574384
        ],
        [
          8.752075224092025,
          45.81936155373845
        ],
        [
          8.75201655770968,
          45.81923811776376
        ],
        [
          8.752016917273878,
          45.8191571200491
        ],
        [
          8.740184844605958,
          45.811607992761424
        ],
        [
          8.742370102340217,
          45.81308731555921
        ],
        [
          8.74102748158851,
          45.81404734013331
        ],
        [
          8.735718356481154,
          45.818400473421725
        ],
        [
          8.735624256832763,
          45.818481398257276
        ],
        [
          8.729154241262746,
          45.82404643955975
        ],
        [
          8.723002690348524,
          45.82922502705835
        ],
        [
          8.717975081288898,
          45.833442983215065
        ],
        [
          8.717772645024379,
          45.83360898929202
        ],
        [
          8.716914388361582,
          45.834326919552254
        ],
        [
          8.709912347645092,
          45.834292048805935
        ],
        [
          8.705986524583855,
          45.8342723166448
        ],
        [
          8.704772411591419,
          45.83440776677865
        ],
        [
          8.704396021452817,
          45.834449762589706
        ],
        [
          8.70402304754834,
          45.834491373583056
        ],
        [
          8.703619642826004,
          45.83453637686682
        ],
        [
          8.7033844397526,
          45.834562606375066
        ],
        [
          8.703305100753335,
          45.834575893209255
        ],
        [
          8.703178587809107,
          45.8345987538133
        ],
        [
          8.703083984406783,
          45.83461583330082
        ],
        [
          8.702351618661366,
          45.83492443543622
        ],
        [
          8.702116086461434,
          45.835022069605905
        ],
        [
          8.701866066504774,
          45.8351256922965
        ],
        [
          8.70164635059856,
          45.83527813366323
        ],
        [
          8.701335681613996,
          45.83558332834562
        ],
        [
          8.701230366115283,
          45.836015090294644
        ],
        [
          8.701202173517714,
          45.83647403673068
        ],
        [
          8.701213210491122,
          45.83681609133209
        ],
        [
          8.701236518685906,
          45.83727517506729
        ],
        [
          8.701053304555693,
          45.837823724680696
        ],
        [
          8.7006363147484,
          45.83844368185062
        ],
        [
          8.7002468534735,
          45.83902768924
        ],
        [
          8.70003919075374,
          45.839333153970315
        ],
        [
          8.699895488430226,
          45.83971080192203
        ],
        [
          8.699826217885807,
          45.83998318878096
        ],
        [
          8.699737630567716,
          45.84033143589254
        ],
        [
          8.699230179496682,
          45.84130215168974
        ],
        [
          8.698490525440725,
          45.84234426998332
        ],
        [
          8.698150553660083,
          45.843297413153664
        ],
        [
          8.69794006120981,
          45.84411591378656
        ],
        [
          8.697756848088742,
          45.84439129754033
        ],
        [
          8.697524573648082,
          45.844740360496175
        ],
        [
          8.69694345570947,
          45.845035831322484
        ],
        [
          8.696441102092187,
          45.84505248916825
        ],
        [
          8.695926098064128,
          45.84502413337794
        ],
        [
          8.694830873617377,
          45.845201213799434
        ],
        [
          8.694301222435387,
          45.845496808606306
        ],
        [
          8.6939782761705,
          45.84567596321715
        ],
        [
          8.694014001176011,
          45.84613507917365
        ],
        [
          8.694153889508758,
          45.84645948757041
        ],
        [
          8.69447388337865,
          45.846820359144836
        ],
        [
          8.694652315630803,
          45.84716286392308
        ],
        [
          8.694457661498666,
          45.84743234958609
        ],
        [
          8.693682405167877,
          45.84788929591672
        ],
        [
          8.693104189098205,
          45.84829028403813
        ],
        [
          8.69268689880949,
          45.84857966910393
        ],
        [
          8.692218785299271,
          45.84938844942359
        ],
        [
          8.692614556085854,
          45.84994755828709
        ],
        [
          8.692780263604721,
          45.850263023514714
        ],
        [
          8.692494092539107,
          45.85077528437998
        ],
        [
          8.692028489803377,
          45.85112504961891
        ],
        [
          8.691421458980091,
          45.8514294209855
        ],
        [
          8.690685712695828,
          45.85171544679543
        ],
        [
          8.689368200931257,
          45.85238691105832
        ],
        [
          8.688695768838405,
          45.852862110115176
        ],
        [
          8.687971707144031,
          45.85335517346598
        ],
        [
          8.687068535400906,
          45.8537037235462
        ],
        [
          8.685780862420431,
          45.85363718493475
        ],
        [
          8.684455362060664,
          45.85342651177626
        ],
        [
          8.683117447030702,
          45.8532067927249
        ],
        [
          8.682453221614322,
          45.85315095790697
        ],
        [
          8.682279642698457,
          45.853128843408015
        ],
        [
          8.681879819439448,
          45.85340137925397
        ],
        [
          8.68177570532966,
          45.853590082636934
        ],
        [
          8.681373375995456,
          45.85412000018071
        ],
        [
          8.680958786355664,
          45.8545418642865
        ],
        [
          8.68073656510979,
          45.85510828004073
        ],
        [
          8.680630022323072,
          45.855720015261504
        ],
        [
          8.680182875289255,
          45.85633254814292
        ],
        [
          8.679980875701316,
          45.85660925253193
        ],
        [
          8.679514993349619,
          45.85698597188469
        ],
        [
          8.678430744221641,
          45.85736994417583
        ],
        [
          8.676158395872964,
          45.858272572147705
        ],
        [
          8.675328023841693,
          45.85831166975541
        ],
        [
          8.67507643512783,
          45.8583235077348
        ],
        [
          8.674790973702319,
          45.85861071308557
        ],
        [
          8.674284970808596,
          45.85923029963082
        ],
        [
          8.673896036205097,
          45.85965222707359
        ],
        [
          8.673611065307382,
          45.859921420670624
        ],
        [
          8.673235969845972,
          45.86018135978902
        ],
        [
          8.672898570578718,
          45.86060341582723
        ],
        [
          8.672315122244786,
          45.8612407791573
        ],
        [
          8.672372221871829,
          45.861311855662464
        ],
        [
          8.672532539676233,
          45.86151142757313
        ],
        [
          8.672942759398058,
          45.86185460869599
        ],
        [
          8.67279643764058,
          45.8625742405387
        ],
        [
          8.672536088893368,
          45.86303251369851
        ],
        [
          8.672122420225616,
          45.863274349047664
        ],
        [
          8.671018110377787,
          45.86273114112935
        ],
        [
          8.670837994767282,
          45.86299399428516
        ],
        [
          8.670550307599262,
          45.86341382552843
        ],
        [
          8.671024667940726,
          45.863802229052
        ],
        [
          8.670851143657792,
          45.8639624839266
        ],
        [
          8.670713804195595,
          45.86408934991415
        ],
        [
          8.670111171185097,
          45.864623129051964
        ],
        [
          8.669884521601094,
          45.86482499796534
        ],
        [
          8.66911996531443,
          45.86557881677008
        ],
        [
          8.669067785631686,
          45.86564365871971
        ],
        [
          8.668553781805846,
          45.86628275328862
        ],
        [
          8.668224381304205,
          45.86669228320674
        ],
        [
          8.66769156588829,
          45.867464773145954
        ],
        [
          8.667856971631394,
          45.867811777337984
        ],
        [
          8.667135406019339,
          45.86782768512681
        ],
        [
          8.66636174127047,
          45.867933429232856
        ],
        [
          8.665007495190425,
          45.868172486705994
        ],
        [
          8.663807964321137,
          45.86839397689834
        ],
        [
          8.663777815573978,
          45.8691229134772
        ],
        [
          8.663980310764309,
          45.86972654453258
        ],
        [
          8.664258986626937,
          45.8705194097528
        ],
        [
          8.664587442165706,
          45.87160943313554
        ],
        [
          8.665190138497799,
          45.872088229858846
        ],
        [
          8.66547202919941,
          45.872350066029185
        ],
        [
          8.66592180562114,
          45.8725493894894
        ],
        [
          8.666851721565516,
          45.871976073629625
        ],
        [
          8.667469438785618,
          45.87210388060084
        ],
        [
          8.668083544686713,
          45.87283470882551
        ],
        [
          8.667758744391556,
          45.873283787913394
        ],
        [
          8.667496717994968,
          45.87401206842187
        ],
        [
          8.667878539299485,
          45.8748052269933
        ],
        [
          8.668545746220405,
          45.87521217174702
        ],
        [
          8.668955615318165,
          45.875627383196985
        ],
        [
          8.669130505235692,
          45.87662694029988
        ],
        [
          8.669304506918772,
          45.87768842622088
        ],
        [
          8.669328294541973,
          45.877833581099935
        ],
        [
          8.669915663562023,
          45.878744340274594
        ],
        [
          8.669924606464791,
          45.87882099040935
        ],
        [
          8.669983392563358,
          45.87932605990154
        ],
        [
          8.670038296193399,
          45.879797731508546
        ],
        [
          8.669544394714505,
          45.88050735582447
        ],
        [
          8.66944874217852,
          45.88142511596469
        ],
        [
          8.66945589271975,
          45.88145214095826
        ],
        [
          8.669469831523346,
          45.88150491631993
        ],
        [
          8.669517978913413,
          45.88168638217877
        ],
        [
          8.669289226601565,
          45.88185216909082
        ],
        [
          8.669154550019888,
          45.88193616646939
        ],
        [
          8.668872115848261,
          45.882070204324485
        ],
        [
          8.66842791352564,
          45.882280986895154
        ],
        [
          8.667705833470771,
          45.88262611114833
        ],
        [
          8.667329372140413,
          45.88280604625314
        ],
        [
          8.666517018555517,
          45.88319430789639
        ],
        [
          8.667096475151222,
          45.88407148472817
        ],
        [
          8.667160118432237,
          45.88419077056345
        ],
        [
          8.667313893937385,
          45.88501012182327
        ],
        [
          8.669628249256201,
          45.887896982934045
        ],
        [
          8.668050109036946,
          45.88886445184246
        ],
        [
          8.666941495133115,
          45.888897227249274
        ],
        [
          8.666245901756064,
          45.888832192279345
        ],
        [
          8.666022103047345,
          45.8896145885253
        ],
        [
          8.665878378915894,
          45.8897594629316
        ],
        [
          8.665425604601444,
          45.89021585886734
        ],
        [
          8.664997793628304,
          45.8906286286247
        ],
        [
          8.6645965024873,
          45.89091546459174
        ],
        [
          8.663952491671884,
          45.8908415746559
        ],
        [
          8.663426101154911,
          45.890498002919905
        ],
        [
          8.662517188465193,
          45.889974222561655
        ],
        [
          8.662295837991369,
          45.88984663964934
        ],
        [
          8.66207745753757,
          45.88976846536171
        ],
        [
          8.66133245689076,
          45.88950176505078
        ],
        [
          8.660483790437006,
          45.889112228673014
        ],
        [
          8.659802194132375,
          45.8889302027443
        ],
        [
          8.658785999810396,
          45.88860316032648
        ],
        [
          8.657976622195916,
          45.888177703364974
        ],
        [
          8.65699890606115,
          45.887949762360854
        ],
        [
          8.656189155841156,
          45.88752431046865
        ],
        [
          8.655430798020141,
          45.88738702286751
        ],
        [
          8.654440244621842,
          45.887024017806674
        ],
        [
          8.65355451520242,
          45.88651730542275
        ],
        [
          8.652937398174286,
          45.88627241311692
        ],
        [
          8.652357521226255,
          45.88626165533395
        ],
        [
          8.65122051864662,
          45.88671720951967
        ],
        [
          8.650084113882327,
          45.88707376728232
        ],
        [
          8.648935556589542,
          45.88731324612858
        ],
        [
          8.648314783783078,
          45.88765336005978
        ],
        [
          8.647771488303368,
          45.887966704259185
        ],
        [
          8.64656376706236,
          45.88755794791479
        ],
        [
          8.645791512231025,
          45.88733054627746
        ],
        [
          8.64471013941141,
          45.88713817904366
        ],
        [
          8.644464447889133,
          45.88726342227763
        ],
        [
          8.643785023966535,
          45.88675727586877
        ],
        [
          8.643450670591802,
          45.88663923973872
        ],
        [
          8.643011113127221,
          45.886853863883026
        ],
        [
          8.642597775828234,
          45.88699658931393
        ],
        [
          8.642122756202452,
          45.8867070875146
        ],
        [
          8.642203087741407,
          45.88630231075394
        ],
        [
          8.64185600057138,
          45.88616622001578
        ],
        [
          8.640877162093986,
          45.88600114206509
        ],
        [
          8.64040259348199,
          45.88564862986473
        ],
        [
          8.640135712390434,
          45.88531478087204
        ],
        [
          8.639569072695828,
          45.88512398384697
        ],
        [
          8.639171299971748,
          45.88485270446409
        ],
        [
          8.638677660127785,
          45.885454178467
        ],
        [
          8.637745255520139,
          45.886144267282354
        ],
        [
          8.636414060747022,
          45.886716068872964
        ],
        [
          8.635250448016535,
          45.88727939661122
        ],
        [
          8.633969784467329,
          45.8880583313288
        ],
        [
          8.63329647588326,
          45.888533198749386
        ],
        [
          8.632818231461934,
          45.88873866614334
        ],
        [
          8.632370452907345,
          45.88864633193995
        ],
        [
          8.632020234038134,
          45.88857409244109
        ],
        [
          8.631720230330615,
          45.88828107238833
        ],
        [
          8.631021777660647,
          45.887598829418486
        ],
        [
          8.630421147562975,
          45.886827349552334
        ],
        [
          8.630152172324822,
          45.88657446449376
        ],
        [
          8.629068056478783,
          45.88680045863672
        ],
        [
          8.628372596169184,
          45.88672620018666
        ],
        [
          8.628196067670256,
          45.88614059962215
        ],
        [
          8.627789742858429,
          45.8854102356522
        ],
        [
          8.62703484715596,
          45.88458872496311
        ],
        [
          8.626421153445357,
          45.883857696913864
        ],
        [
          8.62621663652345,
          45.883605013164775
        ],
        [
          8.625679809765083,
          45.88294621149319
        ],
        [
          8.625323144764963,
          45.882324017331676
        ],
        [
          8.625120332054848,
          45.88181932120496
        ],
        [
          8.625046785398093,
          45.881261060075424
        ],
        [
          8.625051606072295,
          45.880721044488396
        ],
        [
          8.624625025042475,
          45.88091765223501
        ],
        [
          8.623503240683076,
          45.881012964938265
        ],
        [
          8.622676736005781,
          45.88127124991194
        ],
        [
          8.62219674600555,
          45.88173769463408
        ],
        [
          8.621071759535361,
          45.882300986385225
        ],
        [
          8.619987107680078,
          45.882612402348975
        ],
        [
          8.618681969265642,
          45.883130095575225
        ],
        [
          8.617185088796857,
          45.88341310606473
        ],
        [
          8.615894522221728,
          45.883678779644356
        ],
        [
          8.614461814734018,
          45.88399797046379
        ],
        [
          8.612795655448192,
          45.884523371068056
        ],
        [
          8.612392601910495,
          45.885035031395326
        ],
        [
          8.612077906936252,
          45.88580800025651
        ],
        [
          8.611088377444993,
          45.885399609253014
        ],
        [
          8.609764129922851,
          45.885116092534346
        ],
        [
          8.608589551068318,
          45.88537308715215
        ],
        [
          8.607232227412618,
          45.885953453641854
        ],
        [
          8.606045799512241,
          45.886419022652795
        ],
        [
          8.606004458127645,
          45.886435248484354
        ],
        [
          8.606283340271474,
          45.88709325378222
        ],
        [
          8.606405152309652,
          45.88742966856641
        ],
        [
          8.606485619829071,
          45.88765196984001
        ],
        [
          8.605903293343614,
          45.88798299065903
        ],
        [
          8.605577970546241,
          45.88842287788685
        ],
        [
          8.605524969091823,
          45.888683723720895
        ],
        [
          8.60547104726245,
          45.888962537692095
        ],
        [
          8.604926285783373,
          45.889446678587426
        ],
        [
          8.604038591302292,
          45.88921859295278
        ],
        [
          8.603664989260107,
          45.88916609288649
        ],
        [
          8.603099065726159,
          45.889086561226954
        ],
        [
          8.603039312543814,
          45.88907816589143
        ],
        [
          8.602725250371712,
          45.88903403844459
        ],
        [
          8.601564983410405,
          45.88907499242768
        ],
        [
          8.601146685643837,
          45.88867685565527
        ],
        [
          8.601014526116055,
          45.88855105024873
        ],
        [
          8.60036101484106,
          45.88801773367793
        ],
        [
          8.59947437931172,
          45.88773253127202
        ],
        [
          8.599088028828039,
          45.887608265227875
        ],
        [
          8.598463011003973,
          45.88758275212705
        ],
        [
          8.598384490791883,
          45.88757845745303
        ],
        [
          8.598302881081088,
          45.88757400515598
        ],
        [
          8.598118424108113,
          45.88760049138265
        ],
        [
          8.578093343583259,
          45.8904758292976
        ],
        [
          8.575420859627325,
          45.89069094129913
        ],
        [
          8.570141405797285,
          45.89125173004415
        ],
        [
          8.569446777715582,
          45.89412925941137
        ],
        [
          8.570638301810645,
          45.90003353058814
        ],
        [
          8.571626230188842,
          45.90133641858569
        ],
        [
          8.571723095020443,
          45.90145968359704
        ],
        [
          8.572730947456026,
          45.90278651381988
        ],
        [
          8.573973378941236,
          45.90379019777589
        ],
        [
          8.575268925636937,
          45.904596058908254
        ],
        [
          8.576217454476419,
          45.90531962513337
        ],
        [
          8.578083351999156,
          45.90615457023923
        ],
        [
          8.582672070217773,
          45.91183267632424
        ],
        [
          8.587887044173003,
          45.91692784632608
        ],
        [
          8.593841050901396,
          45.92183189422793
        ],
        [
          8.600141598358912,
          45.92621930377263
        ],
        [
          8.608711610386143,
          45.932453067318576
        ],
        [
          8.608853921384458,
          45.93255423542468
        ],
        [
          8.616877698794358,
          45.93837766472591
        ],
        [
          8.654099816918281,
          45.966086989714675
        ],
        [
          8.682299171090865,
          45.98688761251797
        ],
        [
          8.690564163016463,
          45.99275597131223
        ],
        [
          8.7007042304724,
          45.99993850693617
        ],
        [
          8.701175382907756,
          46.000245463641235
        ],
        [
          8.703790879517207,
          46.00192631791803
        ],
        [
          8.705013497343506,
          46.00265850893967
        ],
        [
          8.705850106136038,
          46.00320068224169
        ],
        [
          8.706893316728555,
          46.00375237794617
        ],
        [
          8.707832436507095,
          46.00422278414713
        ],
        [
          8.70899169813152,
          46.00481976790871
        ],
        [
          8.709983566717986,
          46.005364817953335
        ],
        [
          8.710060622603587,
          46.005407498665456
        ],
        [
          8.711090500305064,
          46.005977128084346
        ],
        [
          8.711888840857817,
          46.00644716460023
        ],
        [
          8.712384925657263,
          46.00675441388322
        ],
        [
          8.714758437699352,
          46.008206401331876
        ],
        [
          8.716053064859576,
          46.0090406520839
        ],
        [
          8.717557886117117,
          46.010061413052114
        ],
        [
          8.719322009718312,
          46.01114579792569
        ],
        [
          8.720801688907526,
          46.012292451443045
        ],
        [
          8.72116187826062,
          46.01259034251274
        ],
        [
          8.722113786591741,
          46.0133846897683
        ],
        [
          8.723052113907585,
          46.01431398385451
        ],
        [
          8.724245838185096,
          46.015522902906056
        ],
        [
          8.725402316898299,
          46.01676772643081
        ],
        [
          8.726429854197331,
          46.01796721701196
        ],
        [
          8.727146255077338,
          46.019399973884745
        ],
        [
          8.728029211013721,
          46.02112113243216
        ],
        [
          8.728882234442095,
          46.0234452127895
        ],
        [
          8.729062333617296,
          46.02625371906625
        ],
        [
          8.729046535594154,
          46.02683869160297
        ],
        [
          8.728966654707154,
          46.02732453037954
        ],
        [
          8.728938076778658,
          46.02788247067325
        ],
        [
          8.728900406530268,
          46.02817486779428
        ],
        [
          8.728763492239619,
          46.029232088409216
        ],
        [
          8.72847438665474,
          46.030221428755986
        ],
        [
          8.728004542504392,
          46.031255351417144
        ],
        [
          8.727495496134297,
          46.03237916346938
        ],
        [
          8.72685717879326,
          46.03342165611801
        ],
        [
          8.726285105555394,
          46.034212312553024
        ],
        [
          8.725298405568232,
          46.035136968320344
        ],
        [
          8.72446819383299,
          46.03577398705966
        ],
        [
          8.723390418720665,
          46.03681541568883
        ],
        [
          8.722350784193411,
          46.03805491046833
        ],
        [
          8.721778190790609,
          46.03892655313729
        ],
        [
          8.721270108507674,
          46.039735331050345
        ],
        [
          8.720643142901224,
          46.0410478301704
        ],
        [
          8.720249625572873,
          46.04219889571779
        ],
        [
          8.719934017173198,
          46.04335014881259
        ],
        [
          8.719526274994456,
          46.04475318612827
        ],
        [
          8.719067291496739,
          46.046057091781975
        ],
        [
          8.718711805618085,
          46.04741524089706
        ],
        [
          8.71763166229256,
          46.051390680740134
        ],
        [
          8.717297626534883,
          46.05352289091959
        ],
        [
          8.716937858269112,
          46.055628050124724
        ],
        [
          8.716618667838453,
          46.05737330494332
        ],
        [
          8.716403980219269,
          46.058902788157326
        ],
        [
          8.71612753668089,
          46.05987413569656
        ],
        [
          8.715875973234102,
          46.06102553309392
        ],
        [
          8.71555930705035,
          46.06226677471277
        ],
        [
          8.71500923445332,
          46.06364243177143
        ],
        [
          8.713962528657868,
          46.06603385196057
        ],
        [
          8.713530016333987,
          46.06723879774398
        ],
        [
          8.711642135981728,
          46.07230114145779
        ],
        [
          8.71098963509666,
          46.07351451595317
        ],
        [
          8.710636855558095,
          46.07420664214882
        ],
        [
          8.710167366669092,
          46.07496147380314
        ],
        [
          8.709659109772796,
          46.075707191232155
        ],
        [
          8.709125038899982,
          46.07644384488936
        ],
        [
          8.708513115025983,
          46.07722531117983
        ],
        [
          8.707927088103805,
          46.07806982114405
        ],
        [
          8.706454876426523,
          46.08019008180097
        ],
        [
          8.706115304987103,
          46.08081023080134
        ],
        [
          8.705672484497015,
          46.08139409492581
        ],
        [
          8.705475617908666,
          46.08193360010189
        ],
        [
          8.705339571899055,
          46.08319326471898
        ],
        [
          8.705433737937279,
          46.08357151564329
        ],
        [
          8.705483848410683,
          46.08416647535788
        ],
        [
          8.705564916332804,
          46.085128887290956
        ],
        [
          8.706275448076154,
          46.08772275665225
        ],
        [
          8.707325715273138,
          46.089687475456095
        ],
        [
          8.707850130010726,
          46.090813831976995
        ],
        [
          8.709123738757084,
          46.09214910762343
        ],
        [
          8.71025511383271,
          46.0934029983912
        ],
        [
          8.711502910611419,
          46.09482816815516
        ],
        [
          8.712750577164122,
          46.095974333707254
        ],
        [
          8.713651538928321,
          46.09686761011561
        ],
        [
          8.714372802517943,
          46.09749041645707
        ],
        [
          8.71464304210517,
          46.09777009834053
        ],
        [
          8.714815174878549,
          46.098042791895544
        ],
        [
          8.715451437962354,
          46.099059128602605
        ],
        [
          8.716107813992382,
          46.09965477692695
        ],
        [
          8.716894447305092,
          46.100232737427504
        ],
        [
          8.717549802772584,
          46.100873367660824
        ],
        [
          8.717961289679897,
          46.10136938238616
        ],
        [
          8.718294199841184,
          46.101811211635244
        ],
        [
          8.718398625548133,
          46.10194646725612
        ],
        [
          8.719979228625762,
          46.10400237640899
        ],
        [
          8.722191217781992,
          46.10668081614053
        ],
        [
          8.723230870810767,
          46.1081233577459
        ],
        [
          8.72383470466126,
          46.108988828873606
        ],
        [
          8.724322606566657,
          46.109755016116274
        ],
        [
          8.724644040210588,
          46.110169797103055
        ],
        [
          8.730355973549289,
          46.11413445120288
        ],
        [
          8.735527391602476,
          46.117548584808176
        ],
        [
          8.740094531347967,
          46.120322083476786
        ],
        [
          8.74268749816189,
          46.122145972299336
        ],
        [
          8.745303051434465,
          46.11883978570019
        ],
        [
          8.749338462090492,
          46.113421657502805
        ],
        [
          8.752422427061365,
          46.1093603335614
        ],
        [
          8.754750478990283,
          46.106332311459745
        ],
        [
          8.755886316658675,
          46.10490624317034
        ],
        [
          8.756038600534755,
          46.10471504082161
        ],
        [
          8.756144335725622,
          46.104575769921055
        ],
        [
          8.756780303388975,
          46.10376260566358
        ],
        [
          8.75681237210928,
          46.103737273577345
        ],
        [
          8.757143848673078,
          46.103475374562514
        ],
        [
          8.757571283135517,
          46.10336828689329
        ],
        [
          8.758425583170196,
          46.103010096301404
        ],
        [
          8.759022553029329,
          46.102597347881925
        ],
        [
          8.7592835978592,
          46.10207587815217
        ],
        [
          8.760165028234866,
          46.10169974114114
        ],
        [
          8.761292019464207,
          46.10137808000977
        ],
        [
          8.762961860102386,
          46.10090456577371
        ],
        [
          8.764385407137063,
          46.10081750528422
        ],
        [
          8.765989510137498,
          46.10057778915242
        ],
        [
          8.767297311297726,
          46.10031945899615
        ],
        [
          8.768268126172456,
          46.10020442340893
        ],
        [
          8.768773489438946,
          46.10001644105368
        ],
        [
          8.76956447899193,
          46.0995860428136
        ],
        [
          8.77053471942258,
          46.09931799797634
        ],
        [
          8.771363275432261,
          46.09918464193291
        ],
        [
          8.772270064211021,
          46.098898449631605
        ],
        [
          8.773332993046592,
          46.098396568765864
        ],
        [
          8.774461191545631,
          46.09775077670075
        ],
        [
          8.775509787673714,
          46.09718584766322
        ],
        [
          8.777234237024498,
          46.09623520264435
        ],
        [
          8.778205827121113,
          46.09590407671172
        ],
        [
          8.779617010511362,
          46.09533079910895
        ],
        [
          8.781003249283987,
          46.09482045491998
        ],
        [
          8.782454124641268,
          46.09431923241073
        ],
        [
          8.783424607491265,
          46.093943066117
        ],
        [
          8.786321941238599,
          46.09404751853878
        ],
        [
          8.787833783447375,
          46.09417633752057
        ],
        [
          8.788493217651524,
          46.094258552409315
        ],
        [
          8.790769474716921,
          46.09440675295603
        ],
        [
          8.792902195804167,
          46.09456364389453
        ],
        [
          8.794816796822769,
          46.09455808305809
        ],
        [
          8.796975987898696,
          46.094444949482636
        ],
        [
          8.798812887130719,
          46.09446618696484
        ],
        [
          8.799756865441035,
          46.09457585772854
        ],
        [
          8.800362269246525,
          46.09529691710473
        ],
        [
          8.801532927848115,
          46.09679298261557
        ],
        [
          8.802409937531786,
          46.09755050199444
        ],
        [
          8.803674975431049,
          46.09833570293226
        ],
        [
          8.804849104728058,
          46.09923772265052
        ],
        [
          8.80564764328267,
          46.10025610090705
        ],
        [
          8.806148720261008,
          46.10091395195918
        ],
        [
          8.807819387866683,
          46.1004307701408
        ],
        [
          8.809037234931106,
          46.09993781347589
        ],
        [
          8.811082946583989,
          46.099131189515944
        ],
        [
          8.812728785366017,
          46.098314895815314
        ],
        [
          8.81408784121852,
          46.09775909708706
        ],
        [
          8.815292700281864,
          46.097257057638444
        ],
        [
          8.816369332691238,
          46.09639478008528
        ],
        [
          8.816514352632224,
          46.09557599167937
        ],
        [
          8.81684050956942,
          46.09474850127051
        ],
        [
          8.817463048756032,
          46.09426349279991
        ],
        [
          8.81818017578889,
          46.093999653749485
        ],
        [
          8.819119283379118,
          46.09365411947253
        ],
        [
          8.820491781234377,
          46.093269279114516
        ],
        [
          8.821100674186926,
          46.09299122396127
        ],
        [
          8.821529585098249,
          46.09237089328623
        ],
        [
          8.822217643778684,
          46.09122894451361
        ],
        [
          8.822685630490772,
          46.09050965164979
        ],
        [
          8.823437428994678,
          46.0900248107318
        ],
        [
          8.823904732158312,
          46.089512524478444
        ],
        [
          8.824565557876001,
          46.08916253444668
        ],
        [
          8.825587343621006,
          46.089191100543715
        ],
        [
          8.82625788831477,
          46.08947113078912
        ],
        [
          8.828048535639994,
          46.0880608215815
        ],
        [
          8.83271017222532,
          46.083450651162636
        ],
        [
          8.835715458981776,
          46.081888983475636
        ],
        [
          8.83713840452296,
          46.08138702475538
        ],
        [
          8.83870414956825,
          46.08111023371925
        ],
        [
          8.84030858022606,
          46.08086947524877
        ],
        [
          8.841758811663054,
          46.08027749305649
        ],
        [
          8.84293810760191,
          46.07943309537804
        ],
        [
          8.843960468249866,
          46.07863348048463
        ],
        [
          8.84471323200249,
          46.077680487503315
        ],
        [
          8.845297592027366,
          46.07682627757113
        ],
        [
          8.847136685580155,
          46.075847728295905
        ],
        [
          8.85205053920473,
          46.07552119675512
        ],
        [
          8.853980619933807,
          46.06909305943667
        ],
        [
          8.854000940638437,
          46.06613651511324
        ],
        [
          8.854517049153563,
          46.061628083098576
        ],
        [
          8.844302516987645,
          46.048537355858365
        ],
        [
          8.84335814282129,
          46.048824072311206
        ],
        [
          8.839965404312196,
          46.04985258410772
        ],
        [
          8.838068026565686,
          46.05042773063018
        ],
        [
          8.8344346376093,
          46.05134954446449
        ],
        [
          8.833932318627303,
          46.050745799765956
        ],
        [
          8.833520870767744,
          46.05001618145591
        ],
        [
          8.83305727291263,
          46.04943949356381
        ],
        [
          8.832051834950907,
          46.048520011219956
        ],
        [
          8.830853408296468,
          46.047312211374155
        ],
        [
          8.828426052978866,
          46.046471575308004
        ],
        [
          8.82853000306043,
          46.046291722251944
        ],
        [
          8.829267346009214,
          46.046094825447575
        ],
        [
          8.83047025632277,
          46.04580860904508
        ],
        [
          8.831737849640666,
          46.04550448366359
        ],
        [
          8.83321160108222,
          46.04486762967282
        ],
        [
          8.834170600567447,
          46.0440050049297
        ],
        [
          8.835153716744605,
          46.04372741580945
        ],
        [
          8.832332842767904,
          46.04054624187102
        ],
        [
          8.829914519334185,
          46.04158668149251
        ],
        [
          8.828052237607363,
          46.03364570090934
        ],
        [
          8.827975316091619,
          46.03331708256818
        ],
        [
          8.827551123956724,
          46.032726918692866
        ],
        [
          8.827010614181924,
          46.032015085803906
        ],
        [
          8.82631495631266,
          46.03133901669151
        ],
        [
          8.819682409752314,
          46.025271591962536
        ],
        [
          8.811898608276204,
          46.023530998342856
        ],
        [
          8.810518339806038,
          46.02301570435103
        ],
        [
          8.807886423360097,
          46.02217429171904
        ],
        [
          8.806415444215375,
          46.02175779626753
        ],
        [
          8.805941369985568,
          46.02160848356752
        ],
        [
          8.804321368054795,
          46.018757142667795
        ],
        [
          8.797228673635297,
          46.01199008700018
        ],
        [
          8.793073074436213,
          46.00795501863107
        ],
        [
          8.792383788206992,
          46.005766707713285
        ],
        [
          8.792063805961565,
          46.004983093888264
        ],
        [
          8.79118135051677,
          46.002668418165875
        ],
        [
          8.790319920275158,
          46.00025477485009
        ],
        [
          8.789991060809289,
          45.99981315135933
        ],
        [
          8.789248074630434,
          45.998326726267585
        ],
        [
          8.788801559610604,
          45.99688585765597
        ],
        [
          8.788368677252501,
          45.99515700174993
        ],
        [
          8.788022682454098,
          45.99446335032082
        ],
        [
          8.787308165502385,
          45.992211956054085
        ],
        [
          8.786682847427915,
          45.99028471078577
        ],
        [
          8.785902262349877,
          45.98901770652781
        ],
        [
          8.786804492277101,
          45.98887189517078
        ],
        [
          8.787579293544562,
          45.98873833781942
        ],
        [
          8.788457541741447,
          45.98856896617808
        ],
        [
          8.789184085764612,
          45.98841844372447
        ],
        [
          8.78974933065016,
          45.9883013317932
        ],
        [
          8.789835006678565,
          45.988321837401536
        ],
        [
          8.79096192544943,
          45.98859156420174
        ],
        [
          8.792108981229637,
          45.989043671981186
        ],
        [
          8.792482174565755,
          45.98937737176789
        ],
        [
          8.792350613974696,
          45.990142160037344
        ],
        [
          8.792399467090489,
          45.990889267089685
        ],
        [
          8.792448252033372,
          45.99143490444393
        ],
        [
          8.792461425428792,
          45.991582407363914
        ],
        [
          8.792847736437892,
          45.991871118829174
        ],
        [
          8.793673141175356,
          45.99202560273835
        ],
        [
          8.794357270002685,
          45.992089837377684
        ],
        [
          8.794654272299036,
          45.992090371399144
        ],
        [
          8.795239461654672,
          45.99206441665637
        ],
        [
          8.7956353796947,
          45.992047135387146
        ],
        [
          8.79614884500114,
          45.99199404663462
        ],
        [
          8.797611380254313,
          45.991834640379736
        ],
        [
          8.798489972465202,
          45.99157518694613
        ],
        [
          8.799355924834794,
          45.991342708337804
        ],
        [
          8.800711528178196,
          45.99129107443703
        ],
        [
          8.802183292971122,
          45.9912486358358
        ],
        [
          8.803629505677295,
          45.99112512483807
        ],
        [
          8.8049083938807,
          45.99096531219222
        ],
        [
          8.806070751719275,
          45.9907782810742
        ],
        [
          8.80653534669039,
          45.99073405767276
        ],
        [
          8.807143196262677,
          45.99068109454922
        ],
        [
          8.807413652074219,
          45.990654546830996
        ],
        [
          8.808343329246712,
          45.9905390995537
        ],
        [
          8.80908018651099,
          45.990288322744505
        ],
        [
          8.809700538832365,
          45.99011835949931
        ],
        [
          8.810088008165293,
          45.99009199932824
        ],
        [
          8.811106468536519,
          45.99020168340583
        ],
        [
          8.812022038205532,
          45.990554204373
        ],
        [
          8.812640771797357,
          45.990870235704364
        ],
        [
          8.813272389971969,
          45.991186281119575
        ],
        [
          8.813841815211335,
          45.990809194013785
        ],
        [
          8.814489107579412,
          45.990306225408055
        ],
        [
          8.81508441408536,
          45.98990219007658
        ],
        [
          8.815718096198479,
          45.989597194088745
        ],
        [
          8.816544786177337,
          45.989139516380014
        ],
        [
          8.817734626759762,
          45.98854739252297
        ],
        [
          8.818329684705933,
          45.98820634142343
        ],
        [
          8.818950953951205,
          45.987739303816745
        ],
        [
          8.819557905278025,
          45.9877042658484
        ],
        [
          8.819783946872489,
          45.98767761871281
        ],
        [
          8.821106595548708,
          45.9875446957161
        ],
        [
          8.822552635076045,
          45.98756494087493
        ],
        [
          8.823236486314691,
          45.98771001455225
        ],
        [
          8.824036446006879,
          45.987882246954356
        ],
        [
          8.8247204186702,
          45.98799131497115
        ],
        [
          8.825585694645984,
          45.987929634390404
        ],
        [
          8.826309198100503,
          45.98778672229788
        ],
        [
          8.826979247393075,
          45.987697732302195
        ],
        [
          8.827508741940955,
          45.9876625339203
        ],
        [
          8.827818569894596,
          45.98767201498181
        ],
        [
          8.828061472857163,
          45.987672380672514
        ],
        [
          8.828899963971907,
          45.987916636313365
        ],
        [
          8.829454769150464,
          45.98805246563864
        ],
        [
          8.830022592893355,
          45.988143319707746
        ],
        [
          8.83060362414051,
          45.98814418238576
        ],
        [
          8.831275503646657,
          45.98798317150236
        ],
        [
          8.831754282657974,
          45.98764186075545
        ],
        [
          8.83233655871277,
          45.98721970467022
        ],
        [
          8.832723436726685,
          45.98690527856039
        ],
        [
          8.833176656138388,
          45.98653691967745
        ],
        [
          8.83363511718564,
          45.98607658644623
        ],
        [
          8.833785310817953,
          45.985925787807226
        ],
        [
          8.834613737154875,
          45.985215949716185
        ],
        [
          8.835841158721065,
          45.98492071615273
        ],
        [
          8.83704215552517,
          45.98482343355479
        ],
        [
          8.838269294776264,
          45.98461817719354
        ],
        [
          8.838920143956724,
          45.98429180690971
        ],
        [
          8.83928950809398,
          45.98410660065764
        ],
        [
          8.839704831727627,
          45.98335115183293
        ],
        [
          8.839771917500771,
          45.982469207580564
        ],
        [
          8.839825632634303,
          45.981758262333
        ],
        [
          8.84013646602913,
          45.98140768383068
        ],
        [
          8.840962812355093,
          45.981372838832115
        ],
        [
          8.841594715399808,
          45.98161673194197
        ],
        [
          8.842135502141781,
          45.9815761720919
        ],
        [
          8.842390702493171,
          45.98155702230392
        ],
        [
          8.84253736923609,
          45.981546017206824
        ],
        [
          8.842848117701987,
          45.9810784407205
        ],
        [
          8.843043466028266,
          45.98047568577704
        ],
        [
          8.842993050060876,
          45.980043593037706
        ],
        [
          8.842955909841475,
          45.97949008268692
        ],
        [
          8.842943502609543,
          45.9793055011814
        ],
        [
          8.843020792461303,
          45.97906812068152
        ],
        [
          8.843151450697228,
          45.97866676346343
        ],
        [
          8.843372919421348,
          45.97795604708329
        ],
        [
          8.84372363120904,
          45.977191498397545
        ],
        [
          8.843971322186933,
          45.9769770229117
        ],
        [
          8.844557525597699,
          45.977024989123144
        ],
        [
          8.844627700556046,
          45.97703072508721
        ],
        [
          8.845350316308556,
          45.97699570637446
        ],
        [
          8.845578820468377,
          45.97683299773298
        ],
        [
          8.845880224101071,
          45.97661840525233
        ],
        [
          8.846643700070032,
          45.97595340663674
        ],
        [
          8.847070298248957,
          45.97571101741811
        ],
        [
          8.847354783797497,
          45.9755493548284
        ],
        [
          8.847587804533006,
          45.975306650044224
        ],
        [
          8.847717561633331,
          45.97506382522632
        ],
        [
          8.847815623861784,
          45.97490644910982
        ],
        [
          8.848495044909722,
          45.97383180657984
        ],
        [
          8.84916809230048,
          45.973157674978296
        ],
        [
          8.849460621530199,
          45.97304446048077
        ],
        [
          8.850744122301299,
          45.972547724914214
        ],
        [
          8.852462821326323,
          45.97167693050987
        ],
        [
          8.853781630484455,
          45.97067957481768
        ],
        [
          8.854557405915843,
          45.97017655986145
        ],
        [
          8.855152253680705,
          45.969601294678164
        ],
        [
          8.8555683501935,
          45.96919679996923
        ],
        [
          8.855734537985226,
          45.96903500367707
        ],
        [
          8.85621318969562,
          45.96862159233444
        ],
        [
          8.856743247298775,
          45.96828023847179
        ],
        [
          8.857054096917548,
          45.96813295100861
        ],
        [
          8.857595736261558,
          45.967876302278455
        ],
        [
          8.858410116762734,
          45.96739128101774
        ],
        [
          8.858797484599037,
          45.967166753051586
        ],
        [
          8.858991822215883,
          45.9670517501085
        ],
        [
          8.859154179530922,
          45.96695569556316
        ],
        [
          8.859244848283728,
          45.96690183728381
        ],
        [
          8.859857424670304,
          45.966538046982336
        ],
        [
          8.861046035079507,
          45.96590047453281
        ],
        [
          8.862079950946974,
          45.96534369407035
        ],
        [
          8.863757977755082,
          45.96509369650994
        ],
        [
          8.864971042525966,
          45.96497814269864
        ],
        [
          8.865811593835518,
          45.96429509593912
        ],
        [
          8.866225749629045,
          45.96364756151067
        ],
        [
          8.86698966540075,
          45.96262241794152
        ],
        [
          8.867598243893497,
          45.96161057903388
        ],
        [
          8.867948769728304,
          45.960728960936166
        ],
        [
          8.868233490293656,
          45.96038727137572
        ],
        [
          8.86929199402106,
          45.960100474744806
        ],
        [
          8.869650105583993,
          45.95996988281648
        ],
        [
          8.870325296926284,
          45.959723633815216
        ],
        [
          8.8708934629745,
          45.95957128543343
        ],
        [
          8.871164920505347,
          45.95954908715459
        ],
        [
          8.871359556920114,
          45.95953277265636
        ],
        [
          8.871641693806323,
          45.95950912629904
        ],
        [
          8.872326238352361,
          45.95925788073454
        ],
        [
          8.872677403127177,
          45.95909702296301
        ],
        [
          8.872894641152627,
          45.95899750693599
        ],
        [
          8.87317849507575,
          45.95884481261064
        ],
        [
          8.873578675236695,
          45.95879124993197
        ],
        [
          8.874210974433307,
          45.958809956559236
        ],
        [
          8.87471523113623,
          45.95865178463117
        ],
        [
          8.875011628178,
          45.95855882261975
        ],
        [
          8.87559280109535,
          45.958181438589904
        ],
        [
          8.876226410874203,
          45.95759710970563
        ],
        [
          8.877156765333712,
          45.956860085980054
        ],
        [
          8.877634974612352,
          45.9565275738016
        ],
        [
          8.87887448737112,
          45.95602488814822
        ],
        [
          8.88074512716513,
          45.95623385691426
        ],
        [
          8.882008954523831,
          45.95642417653441
        ],
        [
          8.882655154659158,
          45.95651934802094
        ],
        [
          8.882860344812853,
          45.956555555679486
        ],
        [
          8.889838463266637,
          45.957525532169974
        ],
        [
          8.892341298110857,
          45.957851927662546
        ],
        [
          8.893242858816228,
          45.95814078477371
        ],
        [
          8.894274628166738,
          45.95846575243247
        ],
        [
          8.89503935717481,
          45.95668440274006
        ],
        [
          8.895686581237825,
          45.955613942426005
        ],
        [
          8.896514084689112,
          45.95473267108878
        ],
        [
          8.89718677576427,
          45.953824233399324
        ],
        [
          8.897265654144322,
          45.95304128051287
        ],
        [
          8.897124833287657,
          45.95178109229867
        ],
        [
          8.89695975146475,
          45.95034088679248
        ],
        [
          8.897541964609056,
          45.94948639065172
        ],
        [
          8.898111122542568,
          45.948694862061075
        ],
        [
          8.898526399810944,
          45.94738117998462
        ],
        [
          8.898670337908658,
          45.94627425582382
        ],
        [
          8.898723974396184,
          45.945158255345795
        ],
        [
          8.898287604584265,
          45.94391583239063
        ],
        [
          8.89772171512968,
          45.94294327591583
        ],
        [
          8.896717882388412,
          45.94161932607282
        ],
        [
          8.895753040543735,
          45.94016939562229
        ],
        [
          8.894993845206962,
          45.93915165439182
        ],
        [
          8.894183842760103,
          45.93771984051465
        ],
        [
          8.89337372797298,
          45.93636903434094
        ],
        [
          8.892731610772667,
          45.935090378997074
        ],
        [
          8.892514110560557,
          45.933956134312815
        ],
        [
          8.892619650683823,
          45.93275017677101
        ],
        [
          8.89306090981741,
          45.931337531195595
        ],
        [
          8.894005081202126,
          45.92998833721852
        ],
        [
          8.895154500131586,
          45.92876534982222
        ],
        [
          8.895904226736683,
          45.92789300154072
        ],
        [
          8.896821807496194,
          45.9266697846139
        ],
        [
          8.897402648495126,
          45.92621127683003
        ],
        [
          8.89772573998342,
          45.92585155516036
        ],
        [
          8.898255465512174,
          45.92533001017893
        ],
        [
          8.899057596901143,
          45.9248401872586
        ],
        [
          8.899314193588731,
          45.92468292350426
        ],
        [
          8.899502623651262,
          45.92456607793178
        ],
        [
          8.900643649531318,
          45.92386054547394
        ],
        [
          8.90206342959678,
          45.92298873753778
        ],
        [
          8.904362660637391,
          45.92157311026458
        ],
        [
          8.904382037258985,
          45.92154612147174
        ],
        [
          8.904898325163966,
          45.92082203158999
        ],
        [
          8.905137888182797,
          45.92047121505568
        ],
        [
          8.90530434693109,
          45.92022833499737
        ],
        [
          8.905533745469565,
          45.919936012239056
        ],
        [
          8.906440845210293,
          45.91875770378705
        ],
        [
          8.9064719377298,
          45.91872173004467
        ],
        [
          8.906738946596622,
          45.9181054247953
        ],
        [
          8.906894087147517,
          45.91786253308378
        ],
        [
          8.906937237148593,
          45.91784907381953
        ],
        [
          8.907543270563151,
          45.917635801758884
        ],
        [
          8.907636374705906,
          45.91762913029108
        ],
        [
          8.908544796175812,
          45.91755786070105
        ],
        [
          8.909796549331661,
          45.91749585408439
        ],
        [
          8.910698139376255,
          45.917415549806826
        ],
        [
          8.911288117413578,
          45.917389005194046
        ],
        [
          8.912052222816538,
          45.917353594800765
        ],
        [
          8.912777201880171,
          45.91734515536857
        ],
        [
          8.912967402430496,
          45.917345300381704
        ],
        [
          8.913183320966167,
          45.917399474672806
        ],
        [
          8.913418556034673,
          45.91746265466699
        ],
        [
          8.913487013518106,
          45.917494209076615
        ],
        [
          8.913805123051345,
          45.91764294469711
        ],
        [
          8.913990352380262,
          45.91773309080844
        ],
        [
          8.914143779841824,
          45.917809703917214
        ],
        [
          8.914450453536665,
          45.917274422730664
        ],
        [
          8.914646057075482,
          45.916644534761176
        ],
        [
          8.914723492142272,
          45.916347583133174
        ],
        [
          8.915048225464853,
          45.915857289969054
        ],
        [
          8.91517583737194,
          45.91566387808374
        ],
        [
          8.915615268010232,
          45.9150071819653
        ],
        [
          8.916006378179691,
          45.91430543807432
        ],
        [
          8.916248888812637,
          45.91382859238243
        ],
        [
          8.91627631763222,
          45.91379710899698
        ],
        [
          8.916414434384162,
          45.91363653081067
        ],
        [
          8.916442633510783,
          45.91360372397685
        ],
        [
          8.916881671458185,
          45.91319002288736
        ],
        [
          8.917566069235074,
          45.912497481462495
        ],
        [
          8.918469506164385,
          45.91165209850354
        ],
        [
          8.919386145432796,
          45.91084271503507
        ],
        [
          8.92014800762527,
          45.91004221473955
        ],
        [
          8.920742634420268,
          45.90897156703019
        ],
        [
          8.921233481198177,
          45.90834187305779
        ],
        [
          8.922394860610991,
          45.9073346289112
        ],
        [
          8.92341428488811,
          45.9066872893434
        ],
        [
          8.924614438966488,
          45.90585103341032
        ],
        [
          8.925143873708276,
          45.9049423441069
        ],
        [
          8.925454945065782,
          45.903709498385325
        ],
        [
          8.925482015694039,
          45.90273745777345
        ],
        [
          8.92518695290189,
          45.901666223549604
        ],
        [
          8.924917751752465,
          45.900829009403466
        ],
        [
          8.924699639061703,
          45.900054812794615
        ],
        [
          8.924288061879004,
          45.89936152008716
        ],
        [
          8.924005139815037,
          45.89886629824939
        ],
        [
          8.923825448868657,
          45.89858266180376
        ],
        [
          8.923696417002343,
          45.89838007244939
        ],
        [
          8.9232204238032,
          45.897686711960475
        ],
        [
          8.922860215388594,
          45.897155455659394
        ],
        [
          8.922191186318873,
          45.8965339607314
        ],
        [
          8.921817787484542,
          45.89621870427299
        ],
        [
          8.921483330724898,
          45.89573243575643
        ],
        [
          8.921767517545902,
          45.89530062058249
        ],
        [
          8.922244969057093,
          45.89494093322234
        ],
        [
          8.923108666093173,
          45.89459049770029
        ],
        [
          8.923444899771445,
          45.89380767619019
        ],
        [
          8.924270613582657,
          45.892057633574865
        ],
        [
          8.926386080983843,
          45.89173500274745
        ],
        [
          8.92727565680558,
          45.891582578865865
        ],
        [
          8.928088081680842,
          45.89127706824716
        ],
        [
          8.928604576393388,
          45.890548351105885
        ],
        [
          8.929134236378681,
          45.88958563430336
        ],
        [
          8.92956051915403,
          45.88884786152583
        ],
        [
          8.930116093024328,
          45.88775914854595
        ],
        [
          8.930838976208959,
          45.886850549017055
        ],
        [
          8.931497490797584,
          45.885851882049245
        ],
        [
          8.932156200856834,
          45.88467322948972
        ],
        [
          8.932570130649003,
          45.88341339784944
        ],
        [
          8.93279059000441,
          45.882279478941356
        ],
        [
          8.932817199182395,
          45.881595446095844
        ],
        [
          8.933281021677539,
          45.88066867017128
        ],
        [
          8.93375882526096,
          45.87982291233337
        ],
        [
          8.933785748758899,
          45.87919738713291
        ],
        [
          8.934018131544407,
          45.87849999798278
        ],
        [
          8.93390319145398,
          45.877617878872144
        ],
        [
          8.933672313491009,
          45.87671770019427
        ],
        [
          8.933544550474338,
          45.8758175791208
        ],
        [
          8.933635655409883,
          45.875034583132916
        ],
        [
          8.93381412628925,
          45.87440512476877
        ],
        [
          8.934022155241705,
          45.87414521216463
        ],
        [
          8.934500388909022,
          45.8738380242008
        ],
        [
          8.934689212420517,
          45.873648426902115
        ],
        [
          8.934822894872942,
          45.87351419440093
        ],
        [
          8.934848960772158,
          45.873271189150344
        ],
        [
          8.934888397754596,
          45.872605171246434
        ],
        [
          8.93490179114864,
          45.8721641709961
        ],
        [
          8.93510842133658,
          45.871768254543596
        ],
        [
          8.934799572514459,
          45.87143507017078
        ],
        [
          8.934503624128533,
          45.87108388134059
        ],
        [
          8.934092533345293,
          45.87005758954086
        ],
        [
          8.934315929490545,
          45.87012072382108
        ],
        [
          8.934582375127896,
          45.87019737324128
        ],
        [
          8.935136482985197,
          45.87012569011186
        ],
        [
          8.935639281791921,
          45.86985594979749
        ],
        [
          8.935936067081915,
          45.86947811053466
        ],
        [
          8.936336038429438,
          45.868992293486976
        ],
        [
          8.936645664935382,
          45.86861445947764
        ],
        [
          8.937058459442657,
          45.86816466535076
        ],
        [
          8.937354722660503,
          45.86785880213283
        ],
        [
          8.937479541458163,
          45.867587701803565
        ],
        [
          8.937574286153568,
          45.86738189562348
        ],
        [
          8.93769620786876,
          45.867317204974185
        ],
        [
          8.93817119043358,
          45.86706955978167
        ],
        [
          8.938553549547557,
          45.86730143220599
        ],
        [
          8.93934275452411,
          45.868058498930324
        ],
        [
          8.93984540878785,
          45.868535176515955
        ],
        [
          8.939969297948045,
          45.86865225425015
        ],
        [
          8.940329292842852,
          45.86896746122886
        ],
        [
          8.94103747160374,
          45.869399844512415
        ],
        [
          8.941719815249412,
          45.8698952311553
        ],
        [
          8.942544017955555,
          45.87027365184682
        ],
        [
          8.943059090029852,
          45.87017490631713
        ],
        [
          8.943085183252322,
          45.869841909756055
        ],
        [
          8.942905612997798,
          45.86943679088673
        ],
        [
          8.94327916615245,
          45.86913097257032
        ],
        [
          8.943798726627609,
          45.86883296359455
        ],
        [
          8.943859291071098,
          45.86879823053294
        ],
        [
          8.944671409128198,
          45.868375606967845
        ],
        [
          8.945175080935991,
          45.86796086662392
        ],
        [
          8.945457955580245,
          45.867727953354645
        ],
        [
          8.945600191888666,
          45.86719698665176
        ],
        [
          8.945432986529548,
          45.86689989581597
        ],
        [
          8.945265695185732,
          45.86671979750041
        ],
        [
          8.945034033743983,
          45.86647668389974
        ],
        [
          8.944725180365174,
          45.866116501871815
        ],
        [
          8.944107244565835,
          45.86563919158313
        ],
        [
          8.943154794605736,
          45.865152680834065
        ],
        [
          8.942086009576133,
          45.864639115927545
        ],
        [
          8.941274796857732,
          45.86426069643169
        ],
        [
          8.941030145268021,
          45.86412555034634
        ],
        [
          8.94048971100407,
          45.863909270239894
        ],
        [
          8.940264213841303,
          45.86383499066733
        ],
        [
          8.939832901984174,
          45.86369291105222
        ],
        [
          8.938592788444467,
          45.86341651108917
        ],
        [
          8.938557838150562,
          45.863408713117366
        ],
        [
          8.937566090386303,
          45.8632011554568
        ],
        [
          8.937270268112576,
          45.86313800661812
        ],
        [
          8.936536201102268,
          45.862921585113746
        ],
        [
          8.935879450114028,
          45.86267819791007
        ],
        [
          8.935222718869879,
          45.86244382009552
        ],
        [
          8.93452540576593,
          45.86215090889344
        ],
        [
          8.934301677605768,
          45.86206077215371
        ],
        [
          8.933858416676951,
          45.86172299082613
        ],
        [
          8.933710619619392,
          45.86161179862629
        ],
        [
          8.93334359173401,
          45.86133567103462
        ],
        [
          8.933166901524272,
          45.8611632943313
        ],
        [
          8.932918949789652,
          45.86094840277379
        ],
        [
          8.93246852819501,
          45.86057011284092
        ],
        [
          8.932031037327759,
          45.86017384026833
        ],
        [
          8.931889528427247,
          45.85999373838812
        ],
        [
          8.93154261275835,
          45.859597515156466
        ],
        [
          8.931156637469988,
          45.859219258552734
        ],
        [
          8.930410350934833,
          45.858516766702145
        ],
        [
          8.92967708744083,
          45.85773328177868
        ],
        [
          8.928571344445295,
          45.856436515542384
        ],
        [
          8.927297753061188,
          45.85519363868404
        ],
        [
          8.92620468032383,
          45.85413088189288
        ],
        [
          8.92251278889962,
          45.850924257425845
        ],
        [
          8.921989717872933,
          45.850460227597075
        ],
        [
          8.920583671199953,
          45.84921284310594
        ],
        [
          8.91900166938297,
          45.847708645851625
        ],
        [
          8.917613762254561,
          45.845925542761755
        ],
        [
          8.916392085115511,
          45.844718580018665
        ],
        [
          8.915350772290036,
          45.843502742520094
        ],
        [
          8.914785788760307,
          45.84268328571941
        ],
        [
          8.914580250105514,
          45.842359114473815
        ],
        [
          8.914412464636026,
          45.84207996869678
        ],
        [
          8.91411140758051,
          45.84156671338772
        ],
        [
          8.914092142766467,
          45.841539694419176
        ],
        [
          8.913899580790673,
          45.84115252746671
        ],
        [
          8.913745575472877,
          45.840801390760944
        ],
        [
          8.913604303067876,
          45.8405582625188
        ],
        [
          8.913476650266439,
          45.839847127720404
        ],
        [
          8.913207323643686,
          45.8391403836678
        ],
        [
          8.912951236770777,
          45.838199638764
        ],
        [
          8.912747319805147,
          45.83684039235678
        ],
        [
          8.912567945753212,
          45.836282231339524
        ],
        [
          8.912452677639362,
          45.83587711139635
        ],
        [
          8.91233737265456,
          45.83549899669521
        ],
        [
          8.912286378739713,
          45.835174941171445
        ],
        [
          8.912235485741824,
          45.83478788054097
        ],
        [
          8.912125479305942,
          45.83360872276342
        ],
        [
          8.912121500136534,
          45.83302083145716
        ],
        [
          8.912112107800327,
          45.83163310657139
        ],
        [
          8.912448587663452,
          45.83027877003736
        ],
        [
          8.912346006143952,
          45.829999687673855
        ],
        [
          8.912050360448768,
          45.82970242763683
        ],
        [
          8.910391014766677,
          45.82951212729713
        ],
        [
          8.909476691708452,
          45.829637415214115
        ],
        [
          8.908459492310563,
          45.82967260459983
        ],
        [
          8.907739183703345,
          45.82926701291854
        ],
        [
          8.907392612188856,
          45.82862769156549
        ],
        [
          8.90721326100133,
          45.828087511784915
        ],
        [
          8.907304445718928,
          45.82744854646357
        ],
        [
          8.907563001709466,
          45.82680971561837
        ],
        [
          8.908335993727652,
          45.82648632144323
        ],
        [
          8.909134607306077,
          45.82622594583689
        ],
        [
          8.909728057902955,
          45.825461367087215
        ],
        [
          8.909790162639451,
          45.825003765142675
        ],
        [
          8.909884394020402,
          45.824309418091154
        ],
        [
          8.909653891092383,
          45.82355318370793
        ],
        [
          8.909551857155883,
          45.822968070499115
        ],
        [
          8.909604076876768,
          45.822518085960205
        ],
        [
          8.909348148431363,
          45.821563821311514
        ],
        [
          8.909336612395705,
          45.82074475143153
        ],
        [
          8.909092930819845,
          45.82019552756256
        ],
        [
          8.908720389213562,
          45.81972721312541
        ],
        [
          8.908245861293445,
          45.818646760958956
        ],
        [
          8.907796643409553,
          45.817845349372654
        ],
        [
          8.907501801467983,
          45.81711606065391
        ],
        [
          8.907027031712332,
          45.81622461518259
        ],
        [
          8.907003460203272,
          45.815371122368354
        ],
        [
          8.906946374522299,
          45.8153785041191
        ],
        [
          8.906024249323721,
          45.815494745120226
        ],
        [
          8.905264444679682,
          45.81571013567432
        ],
        [
          8.904504817493645,
          45.81581752358324
        ],
        [
          8.903745492294059,
          45.81576287634155
        ],
        [
          8.903011226198474,
          45.81609526817946
        ],
        [
          8.902907679888315,
          45.81642821185704
        ],
        [
          8.90272714171167,
          45.816617073445684
        ],
        [
          8.902252472789948,
          45.81661666837429
        ],
        [
          8.902046819837658,
          45.81645448173042
        ],
        [
          8.901931688382405,
          45.81604035733897
        ],
        [
          8.90178831746393,
          45.81591422529509
        ],
        [
          8.901761549711996,
          45.8158911786574
        ],
        [
          8.901610383668114,
          45.815761057803286
        ],
        [
          8.9014330305589,
          45.815682766826484
        ],
        [
          8.900915857844694,
          45.81545442880943
        ],
        [
          8.900677930002791,
          45.815346222699375
        ],
        [
          8.900054159657666,
          45.81505766200206
        ],
        [
          8.899617172930999,
          45.814706254064056
        ],
        [
          8.899566391487207,
          45.81430117891405
        ],
        [
          8.899348311383871,
          45.8139049688064
        ],
        [
          8.898808509949468,
          45.813463459791535
        ],
        [
          8.897728226818979,
          45.8129674753673
        ],
        [
          8.896956039983547,
          45.812921767605616
        ],
        [
          8.896029579487548,
          45.812785923243574
        ],
        [
          8.894833168987535,
          45.81246980589633
        ],
        [
          8.894383261246823,
          45.812163381698014
        ],
        [
          8.893804658565307,
          45.81184782203241
        ],
        [
          8.893406196849703,
          45.81157742362561
        ],
        [
          8.893123923530371,
          45.811118137037475
        ],
        [
          8.891657392125373,
          45.81072973271135
        ],
        [
          8.891105908941558,
          45.81035118049539
        ],
        [
          8.890115607962873,
          45.80999021382474
        ],
        [
          8.889497726137366,
          45.81003461217729
        ],
        [
          8.888636085068718,
          45.80969176640851
        ],
        [
          8.887568689353275,
          45.80927669317568
        ],
        [
          8.88692381457104,
          45.809951099882724
        ],
        [
          8.886589604890045,
          45.80973475045564
        ],
        [
          8.885972375860815,
          45.80947312192543
        ],
        [
          8.88499455766183,
          45.80931912734599
        ],
        [
          8.8845952745537,
          45.809462744116466
        ],
        [
          8.884465394648952,
          45.810029634661824
        ],
        [
          8.884554746936187,
          45.81038976158503
        ],
        [
          8.884914771171742,
          45.81056113071838
        ],
        [
          8.88488853499028,
          45.810795113847604
        ],
        [
          8.884617896287832,
          45.810965863919876
        ],
        [
          8.884077183803702,
          45.8110463116285
        ],
        [
          8.883175909724182,
          45.811198408254874
        ],
        [
          8.881528990583007,
          45.81152073032086
        ],
        [
          8.88045266137976,
          45.811704124269745
        ],
        [
          8.880359868476413,
          45.811719769272486
        ],
        [
          8.879842296978072,
          45.81180698784175
        ],
        [
          8.878232959591996,
          45.81203029841672
        ],
        [
          8.876790833860015,
          45.8122897657102
        ],
        [
          8.875876677945437,
          45.81243279379857
        ],
        [
          8.875542361651327,
          45.81228841267285
        ],
        [
          8.874988390705482,
          45.81252181742345
        ],
        [
          8.873816249045685,
          45.8129075691928
        ],
        [
          8.872375070509941,
          45.8132749822498
        ],
        [
          8.870507474060876,
          45.81382191374861
        ],
        [
          8.869258183984472,
          45.814144508743425
        ],
        [
          8.867958091584391,
          45.81431703842086
        ],
        [
          8.867759803687251,
          45.8143766840592
        ],
        [
          8.86775512214391,
          45.814442011577746
        ],
        [
          8.86775758141185,
          45.814490616072774
        ],
        [
          8.86775360277797,
          45.81454011678057
        ],
        [
          8.867771473821234,
          45.814603140132284
        ],
        [
          8.867809846143496,
          45.814706693672825
        ],
        [
          8.86784056715049,
          45.814777834251636
        ],
        [
          8.867913694765218,
          45.81487962421957
        ],
        [
          8.867946988640314,
          45.81495256966946
        ],
        [
          8.867964872794973,
          45.815010192618814
        ],
        [
          8.867977634306378,
          45.8150579035442
        ],
        [
          8.868014848967336,
          45.81510474805263
        ],
        [
          8.868092126214854,
          45.81514806195112
        ],
        [
          8.868160018871272,
          45.81522462412498
        ],
        [
          8.868220345533338,
          45.815295800551134
        ],
        [
          8.868288453135257,
          45.81534358065667
        ],
        [
          8.868302482603005,
          45.81539850251492
        ],
        [
          8.868297189632822,
          45.81545970074696
        ],
        [
          8.868268716870936,
          45.81552537257565
        ],
        [
          8.86824668630222,
          45.815588349833725
        ],
        [
          8.868250289023273,
          45.81570356393925
        ],
        [
          8.868252604464212,
          45.815813365375405
        ],
        [
          8.868256283484987,
          45.81589077509114
        ],
        [
          8.868257407248892,
          45.815960079486786
        ],
        [
          8.868240478537887,
          45.81604286762096
        ],
        [
          8.868219681841659,
          45.81612834968123
        ],
        [
          8.868198872345536,
          45.81621923030309
        ],
        [
          8.868191000543767,
          45.81628222747401
        ],
        [
          8.868183056654983,
          45.81638122530316
        ],
        [
          8.868173918856927,
          45.81643521857495
        ],
        [
          8.86815315849314,
          45.8165062906418
        ],
        [
          8.868109161683202,
          45.81660344492827
        ],
        [
          8.868087173002355,
          45.81664842452186
        ],
        [
          8.86808832419702,
          45.81670602811595
        ],
        [
          8.868088175429605,
          45.81676903419001
        ],
        [
          8.86806228467074,
          45.81683110687886
        ],
        [
          8.868038989520954,
          45.816884185524216
        ],
        [
          8.868032423911357,
          45.81694448039468
        ],
        [
          8.868037400534526,
          45.81701739308353
        ],
        [
          8.868059130068778,
          45.81708222094577
        ],
        [
          8.868079597935571,
          45.817136250185406
        ],
        [
          8.868076868358948,
          45.817201949865094
        ],
        [
          8.868065053882633,
          45.81729913407375
        ],
        [
          8.868041743657441,
          45.81735851314179
        ],
        [
          8.868024841848438,
          45.81742959683033
        ],
        [
          8.868025893050916,
          45.81752950688113
        ],
        [
          8.8680205038852,
          45.81763120781808
        ],
        [
          8.868003553161795,
          45.81772839508099
        ],
        [
          8.867998193713746,
          45.81781749336081
        ],
        [
          8.868007044818865,
          45.817885010135704
        ],
        [
          8.868031312988798,
          45.81796513480169
        ],
        [
          8.86805691308262,
          45.818026368670665
        ],
        [
          8.868046491911182,
          45.818078558508
        ],
        [
          8.868029630278302,
          45.818132544632455
        ],
        [
          8.867980594065871,
          45.81818378979418
        ],
        [
          8.867949568457279,
          45.81823955966662
        ],
        [
          8.867929942042553,
          45.818267802570396
        ],
        [
          8.867910819355371,
          45.81829531700173
        ],
        [
          8.867890112931335,
          45.818347498532404
        ],
        [
          8.867874519157759,
          45.81840958306627
        ],
        [
          8.867871712628844,
          45.81850768851402
        ],
        [
          8.867867710392892,
          45.81856708625874
        ],
        [
          8.867879116794295,
          45.818642706558386
        ],
        [
          8.867896985041414,
          45.81870752276761
        ],
        [
          8.86792643218757,
          45.81877505974758
        ],
        [
          8.867971258355796,
          45.818871420112025
        ],
        [
          8.868001651033573,
          45.81893710763331
        ],
        [
          8.868016198249176,
          45.81900252662659
        ],
        [
          8.868011310221567,
          45.81906639846192
        ],
        [
          8.867948730946306,
          45.81915041185648
        ],
        [
          8.867899652869756,
          45.819217861662395
        ],
        [
          8.86784924528105,
          45.81930420763378
        ],
        [
          8.867859387960554,
          45.81936992577002
        ],
        [
          8.86789646111328,
          45.81947886726982
        ],
        [
          8.867941436743724,
          45.81951221979193
        ],
        [
          8.868027683700186,
          45.81951231943754
        ],
        [
          8.86807154269468,
          45.81957811699192
        ],
        [
          8.868112343763007,
          45.819639326077976
        ],
        [
          8.868203378996462,
          45.81979244183419
        ],
        [
          8.86822899284657,
          45.81984827708276
        ],
        [
          8.868293217031782,
          45.81990865355057
        ],
        [
          8.868360002271059,
          45.81997263678441
        ],
        [
          8.86840363828744,
          45.820028489153096
        ],
        [
          8.868425369401832,
          45.82009332051335
        ],
        [
          8.86845739036034,
          45.82016176036663
        ],
        [
          8.868498413926552,
          45.82023380542259
        ],
        [
          8.868567772704687,
          45.82029958981881
        ],
        [
          8.868621675730495,
          45.82036895485416
        ],
        [
          8.868643454255253,
          45.820414883101975
        ],
        [
          8.8686459168295,
          45.820467991445476
        ],
        [
          8.868639323756017,
          45.82053458672164
        ],
        [
          8.868618529758615,
          45.82061736860151
        ],
        [
          8.868618366750377,
          45.82068667505674
        ],
        [
          8.86861177150061,
          45.82075417038912
        ],
        [
          8.868593586307636,
          45.82082345611819
        ],
        [
          8.868563760311265,
          45.82091612828792
        ],
        [
          8.868554397278892,
          45.82094422828022
        ],
        [
          8.868534529247086,
          45.820991171602095
        ],
        [
          8.868510042646156,
          45.82103469746721
        ],
        [
          8.868536328950814,
          45.821090699920106
        ],
        [
          8.8685762034557,
          45.821098846355
        ],
        [
          8.868628968131013,
          45.8211052056709
        ],
        [
          8.868688179917894,
          45.8211070738576
        ],
        [
          8.868722867342932,
          45.82113681578778
        ],
        [
          8.868611714650743,
          45.8213311033961
        ],
        [
          8.868548424651587,
          45.82142103688141
        ],
        [
          8.868504482303067,
          45.82149478966742
        ],
        [
          8.86849662280297,
          45.82155238643871
        ],
        [
          8.86849059245008,
          45.82159855091707
        ],
        [
          8.868432501480378,
          45.82160703464021
        ],
        [
          8.86843253411282,
          45.82165329970251
        ],
        [
          8.868429059889813,
          45.82176741465476
        ],
        [
          8.868430345648415,
          45.82193203760321
        ],
        [
          8.868430911761537,
          45.8220196143539
        ],
        [
          8.868429717387038,
          45.82208945604157
        ],
        [
          8.868428020577943,
          45.82215399038046
        ],
        [
          8.868416083222359,
          45.82219384940972
        ],
        [
          8.868394439977447,
          45.822255296962204
        ],
        [
          8.868374501387278,
          45.822303519153195
        ],
        [
          8.868364843196918,
          45.822359490122295
        ],
        [
          8.868338121890256,
          45.82245071668401
        ],
        [
          8.868313695967858,
          45.822491101347374
        ],
        [
          8.868276883786669,
          45.822544344422354
        ],
        [
          8.868231251336741,
          45.82262349551595
        ],
        [
          8.868181384146089,
          45.82269796317977
        ],
        [
          8.868177006976609,
          45.82272532003204
        ],
        [
          8.868172629798561,
          45.82275267868412
        ],
        [
          8.868144271056243,
          45.8228229390483
        ],
        [
          8.868123073131695,
          45.82291364268913
        ],
        [
          8.868106435208473,
          45.82298787684385
        ],
        [
          8.86810033396744,
          45.8231183716647
        ],
        [
          8.868279938734748,
          45.823807501944536
        ],
        [
          8.868373369334416,
          45.82416599014076
        ],
        [
          8.868413174067715,
          45.82421013724072
        ],
        [
          8.868488032765331,
          45.82426147297179
        ],
        [
          8.86851981654079,
          45.82429936616962
        ],
        [
          8.868557012826361,
          45.82435791481408
        ],
        [
          8.86856460578809,
          45.82441372561074
        ],
        [
          8.86855033444491,
          45.82446051243313
        ],
        [
          8.868524487218785,
          45.82450278742317
        ],
        [
          8.868448080129873,
          45.82458343056779
        ],
        [
          8.86844309975022,
          45.82463458347287
        ],
        [
          8.868459649620029,
          45.8247016157612
        ],
        [
          8.868454379687071,
          45.82475381510476
        ],
        [
          8.868441389351256,
          45.82480870214546
        ],
        [
          8.868409656959024,
          45.82486146145626
        ],
        [
          8.868386071573768,
          45.82491841093101
        ],
        [
          8.868319922227634,
          45.82499937722036
        ],
        [
          8.868312055677926,
          45.82505967052172
        ],
        [
          8.868285638158264,
          45.825319007389105
        ],
        [
          8.86828810372126,
          45.82539535710264
        ],
        [
          8.868284129008716,
          45.82544305581419
        ],
        [
          8.86825140363066,
          45.82547993863478
        ],
        [
          8.868166643742876,
          45.82558333181695
        ],
        [
          8.868157525650906,
          45.82562832440193
        ],
        [
          8.868155891910384,
          45.8257750308377
        ],
        [
          8.868146739775176,
          45.82583442617432
        ],
        [
          8.868141498745066,
          45.82587312279534
        ],
        [
          8.868201782084268,
          45.825968598922685
        ],
        [
          8.868238998284768,
          45.826019034517195
        ],
        [
          8.868296605365126,
          45.82616311085521
        ],
        [
          8.868319662225792,
          45.82621264263947
        ],
        [
          8.868395478051974,
          45.82627303233958
        ],
        [
          8.868475253661714,
          45.82628662512308
        ],
        [
          8.868522372095383,
          45.8263129305572
        ],
        [
          8.86853194339124,
          45.82635644786635
        ],
        [
          8.86854066457998,
          45.82639740802032
        ],
        [
          8.868519987347156,
          45.82643068832313
        ],
        [
          8.868467175262746,
          45.82644232834799
        ],
        [
          8.868349959930091,
          45.82646739509422
        ],
        [
          8.868304836266123,
          45.826494344955336
        ],
        [
          8.868245396278022,
          45.826587882876645
        ],
        [
          8.868216998008315,
          45.82661845223446
        ],
        [
          8.868041898781659,
          45.826621848604454
        ],
        [
          8.867326148746589,
          45.82660031115973
        ],
        [
          8.866416038110653,
          45.826561448198895
        ],
        [
          8.866348736328007,
          45.826709879719644
        ],
        [
          8.86616879729885,
          45.82712638697027
        ],
        [
          8.866067664318212,
          45.827428691055175
        ],
        [
          8.866058507537444,
          45.82748898266352
        ],
        [
          8.86609039384359,
          45.827614128626536
        ],
        [
          8.866332140179685,
          45.827737720141926
        ],
        [
          8.866418227594975,
          45.827809827556194
        ],
        [
          8.866541520415206,
          45.827936871331225
        ],
        [
          8.866619864282054,
          45.82801706830195
        ],
        [
          8.866651894417124,
          45.828083710253175
        ],
        [
          8.86663614133962,
          45.82820969870701
        ],
        [
          8.86679551261814,
          45.82832509430087
        ],
        [
          8.866881485683429,
          45.828445802754956
        ],
        [
          8.866988003011844,
          45.828589934860894
        ],
        [
          8.867030326877826,
          45.82865839060474
        ],
        [
          8.86705974821943,
          45.82873851952166
        ],
        [
          8.86707761440862,
          45.82880514666546
        ],
        [
          8.867130652187084,
          45.82924623851873
        ],
        [
          8.867226334587622,
          45.829616266016494
        ],
        [
          8.867298263530284,
          45.82968835269079
        ],
        [
          8.867424289194815,
          45.82975150508274
        ],
        [
          8.867550295939635,
          45.82982275426574
        ],
        [
          8.867604221795153,
          45.829885822861215
        ],
        [
          8.867723047539858,
          45.830276578125805
        ],
        [
          8.866904726131153,
          45.83059514966191
        ],
        [
          8.86514548635042,
          45.83138481686317
        ],
        [
          8.865349060786485,
          45.83156874862333
        ],
        [
          8.865444733464763,
          45.831653692088445
        ],
        [
          8.865514703297295,
          45.83173209958209
        ],
        [
          8.86553069137606,
          45.83178160195936
        ],
        [
          8.865591063262078,
          45.83184107882434
        ],
        [
          8.865683535353547,
          45.83193749229955
        ],
        [
          8.8657490387283,
          45.83200597572219
        ],
        [
          8.86579781833982,
          45.832066337116174
        ],
        [
          8.865917183371295,
          45.832224878993465
        ],
        [
          8.865963377432138,
          45.83228973578556
        ],
        [
          8.86605325634241,
          45.83239424826142
        ],
        [
          8.866114932119963,
          45.832447425944416
        ],
        [
          8.866165032857593,
          45.83249518787058
        ],
        [
          8.866224129411984,
          45.83255015899448
        ],
        [
          8.866695398814576,
          45.83254260920836
        ],
        [
          8.866748167635789,
          45.83255167136732
        ],
        [
          8.866802211584124,
          45.83256703721547
        ],
        [
          8.866880694919338,
          45.83259053026133
        ],
        [
          8.86701318380969,
          45.832645586454355
        ],
        [
          8.86710451563651,
          45.832681696952946
        ],
        [
          8.86720997660098,
          45.83272682260854
        ],
        [
          8.867321914585391,
          45.83276205321648
        ],
        [
          8.86741841803779,
          45.83278826699745
        ],
        [
          8.867469765040688,
          45.83285403281397
        ],
        [
          8.867446345149053,
          45.8329620129626
        ],
        [
          8.867383108462846,
          45.83302314193148
        ],
        [
          8.867339225973051,
          45.83306719577887
        ],
        [
          8.867299184797082,
          45.83312024207852
        ],
        [
          8.867273326186876,
          45.83316521509095
        ],
        [
          8.867238428763232,
          45.83322098012662
        ],
        [
          8.867198395922577,
          45.83327043515624
        ],
        [
          8.867148056321193,
          45.83332348203248
        ],
        [
          8.867109353321109,
          45.83335403909778
        ],
        [
          8.8670796702775,
          45.833388205086045
        ],
        [
          8.867060262164033,
          45.83342778698643
        ],
        [
          8.867056225814014,
          45.833500685420354
        ],
        [
          8.867059987859685,
          45.833542994457645
        ],
        [
          8.866269064376151,
          45.83369147263897
        ],
        [
          8.865838820085141,
          45.833778704422784
        ],
        [
          8.8656713470409,
          45.833808679506525
        ],
        [
          8.865506850243605,
          45.83383352030071
        ],
        [
          8.865198621317518,
          45.8338757614724
        ],
        [
          8.864343162744447,
          45.83400924425714
        ],
        [
          8.86393483120841,
          45.83406507465864
        ],
        [
          8.86379427555808,
          45.834086907387515
        ],
        [
          8.863722750564904,
          45.83411157197801
        ],
        [
          8.862857999879143,
          45.83485837981896
        ],
        [
          8.862812765042005,
          45.834926731928334
        ],
        [
          8.86281393545664,
          45.83497443474703
        ],
        [
          8.862866642069132,
          45.83505301480213
        ],
        [
          8.863114463215235,
          45.83546736321976
        ],
        [
          8.8634141516874,
          45.83596340226496
        ],
        [
          8.8635194615098,
          45.836076935714864
        ],
        [
          8.863659507599255,
          45.83620311136349
        ],
        [
          8.863727619168248,
          45.836258098099066
        ],
        [
          8.863866569469051,
          45.83630596512696
        ],
        [
          8.864112351638404,
          45.83637196389023
        ],
        [
          8.864283467737476,
          45.83643066938257
        ],
        [
          8.864454496800555,
          45.83652537152992
        ],
        [
          8.864508436542865,
          45.83658393762631
        ],
        [
          8.864571307065324,
          45.83667671837564
        ],
        [
          8.864627735098784,
          45.83677129337739
        ],
        [
          8.86485180179842,
          45.837303483449205
        ],
        [
          8.865018531866646,
          45.8375799900194
        ],
        [
          8.86502979243909,
          45.837715914212005
        ],
        [
          8.865030982205088,
          45.83775641832596
        ],
        [
          8.86496233611697,
          45.83792644679521
        ],
        [
          8.864941584964997,
          45.83798762816088
        ],
        [
          8.86492338523256,
          45.83805870059895
        ],
        [
          8.86490295535751,
          45.83819460712772
        ],
        [
          8.864904645603602,
          45.83827037708649
        ],
        [
          8.864904308441439,
          45.838409701490505
        ],
        [
          8.864951490787785,
          45.83869416904316
        ],
        [
          8.864962919069729,
          45.83876078519183
        ],
        [
          8.864912287734368,
          45.838839932428165
        ],
        [
          8.864911188334494,
          45.83886641089408
        ],
        [
          8.864896559715392,
          45.839222430327176
        ],
        [
          8.86489966793388,
          45.83926833525963
        ],
        [
          8.864951097377173,
          45.83939440444588
        ],
        [
          8.864902845519845,
          45.83941062229172
        ],
        [
          8.864572208698506,
          45.83952175552724
        ],
        [
          8.864469718756691,
          45.83955646632968
        ],
        [
          8.864435779352158,
          45.83949279183396
        ],
        [
          8.864352273840458,
          45.83941168734099
        ],
        [
          8.864275161600615,
          45.83935040249925
        ],
        [
          8.863952563253651,
          45.8390890014771
        ],
        [
          8.863384426640755,
          45.83866530262828
        ],
        [
          8.863322671683521,
          45.83864272727705
        ],
        [
          8.863169586552004,
          45.838580441721724
        ],
        [
          8.863073092394798,
          45.83854612205001
        ],
        [
          8.862979164926823,
          45.83851540741485
        ],
        [
          8.862577661731367,
          45.838414118525165
        ],
        [
          8.862421953856366,
          45.838371630133466
        ],
        [
          8.862219926732038,
          45.838313782866095
        ],
        [
          8.861895724407423,
          45.83819278343528
        ],
        [
          8.861779958583028,
          45.83814133823304
        ],
        [
          8.861651367628632,
          45.838070088663414
        ],
        [
          8.861594809101996,
          45.83802861560418
        ],
        [
          8.861543446413615,
          45.83797005126979
        ],
        [
          8.861452469708656,
          45.8377836267413
        ],
        [
          8.86139233045951,
          45.83762964531286
        ],
        [
          8.861331250368956,
          45.83733616056887
        ],
        [
          8.861278617634204,
          45.836747466244745
        ],
        [
          8.861253222776751,
          45.836598025391716
        ],
        [
          8.861218708719715,
          45.8364962856554
        ],
        [
          8.861155964849575,
          45.83635489994983
        ],
        [
          8.861093306398175,
          45.83617931021331
        ],
        [
          8.860917762403929,
          45.835898310217466
        ],
        [
          8.860831655791435,
          45.83576047452051
        ],
        [
          8.86064032507456,
          45.835548725309245
        ],
        [
          8.860500449970202,
          45.83535235043027
        ],
        [
          8.86046283577462,
          45.83526054512747
        ],
        [
          8.86049316947213,
          45.83517413134555
        ],
        [
          8.86049378791918,
          45.83492662443036
        ],
        [
          8.860498306001956,
          45.83485867986785
        ],
        [
          8.860454621770065,
          45.83477377780942
        ],
        [
          8.86017579247125,
          45.83438800846276
        ],
        [
          8.860130901975412,
          45.83431774878561
        ],
        [
          8.859905329100632,
          45.833895352946094
        ],
        [
          8.859841283535005,
          45.833762065405104
        ],
        [
          8.85969173797525,
          45.83348992038909
        ],
        [
          8.859636261491644,
          45.83336668522471
        ],
        [
          8.859605689826127,
          45.8332352387753
        ],
        [
          8.859265497712288,
          45.83334192753484
        ],
        [
          8.858981097367135,
          45.83342756151878
        ],
        [
          8.859049788703512,
          45.83361798034294
        ],
        [
          8.859109882836046,
          45.833784558509066
        ],
        [
          8.859175219078777,
          45.83396699587011
        ],
        [
          8.859207080018834,
          45.83404749606612
        ],
        [
          8.859245465005706,
          45.83414294979774
        ],
        [
          8.859289028082936,
          45.83422850742304
        ],
        [
          8.859385149370778,
          45.834413131000254
        ],
        [
          8.859406873003735,
          45.83447796030268
        ],
        [
          8.859440076753872,
          45.834586908497
        ],
        [
          8.85954834392647,
          45.8350459213523
        ],
        [
          8.859250422814926,
          45.83504732158965
        ],
        [
          8.858871217302775,
          45.83502332538198
        ],
        [
          8.85832398902496,
          45.83499595059823
        ],
        [
          8.857645200168532,
          45.835001019465736
        ],
        [
          8.85713936877009,
          45.83500077609874
        ],
        [
          8.857050521730573,
          45.83500606534749
        ],
        [
          8.856991113338317,
          45.83500849227935
        ],
        [
          8.85683675296963,
          45.83501479832449
        ],
        [
          8.85675029970458,
          45.835094313838304
        ],
        [
          8.856263598567901,
          45.83553690418171
        ],
        [
          8.856206834317891,
          45.8355798556575
        ],
        [
          8.855723126998882,
          45.835959334288304
        ],
        [
          8.85539933683412,
          45.83619203886033
        ],
        [
          8.854963372605903,
          45.83648147859694
        ],
        [
          8.854918229713224,
          45.836509231355
        ],
        [
          8.85487308934631,
          45.83653698589845
        ],
        [
          8.854839528278594,
          45.836568447182195
        ],
        [
          8.85483148498395,
          45.836616690101174
        ],
        [
          8.85484191404696,
          45.83664135330376
        ],
        [
          8.854917620647186,
          45.836748558436234
        ],
        [
          8.854730546286085,
          45.836887819310746
        ],
        [
          8.854477631959568,
          45.83709631328978
        ],
        [
          8.854308502935842,
          45.837266198367075
        ],
        [
          8.854166551632838,
          45.8373884254853
        ],
        [
          8.854045192099434,
          45.83750977866847
        ],
        [
          8.853883738216448,
          45.837703087074594
        ],
        [
          8.853781975311023,
          45.83783505873715
        ],
        [
          8.853741609317044,
          45.83788740678059
        ],
        [
          8.853616289000659,
          45.83805015899532
        ],
        [
          8.853518062896988,
          45.83818773331978
        ],
        [
          8.853428876186067,
          45.83831542749116
        ],
        [
          8.85333991867967,
          45.83837532755756
        ],
        [
          8.8532588013773,
          45.83835751023263
        ],
        [
          8.853136517070396,
          45.83833935372764
        ],
        [
          8.853045670148209,
          45.83827060014445
        ],
        [
          8.852927886203592,
          45.83818710663836
        ],
        [
          8.852725276594002,
          45.838026512566366
        ],
        [
          8.852666721028198,
          45.837987012645634
        ],
        [
          8.85254046317671,
          45.83790185169881
        ],
        [
          8.85240909301878,
          45.837798389384524
        ],
        [
          8.852323049017588,
          45.83771187243506
        ],
        [
          8.852197160542403,
          45.837595601900446
        ],
        [
          8.852085432488373,
          45.83748205693708
        ],
        [
          8.851996849719889,
          45.83738113548654
        ],
        [
          8.85193057267933,
          45.83730829955093
        ],
        [
          8.851885182219434,
          45.83724508169392
        ],
        [
          8.851810404900558,
          45.837205465794355
        ],
        [
          8.851735962397557,
          45.83718818617628
        ],
        [
          8.851657423812249,
          45.83718448400202
        ],
        [
          8.85159045277333,
          45.83718889734096
        ],
        [
          8.851509290968009,
          45.83720319286365
        ],
        [
          8.851175575292807,
          45.837285564254756
        ],
        [
          8.851107275610094,
          45.83730527660467
        ],
        [
          8.85102360300691,
          45.83729526688302
        ],
        [
          8.850956771159394,
          45.83724747473559
        ],
        [
          8.850863747014758,
          45.83717837094111
        ],
        [
          8.85073686193224,
          45.83708678343993
        ],
        [
          8.850515845414975,
          45.83696788186919
        ],
        [
          8.850056756583955,
          45.83674047235653
        ],
        [
          8.849915370005862,
          45.83664488033844
        ],
        [
          8.849835733474176,
          45.836570972559485
        ],
        [
          8.849432841991348,
          45.836034013566334
        ],
        [
          8.84935056696065,
          45.835941137689915
        ],
        [
          8.84921569865634,
          45.835860017358335
        ],
        [
          8.84899191900093,
          45.83576071338582
        ],
        [
          8.848770734645434,
          45.83565241360285
        ],
        [
          8.848632573234841,
          45.835575883929565
        ],
        [
          8.848600403764008,
          45.835543710948784
        ],
        [
          8.848558680657263,
          45.835517480998085
        ],
        [
          8.84849502529644,
          45.83547746182836
        ],
        [
          8.848440146059252,
          45.83544379789056
        ],
        [
          8.848397408696624,
          45.83538190973533
        ],
        [
          8.848358267902931,
          45.835333256327075
        ],
        [
          8.84817065551911,
          45.835192597740416
        ],
        [
          8.84808264032848,
          45.83512362612047
        ],
        [
          8.847994625360167,
          45.83505465263207
        ],
        [
          8.847838531286769,
          45.834924200701785
        ],
        [
          8.847691509408413,
          45.83477433913887
        ],
        [
          8.847570016952005,
          45.834687101662595
        ],
        [
          8.847518083426214,
          45.83462199938624
        ],
        [
          8.847326627314207,
          45.83458100069002
        ],
        [
          8.846964739654304,
          45.834429656513386
        ],
        [
          8.846749639050104,
          45.83434026851612
        ],
        [
          8.846458059512296,
          45.83421928154519
        ],
        [
          8.846242113119995,
          45.83413696593035
        ],
        [
          8.846128725525537,
          45.83406389307342
        ],
        [
          8.845987162861444,
          45.83397587802399
        ],
        [
          8.845852702187244,
          45.83387092333341
        ],
        [
          8.84575770589239,
          45.833767288146156
        ],
        [
          8.845682031312382,
          45.83366097699122
        ],
        [
          8.845675957054912,
          45.83362487904736
        ],
        [
          8.845633433740927,
          45.83354120325381
        ],
        [
          8.845583660344117,
          45.83337643566
        ],
        [
          8.84557195126602,
          45.83331441914041
        ],
        [
          8.845549652230828,
          45.83327040032441
        ],
        [
          8.84553647531066,
          45.833211660737945
        ],
        [
          8.845531546592182,
          45.83313154865202
        ],
        [
          8.845461918476323,
          45.833153180176154
        ],
        [
          8.845269881281164,
          45.83323920193651
        ],
        [
          8.84499146099938,
          45.83334772295224
        ],
        [
          8.844663978423085,
          45.83348310732344
        ],
        [
          8.844482203183292,
          45.83358914735809
        ],
        [
          8.844120775902471,
          45.83391626622496
        ],
        [
          8.844009672765413,
          45.83404842354529
        ],
        [
          8.843919744546458,
          45.834164668703686
        ],
        [
          8.843854765653111,
          45.834194023616334
        ],
        [
          8.843741514346389,
          45.8341731656036
        ],
        [
          8.843609254109431,
          45.83404337590792
        ],
        [
          8.843448730409131,
          45.833891045816436
        ],
        [
          8.843249471092728,
          45.83377736498053
        ],
        [
          8.843158269247139,
          45.83369983640025
        ],
        [
          8.843076149077728,
          45.833598025775565
        ],
        [
          8.842899741472973,
          45.83360048355772
        ],
        [
          8.842628834129822,
          45.83359259346716
        ],
        [
          8.842498209956297,
          45.83360267778638
        ],
        [
          8.842410426310305,
          45.83361060149758
        ],
        [
          8.842297661001176,
          45.83296241105973
        ],
        [
          8.8422283411347,
          45.83258568394171
        ],
        [
          8.84221153611575,
          45.83255638805204
        ],
        [
          8.842169025067234,
          45.83245910834736
        ],
        [
          8.842076633503062,
          45.83234827318266
        ],
        [
          8.841967338636096,
          45.83226946573979
        ],
        [
          8.841510765175792,
          45.832115273950826
        ],
        [
          8.841322629401203,
          45.83204632738369
        ],
        [
          8.841097131602814,
          45.83223530766998
        ],
        [
          8.84080149520441,
          45.83251391509357
        ],
        [
          8.84049941029257,
          45.83279160187599
        ],
        [
          8.84025163756461,
          45.83299107108704
        ],
        [
          8.839992177591961,
          45.833200641873844
        ],
        [
          8.839828336289715,
          45.83333519285105
        ],
        [
          8.839667044403626,
          45.8334555748086
        ],
        [
          8.839539234373058,
          45.83357240331511
        ],
        [
          8.839412592265031,
          45.833736027481606
        ],
        [
          8.839267863505862,
          45.83391583435833
        ],
        [
          8.839133348943399,
          45.83413075959748
        ],
        [
          8.839024525988496,
          45.83436011283837
        ],
        [
          8.838894970301006,
          45.83463984913766
        ],
        [
          8.838812238128552,
          45.83475224005042
        ],
        [
          8.838787665742124,
          45.8347774475872
        ],
        [
          8.838738638447753,
          45.83482773410166
        ],
        [
          8.838640581684606,
          45.83489599848537
        ],
        [
          8.83850645562522,
          45.8349696139768
        ],
        [
          8.838333677021096,
          45.83505127697582
        ],
        [
          8.838209874508477,
          45.835121304679994
        ],
        [
          8.838048524424515,
          45.83525787632388
        ],
        [
          8.838010807122759,
          45.835387433387325
        ],
        [
          8.83796645276912,
          45.83558988232972
        ],
        [
          8.837955736334377,
          45.83573296775984
        ],
        [
          8.83798640723719,
          45.83581311843856
        ],
        [
          8.83806851085866,
          45.835919441961664
        ],
        [
          8.838176383284553,
          45.836023101738924
        ],
        [
          8.838191662885539,
          45.83608252556893
        ],
        [
          8.838184968985283,
          45.836170723816586
        ],
        [
          8.838153921875671,
          45.83622018137135
        ],
        [
          8.837953794957368,
          45.83641070335179
        ],
        [
          8.837887800393148,
          45.83652311729574
        ],
        [
          8.837821923303903,
          45.836593226727224
        ],
        [
          8.837757370279094,
          45.83665164081498
        ],
        [
          8.837659403329425,
          45.83668750228879
        ],
        [
          8.837417325227342,
          45.83669075834844
        ],
        [
          8.837293704403312,
          45.83669238250974
        ],
        [
          8.837186023765494,
          45.83670587494575
        ],
        [
          8.837048880455697,
          45.83674963030146
        ],
        [
          8.836930260298606,
          45.83680256284198
        ],
        [
          8.83680404310643,
          45.83687384204013
        ],
        [
          8.836559439536076,
          45.83700711539346
        ],
        [
          8.836490428190258,
          45.83705845151038
        ],
        [
          8.836364035643525,
          45.837131177265455
        ],
        [
          8.83621740488636,
          45.837165081273966
        ],
        [
          8.836169604299483,
          45.83718549452195
        ],
        [
          8.836054685057386,
          45.83723603189501
        ],
        [
          8.835947710122234,
          45.83727008065259
        ],
        [
          8.835831795217548,
          45.837281614958634
        ],
        [
          8.83570692509946,
          45.83726973470381
        ],
        [
          8.835578282062249,
          45.83722814689359
        ],
        [
          8.83544192129528,
          45.83718204572228
        ],
        [
          8.835115152623326,
          45.83707986785043
        ],
        [
          8.835012242755258,
          45.83707029657492
        ],
        [
          8.834870452515325,
          45.83709391725482
        ],
        [
          8.834744132388014,
          45.837141437833424
        ],
        [
          8.834678332929695,
          45.83719070916134
        ],
        [
          8.834607335124703,
          45.837243846786386
        ],
        [
          8.834398333990707,
          45.83737765306445
        ],
        [
          8.834274556147875,
          45.837435966728634
        ],
        [
          8.834123726514227,
          45.83749335353187
        ],
        [
          8.833591261965154,
          45.83771579427416
        ],
        [
          8.833329490978198,
          45.83783961206148
        ],
        [
          8.833204434036507,
          45.83789433145596
        ],
        [
          8.833066460138042,
          45.83795803604541
        ],
        [
          8.832835696599254,
          45.838046802845014
        ],
        [
          8.83253536375405,
          45.838150770063216
        ],
        [
          8.83231245181989,
          45.83819724808301
        ],
        [
          8.83164879065958,
          45.83836547453109
        ],
        [
          8.831479807744875,
          45.83846693223407
        ],
        [
          8.83114969030017,
          45.838620353377856
        ],
        [
          8.831025968969902,
          45.83865796401247
        ],
        [
          8.830805653576412,
          45.83869724244793
        ],
        [
          8.830734745182358,
          45.83872413923428
        ],
        [
          8.83060701621606,
          45.83880495536176
        ],
        [
          8.83054500393497,
          45.838871466059786
        ],
        [
          8.830418312459969,
          45.839038691076105
        ],
        [
          8.830262088347022,
          45.83917345912073
        ],
        [
          8.830149897142656,
          45.839229994390635
        ],
        [
          8.830036594192817,
          45.839224425556836
        ],
        [
          8.829892564253102,
          45.83916030877183
        ],
        [
          8.82955995193491,
          45.83886280052166
        ],
        [
          8.829466238540736,
          45.83876365409341
        ],
        [
          8.829369885906262,
          45.838689707128
        ],
        [
          8.829270809476487,
          45.83866435745544
        ],
        [
          8.829124045772113,
          45.8386569357962
        ],
        [
          8.828942022376134,
          45.838711152188075
        ],
        [
          8.828782937654557,
          45.83883643270333
        ],
        [
          8.828718690714892,
          45.838949381259894
        ],
        [
          8.828624191893926,
          45.839068767543026
        ],
        [
          8.828579757428901,
          45.839178320101325
        ],
        [
          8.828497044887818,
          45.83938224588811
        ],
        [
          8.828394990745753,
          45.83969692643917
        ],
        [
          8.828354000887394,
          45.839965341941955
        ],
        [
          8.82830477863172,
          45.84001292796272
        ],
        [
          8.82782982003044,
          45.840367780153585
        ],
        [
          8.827560977948231,
          45.84069228734755
        ],
        [
          8.82738803379986,
          45.84082073477367
        ],
        [
          8.826974838121723,
          45.84100443128429
        ],
        [
          8.826867203896239,
          45.84100985222221
        ],
        [
          8.826728184358165,
          45.84099074024638
        ],
        [
          8.826153109337143,
          45.84059530937344
        ],
        [
          8.826125222247466,
          45.84057692912419
        ],
        [
          8.825937196960641,
          45.84046661594977
        ],
        [
          8.825610219316271,
          45.84031120900094
        ],
        [
          8.825560577931249,
          45.840315287005375
        ],
        [
          8.825500497190045,
          45.84030933408083
        ],
        [
          8.825300803768153,
          45.840338730924096
        ],
        [
          8.825232119340143,
          45.840415932931876
        ],
        [
          8.824870877700894,
          45.840793877493105
        ],
        [
          8.824247757881267,
          45.84147567546848
        ],
        [
          8.82413779255348,
          45.841635707894184
        ],
        [
          8.824062691838053,
          45.84177335091001
        ],
        [
          8.823868078154048,
          45.841819802892466
        ],
        [
          8.823659162126823,
          45.841913086873916
        ],
        [
          8.823424479755998,
          45.8420045962436
        ],
        [
          8.823304201639232,
          45.84202202538157
        ],
        [
          8.823246216973555,
          45.84203197676723
        ],
        [
          8.823268906507424,
          45.842086495167486
        ],
        [
          8.82331188420758,
          45.8421897615583
        ],
        [
          8.822947514212222,
          45.842331900199206
        ],
        [
          8.822706943883594,
          45.84249753596594
        ],
        [
          8.822302638798106,
          45.842723992948706
        ],
        [
          8.822276898656222,
          45.84277195308765
        ],
        [
          8.822325865034296,
          45.84295722546478
        ],
        [
          8.82232037502015,
          45.843023189483
        ],
        [
          8.822111194279092,
          45.84320053755025
        ],
        [
          8.822120975305621,
          45.843323770904405
        ],
        [
          8.822127021154074,
          45.843447979505456
        ],
        [
          8.821693286921425,
          45.8437722231262
        ],
        [
          8.82140161210336,
          45.843971571772094
        ],
        [
          8.821313486467249,
          45.84414424717383
        ],
        [
          8.821186547773593,
          45.84437625307791
        ],
        [
          8.821097354702946,
          45.84452355036216
        ],
        [
          8.821070027390865,
          45.84456868514374
        ],
        [
          8.821247269389948,
          45.84461921375063
        ],
        [
          8.821325749461593,
          45.84474279490596
        ],
        [
          8.821524667739844,
          45.84496541137331
        ],
        [
          8.821498888740663,
          45.845001631786815
        ],
        [
          8.821420847816311,
          45.84500058368885
        ],
        [
          8.821259272205557,
          45.844995598550504
        ],
        [
          8.821208979625942,
          45.84501622302945
        ],
        [
          8.821205015148978,
          45.84504771889445
        ],
        [
          8.821423569885916,
          45.8451677685171
        ],
        [
          8.821450433784841,
          45.84522361235857
        ],
        [
          8.821362793178384,
          45.84524417673966
        ],
        [
          8.821141262658658,
          45.845253732650185
        ],
        [
          8.821099958857255,
          45.84528246988104
        ],
        [
          8.821113966649877,
          45.845331993258654
        ],
        [
          8.821200135084077,
          45.845369030543814
        ],
        [
          8.821299193777026,
          45.845402489487334
        ],
        [
          8.82140846591629,
          45.84546476259661
        ],
        [
          8.821959524121128,
          45.84591474476455
        ],
        [
          8.822039070962918,
          45.846009374868
        ],
        [
          8.822085101673427,
          45.84611385338277
        ],
        [
          8.822124595645398,
          45.84624891470377
        ],
        [
          8.822127944348637,
          45.84641453075118
        ],
        [
          8.822119413785721,
          45.846666533950255
        ],
        [
          8.82213060323447,
          45.84679255060273
        ],
        [
          8.822233382488546,
          45.84687191571793
        ],
        [
          8.822296357566906,
          45.846913416375756
        ],
        [
          8.822347671149245,
          45.84694468140165
        ],
        [
          8.822416159198578,
          45.84699693096523
        ],
        [
          8.822505861337724,
          45.84706632535882
        ],
        [
          8.822592679452201,
          45.84713348395789
        ],
        [
          8.823289697731015,
          45.847615193549494
        ],
        [
          8.823703278499758,
          45.84756092700687
        ],
        [
          8.823694274301614,
          45.84783091901362
        ],
        [
          8.823690491402667,
          45.847944332265754
        ],
        [
          8.823611470467098,
          45.848088221491665
        ],
        [
          8.823276282581867,
          45.848114276920136
        ],
        [
          8.823163836055933,
          45.84811544224498
        ],
        [
          8.823009871444565,
          45.848134985359785
        ],
        [
          8.822951464774347,
          45.848312469236504
        ],
        [
          8.822870733647319,
          45.84855778648828
        ],
        [
          8.822859010802407,
          45.848599172808214
        ],
        [
          8.822853822350176,
          45.84864034694247
        ],
        [
          8.822953975130714,
          45.84870822706536
        ],
        [
          8.823006486866326,
          45.84880011258161
        ],
        [
          8.823084449171906,
          45.848990147452454
        ],
        [
          8.82312175108146,
          45.849005506192974
        ],
        [
          8.823079173307715,
          45.84903334028988
        ],
        [
          8.823003204193924,
          45.84907452002373
        ],
        [
          8.823034986430017,
          45.84915837099026
        ],
        [
          8.823190642798588,
          45.849259828017445
        ],
        [
          8.823252718575846,
          45.849313478427625
        ],
        [
          8.82368003894893,
          45.8495788142693
        ],
        [
          8.823805004334082,
          45.84962309278624
        ],
        [
          8.823927249760342,
          45.849658381692514
        ],
        [
          8.824362179198454,
          45.84978325908866
        ],
        [
          8.824157119971844,
          45.84994152589045
        ],
        [
          8.823849157106617,
          45.85017921238716
        ],
        [
          8.823878077216186,
          45.85023925304964
        ],
        [
          8.82390666053775,
          45.850309714959636
        ],
        [
          8.823824568956391,
          45.85031770964814
        ],
        [
          8.823239808313891,
          45.85037464882697
        ],
        [
          8.822881979238346,
          45.85030209188443
        ],
        [
          8.82265015225784,
          45.85029903213753
        ],
        [
          8.822645192453507,
          45.85038203951211
        ],
        [
          8.822517062281648,
          45.8504299117807
        ],
        [
          8.822425873187985,
          45.85039786844379
        ],
        [
          8.822302687350586,
          45.85050034748765
        ],
        [
          8.8222684600719,
          45.850546550948145
        ],
        [
          8.822219918147455,
          45.850625868670924
        ],
        [
          8.82244929180242,
          45.85068754536891
        ],
        [
          8.822567064682858,
          45.850721068260235
        ],
        [
          8.822935715484647,
          45.85082600004411
        ],
        [
          8.822895104005115,
          45.8510446512391
        ],
        [
          8.822964575644166,
          45.851069960520284
        ],
        [
          8.822883874435599,
          45.85133625266305
        ],
        [
          8.822879916192251,
          45.85136593764395
        ],
        [
          8.822868044185286,
          45.851454126733266
        ],
        [
          8.822817056187793,
          45.85169616329987
        ],
        [
          8.822948658597172,
          45.85203117986376
        ],
        [
          8.823135061330033,
          45.85255529325146
        ],
        [
          8.823170420170339,
          45.85278216256052
        ],
        [
          8.823211556430737,
          45.85321424316837
        ],
        [
          8.823264224259757,
          45.85367064672348
        ],
        [
          8.823276258863384,
          45.85394709465765
        ],
        [
          8.823278642116456,
          45.85400189025448
        ],
        [
          8.823247052930025,
          45.85421514425919
        ],
        [
          8.823194279314373,
          45.85420336186641
        ],
        [
          8.823051219430111,
          45.854174181068274
        ],
        [
          8.822420663314757,
          45.85404556121459
        ],
        [
          8.821896354661005,
          45.85406993782251
        ],
        [
          8.821846877234343,
          45.85423416261772
        ],
        [
          8.821580074209502,
          45.85443215684257
        ],
        [
          8.821403905119093,
          45.85456014434242
        ],
        [
          8.821370694487387,
          45.85458229235132
        ],
        [
          8.821000072827395,
          45.854408750111624
        ],
        [
          8.820662343196096,
          45.85408691078948
        ],
        [
          8.820248908302563,
          45.85407995897647
        ],
        [
          8.820091726632509,
          45.8540923142198
        ],
        [
          8.819627580504774,
          45.85423198250255
        ],
        [
          8.819708894072484,
          45.85432202838423
        ],
        [
          8.819827803780159,
          45.85445371255342
        ],
        [
          8.819898202831576,
          45.854592432402185
        ],
        [
          8.81989284748637,
          45.85465452613403
        ],
        [
          8.819873386867894,
          45.85469770002244
        ],
        [
          8.819829385900553,
          45.85476153474009
        ],
        [
          8.819751741930359,
          45.85487661065553
        ],
        [
          8.819700504025556,
          45.854944485064664
        ],
        [
          8.81972554375799,
          45.855012476213645
        ],
        [
          8.81976527696819,
          45.85507284454464
        ],
        [
          8.81991923713485,
          45.85526209755348
        ],
        [
          8.81995512065302,
          45.85531885951893
        ],
        [
          8.819963893354348,
          45.855394467367105
        ],
        [
          8.819962470010429,
          45.855440369854655
        ],
        [
          8.819940258703605,
          45.85553753926057
        ],
        [
          8.819928366624174,
          45.85563022643567
        ],
        [
          8.819944920265051,
          45.85581854992963
        ],
        [
          8.81993779123517,
          45.85590295867808
        ],
        [
          8.81988617787112,
          45.85597798352239
        ],
        [
          8.819839446078085,
          45.85604590378581
        ],
        [
          8.819817368016363,
          45.85610167433022
        ],
        [
          8.819828471606352,
          45.85616308924949
        ],
        [
          8.819853794368795,
          45.856241778883344
        ],
        [
          8.82045211193003,
          45.85618997901277
        ],
        [
          8.820555273728104,
          45.85615503702587
        ],
        [
          8.820757735485433,
          45.85608425205741
        ],
        [
          8.821523080875085,
          45.856016152801104
        ],
        [
          8.821600502569225,
          45.855973972804435
        ],
        [
          8.821688316801355,
          45.855900303364464
        ],
        [
          8.821741195223728,
          45.8558796863473
        ],
        [
          8.821812040945867,
          45.855879796844356
        ],
        [
          8.821907406615136,
          45.85586554459743
        ],
        [
          8.821993672232392,
          45.85587737975927
        ],
        [
          8.822193245147217,
          45.85586418961624
        ],
        [
          8.822629686057546,
          45.85564066810543
        ],
        [
          8.822897457372854,
          45.85564835859588
        ],
        [
          8.823073796648986,
          45.8556531500522
        ],
        [
          8.82306497857468,
          45.85561772093949
        ],
        [
          8.823187949344026,
          45.85560899801405
        ],
        [
          8.823205262495307,
          45.855651760427996
        ],
        [
          8.823214114145273,
          45.85567946888919
        ],
        [
          8.823906021809744,
          45.85606665466152
        ],
        [
          8.824092412449346,
          45.856189077726775
        ],
        [
          8.824137429456105,
          45.856319473516734
        ],
        [
          8.824316191431382,
          45.856837186015206
        ],
        [
          8.824215838516201,
          45.85688041264039
        ],
        [
          8.823984008488752,
          45.85703702574843
        ],
        [
          8.823867721535418,
          45.857149344711836
        ],
        [
          8.823712097532372,
          45.85748482784502
        ],
        [
          8.823669184908871,
          45.85761256794309
        ],
        [
          8.823614775536402,
          45.85770968293535
        ],
        [
          8.823329395473689,
          45.85793425633406
        ],
        [
          8.822882844672101,
          45.85820627333126
        ],
        [
          8.822642721960955,
          45.85843800218446
        ],
        [
          8.822438045648553,
          45.85873840594934
        ],
        [
          8.822384566933003,
          45.858778077450744
        ],
        [
          8.822165611636963,
          45.85894049689573
        ],
        [
          8.822173421089126,
          45.85912016760219
        ],
        [
          8.82217641079554,
          45.859188920397614
        ],
        [
          8.822134838908008,
          45.859298662631545
        ],
        [
          8.822034173533261,
          45.85936240273152
        ],
        [
          8.82191682418043,
          45.85940002949537
        ],
        [
          8.821718000656581,
          45.85953832821391
        ],
        [
          8.821645535031479,
          45.85964292953758
        ],
        [
          8.821584714118005,
          45.859730721534284
        ],
        [
          8.821459379146994,
          45.85984933326482
        ],
        [
          8.82136131197804,
          45.85990048503179
        ],
        [
          8.821201337470516,
          45.859978537965404
        ],
        [
          8.820904696068867,
          45.86008967145454
        ],
        [
          8.820494745600522,
          45.860186234324416
        ],
        [
          8.820387673693036,
          45.860233770880285
        ],
        [
          8.820213416367435,
          45.860346002129
        ],
        [
          8.819983802174054,
          45.86044193769561
        ],
        [
          8.819797938455396,
          45.86047860646749
        ],
        [
          8.81972539136629,
          45.86050886354975
        ],
        [
          8.819505388169132,
          45.860596894616315
        ],
        [
          8.819338765972764,
          45.8607325398562
        ],
        [
          8.819266519198935,
          45.86076932793138
        ],
        [
          8.819195617687733,
          45.86078451493066
        ],
        [
          8.819052707333693,
          45.86075908893455
        ],
        [
          8.818587909994696,
          45.860681845363594
        ],
        [
          8.818226040133666,
          45.8606488700541
        ],
        [
          8.817914203874874,
          45.86067627353063
        ],
        [
          8.817786685731306,
          45.86067066092181
        ],
        [
          8.817590853600741,
          45.86067754893427
        ],
        [
          8.817503213787994,
          45.86069000979326
        ],
        [
          8.817157002979073,
          45.860716335635146
        ],
        [
          8.817132020160953,
          45.86075062201647
        ],
        [
          8.816807277613897,
          45.86077010652029
        ],
        [
          8.815495678056095,
          45.860848797964216
        ],
        [
          8.815426418005433,
          45.860979193418274
        ],
        [
          8.815085541983581,
          45.86162093254933
        ],
        [
          8.814879962829213,
          45.86200794618237
        ],
        [
          8.814792975925956,
          45.862214820000126
        ],
        [
          8.814746198365857,
          45.862335351728134
        ],
        [
          8.814726617055545,
          45.86241362488446
        ],
        [
          8.814666544404421,
          45.86275977373668
        ],
        [
          8.814645901919466,
          45.86282622921331
        ],
        [
          8.814620173475841,
          45.86290115496474
        ],
        [
          8.814525266932158,
          45.86313616585715
        ],
        [
          8.814484578173847,
          45.86354008463483
        ],
        [
          8.814449522164061,
          45.86362192202716
        ],
        [
          8.814313599295126,
          45.863817915275824
        ],
        [
          8.81421544537472,
          45.863986575661166
        ],
        [
          8.814152943912351,
          45.864093969526344
        ],
        [
          8.814110639486106,
          45.86427347990296
        ],
        [
          8.813854779890072,
          45.864972091891104
        ],
        [
          8.81380044016362,
          45.86493957434308
        ],
        [
          8.813736061114502,
          45.8651013354818
        ],
        [
          8.813700763345405,
          45.86525878052478
        ],
        [
          8.81351417171615,
          45.86558339453642
        ],
        [
          8.813501032606334,
          45.865659879640106
        ],
        [
          8.813469703337663,
          45.865782227096204
        ],
        [
          8.813148637355932,
          45.86663944668252
        ],
        [
          8.813035586030862,
          45.86693086900282
        ],
        [
          8.812875002799,
          45.86760974219373
        ],
        [
          8.812858796033757,
          45.86779102373691
        ],
        [
          8.81339519990482,
          45.868630785920104
        ],
        [
          8.813802127166133,
          45.86926862857531
        ],
        [
          8.814769582964258,
          45.870872281050524
        ],
        [
          8.814951002563781,
          45.871340594571905
        ],
        [
          8.814987701959561,
          45.871541368045115
        ],
        [
          8.814850039077164,
          45.87226387112221
        ],
        [
          8.814891558006508,
          45.87256545493501
        ],
        [
          8.814862906416964,
          45.872657215902144
        ],
        [
          8.814788737566179,
          45.87281014821808
        ],
        [
          8.81444707904965,
          45.873370626819664
        ],
        [
          8.81460749099907,
          45.87352884676709
        ],
        [
          8.81490098126768,
          45.873774734127224
        ],
        [
          8.815562352872368,
          45.87432881951148
        ],
        [
          8.815540916783132,
          45.87469725819771
        ],
        [
          8.815503655803026,
          45.87526113160715
        ],
        [
          8.815468510057613,
          45.87579302943753
        ],
        [
          8.81530419421359,
          45.87598009495901
        ],
        [
          8.814996408649504,
          45.87633049129652
        ],
        [
          8.814793313784321,
          45.87657137681685
        ],
        [
          8.814746160052847,
          45.876692490867434
        ],
        [
          8.814621933044473,
          45.87701153498524
        ],
        [
          8.814577578158712,
          45.877125449464955
        ],
        [
          8.814618709379625,
          45.87755393469955
        ],
        [
          8.81476591253133,
          45.87808177601641
        ],
        [
          8.814804294140952,
          45.878203975536096
        ],
        [
          8.814617132577848,
          45.87845514079926
        ],
        [
          8.814536885550515,
          45.87856283433267
        ],
        [
          8.81450522812918,
          45.878626594962284
        ],
        [
          8.814484537043004,
          45.87868722679831
        ],
        [
          8.814452991037328,
          45.87877554826022
        ],
        [
          8.814392456038464,
          45.87894503087931
        ],
        [
          8.814283281963968,
          45.87922746136672
        ],
        [
          8.814198851189595,
          45.8794280378879
        ],
        [
          8.81386687909233,
          45.8798260935855
        ],
        [
          8.813782343775639,
          45.87990293924216
        ],
        [
          8.813448912241393,
          45.88007303475311
        ],
        [
          8.813318618448527,
          45.880113141671245
        ],
        [
          8.813178412092574,
          45.88015629961756
        ],
        [
          8.812550642057237,
          45.880598988944655
        ],
        [
          8.812219755129357,
          45.88098608970712
        ],
        [
          8.812045455192331,
          45.881189995567745
        ],
        [
          8.811922041992656,
          45.88133436988558
        ],
        [
          8.81144433443208,
          45.88189320969374
        ],
        [
          8.811302184607165,
          45.88205950290533
        ],
        [
          8.811294548666611,
          45.882135343183265
        ],
        [
          8.811271912801386,
          45.882360151696766
        ],
        [
          8.811317177287222,
          45.88250423315592
        ],
        [
          8.811328647763192,
          45.88259536537799
        ],
        [
          8.811355339014332,
          45.88280733685896
        ],
        [
          8.811049075998996,
          45.882749045647614
        ],
        [
          8.810410956859464,
          45.882659243486295
        ],
        [
          8.808889133617695,
          45.882994219423736
        ],
        [
          8.80751302629633,
          45.882925307584614
        ],
        [
          8.806666157485893,
          45.88335319245475
        ],
        [
          8.806291288447499,
          45.88368287887307
        ],
        [
          8.806114457952193,
          45.883760883593254
        ],
        [
          8.805392546263429,
          45.88382444946343
        ],
        [
          8.805106431510081,
          45.88382756206018
        ],
        [
          8.803918917328026,
          45.88379717169561
        ],
        [
          8.803883946202804,
          45.88377723851297
        ],
        [
          8.802988475788192,
          45.88305046513789
        ],
        [
          8.802504740401961,
          45.8826539460669
        ],
        [
          8.80223833977635,
          45.88254997922401
        ],
        [
          8.801951492756004,
          45.88239827350354
        ],
        [
          8.801654196757449,
          45.88228975147797
        ],
        [
          8.801543611017337,
          45.88222115363134
        ],
        [
          8.80140874199421,
          45.882092220710376
        ],
        [
          8.801224931766386,
          45.88195869323816
        ],
        [
          8.800702621835795,
          45.88170846872745
        ],
        [
          8.800082424309378,
          45.88144098039348
        ],
        [
          8.799960198739544,
          45.88138496318699
        ],
        [
          8.799782621154794,
          45.88131174786541
        ],
        [
          8.799505973011765,
          45.881195155923976
        ],
        [
          8.799438979202348,
          45.881168565990684
        ],
        [
          8.799278196902163,
          45.88110474891491
        ],
        [
          8.798988223243132,
          45.88099958098795
        ],
        [
          8.79887140167306,
          45.88116756841549
        ],
        [
          8.798842609738907,
          45.88120896879535
        ],
        [
          8.79857670391278,
          45.881591334505735
        ],
        [
          8.798519426865106,
          45.88155584073336
        ],
        [
          8.798412118604244,
          45.88148933824964
        ],
        [
          8.798369663329519,
          45.881469462172234
        ],
        [
          8.798273763708785,
          45.88140988219657
        ],
        [
          8.7975889250275,
          45.88098440221195
        ],
        [
          8.797539919856575,
          45.881029499880974
        ],
        [
          8.797507887226404,
          45.88101414221464
        ],
        [
          8.797461167382735,
          45.881059438310835
        ],
        [
          8.797397610836528,
          45.88114688464121
        ],
        [
          8.797321859395412,
          45.881214063504814
        ],
        [
          8.797262032877715,
          45.88118078590967
        ],
        [
          8.796740290818576,
          45.880890552183544
        ],
        [
          8.796043123815606,
          45.88053468890639
        ],
        [
          8.794994925211254,
          45.87997028695807
        ],
        [
          8.794278179148728,
          45.87958296945365
        ],
        [
          8.793945469168458,
          45.87940317650834
        ],
        [
          8.794186481277995,
          45.878815318617
        ],
        [
          8.794266632008869,
          45.878619815551126
        ],
        [
          8.793942415614424,
          45.878517527093166
        ],
        [
          8.793473884185802,
          45.87833936885561
        ],
        [
          8.793193401472125,
          45.878221863584166
        ],
        [
          8.792978683567025,
          45.878098114303015
        ],
        [
          8.792901751520317,
          45.87804085748116
        ],
        [
          8.792863003284246,
          45.87801155259853
        ],
        [
          8.792767093367768,
          45.87781336863893
        ],
        [
          8.79278029778421,
          45.87772428544239
        ],
        [
          8.79282605998299,
          45.87760510856621
        ],
        [
          8.792853167946616,
          45.87753451697212
        ],
        [
          8.792821234630589,
          45.877457954489984
        ],
        [
          8.792748015295537,
          45.877395716189945
        ],
        [
          8.792611667373937,
          45.87733588524076
        ],
        [
          8.792262783684178,
          45.877241825919555
        ],
        [
          8.79293951907958,
          45.876832640313125
        ],
        [
          8.79345875858638,
          45.87649516684429
        ],
        [
          8.793855327616097,
          45.876228568648024
        ],
        [
          8.794614918568385,
          45.875704313742744
        ],
        [
          8.79507556869667,
          45.8753962214225
        ],
        [
          8.795371864963638,
          45.87519804829295
        ],
        [
          8.79554107439489,
          45.87508314421539
        ],
        [
          8.793736281280665,
          45.874262662113004
        ],
        [
          8.793548483021324,
          45.87417411583268
        ],
        [
          8.792490823312665,
          45.87376718633193
        ],
        [
          8.79213822319441,
          45.87364503811648
        ],
        [
          8.791510118657008,
          45.87346298107252
        ],
        [
          8.790921396172696,
          45.87308479151168
        ],
        [
          8.790285369723378,
          45.8728719448508
        ],
        [
          8.789988403932716,
          45.87277256354953
        ],
        [
          8.78991891693658,
          45.87274813434403
        ],
        [
          8.789342569880862,
          45.87251305906443
        ],
        [
          8.789278543530585,
          45.87240764360284
        ],
        [
          8.78921599981618,
          45.8722816105821
        ],
        [
          8.788891165454615,
          45.87166760462764
        ],
        [
          8.788812870009393,
          45.87151960882904
        ],
        [
          8.787182710816488,
          45.871188134591094
        ],
        [
          8.787001914299648,
          45.87115757568612
        ],
        [
          8.78681202986893,
          45.8711484010487
        ],
        [
          8.786107709377802,
          45.87102952418211
        ],
        [
          8.785836020350049,
          45.87098366603726
        ],
        [
          8.785259154210378,
          45.870892577923875
        ],
        [
          8.785188013898349,
          45.87088273081849
        ],
        [
          8.784039651816897,
          45.870723769501716
        ],
        [
          8.783884402501272,
          45.87088027278474
        ],
        [
          8.783826742913323,
          45.87093839548305
        ],
        [
          8.783400679580138,
          45.87136789058146
        ],
        [
          8.782494057766801,
          45.87224820430906
        ],
        [
          8.782225733721596,
          45.87251348485061
        ],
        [
          8.781882553128876,
          45.872778060255996
        ],
        [
          8.78004750832699,
          45.872840248630816
        ],
        [
          8.779574545225994,
          45.872893121848506
        ],
        [
          8.779190330198942,
          45.87292859196484
        ],
        [
          8.779091557503298,
          45.8729405788009
        ],
        [
          8.777646556631414,
          45.872971499440226
        ],
        [
          8.777037111263901,
          45.87329252431778
        ],
        [
          8.7769076327235,
          45.87334559894907
        ],
        [
          8.776311135375746,
          45.87359011266607
        ],
        [
          8.77629330366284,
          45.87354512452897
        ],
        [
          8.776260457939808,
          45.87338395583731
        ],
        [
          8.776241111242614,
          45.8732207623408
        ],
        [
          8.776139216928113,
          45.87292265545643
        ],
        [
          8.774872469769479,
          45.87299684249538
        ],
        [
          8.77454190652855,
          45.872663751937225
        ],
        [
          8.773689165176846,
          45.87180447060439
        ],
        [
          8.773306359326186,
          45.87124641398119
        ],
        [
          8.773268983143637,
          45.87120150988622
        ],
        [
          8.773178074050653,
          45.871060920885355
        ],
        [
          8.773127902562884,
          45.8709934917675
        ],
        [
          8.773020455581886,
          45.870849093169475
        ],
        [
          8.772894928314177,
          45.87066704343147
        ],
        [
          8.772855210275743,
          45.87061205932517
        ],
        [
          8.772903876777661,
          45.870554692033394
        ],
        [
          8.772905415331254,
          45.87049802581942
        ],
        [
          8.773020037564905,
          45.86999585805361
        ],
        [
          8.773062223220885,
          45.86976102742311
        ],
        [
          8.773144523343026,
          45.869606084314285
        ],
        [
          8.772813611391879,
          45.86964401856197
        ],
        [
          8.772828871812152,
          45.86948138820859
        ],
        [
          8.772934773537937,
          45.86835690927668
        ],
        [
          8.772253649811477,
          45.8683285494278
        ],
        [
          8.769922118228834,
          45.868224875376
        ],
        [
          8.769119925795163,
          45.868263752679226
        ],
        [
          8.768558538127573,
          45.86829412077311
        ],
        [
          8.7677312951963,
          45.868454449578614
        ],
        [
          8.76702917509489,
          45.868538530943304
        ],
        [
          8.766318582995336,
          45.86862258976799
        ],
        [
          8.764933182449761,
          45.86852524331663
        ],
        [
          8.76385378868681,
          45.86860852755221
        ],
        [
          8.762462586830859,
          45.86864164449995
        ],
        [
          8.761223194285998,
          45.869233082681035
        ],
        [
          8.760064118609401,
          45.869212663615315
        ],
        [
          8.758699276772287,
          45.869101773128676
        ],
        [
          8.756804316633279,
          45.869520791811404
        ],
        [
          8.755063665300694,
          45.86981408596479
        ],
        [
          8.753826989612365,
          45.86976641401602
        ],
        [
          8.752683329832047,
          45.86934992265046
        ],
        [
          8.751769206663338,
          45.869203941585226
        ],
        [
          8.749656838087045,
          45.86915433866374
        ],
        [
          8.748135168996592,
          45.869547009322126
        ],
        [
          8.746987049242126,
          45.86994949965553
        ],
        [
          8.745619711520034,
          45.87038747483757
        ],
        [
          8.744433994949539,
          45.87055584861908
        ],
        [
          8.743298995971701,
          45.870895310214955
        ],
        [
          8.74197007373747,
          45.87128835039863
        ],
        [
          8.740835279830092,
          45.87158279419545
        ],
        [
          8.740035729069266,
          45.871814985126036
        ],
        [
          8.738506771022255,
          45.868319308157
        ],
        [
          8.737996671120776,
          45.867202077019584
        ]
      ]
    ]
  }
}
