import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = ["movable"]

  static values = {
    amount: {
      type: Number,
      default: 25,
    },
  }

  connect() {
    this.movableTargets.forEach((movable) => {
      movable.style.transform = `translateX(${anime.random(-1 * this.amountValue, this.amountValue)}px) translateY(${anime.random(-1 * this.amountValue, this.amountValue)}px)`
    })

    animate(this)
  }
}

function animate(controller) {
  anime({
    targets: controller.movableTargets,
    translateX: function () {
      return anime.random(-1 * controller.amountValue, controller.amountValue)
    },
    translateY: function () {
      return anime.random(-1 * controller.amountValue, controller.amountValue)
    },
    easing: "easeInOutQuad",
    duration: 5000,
    complete: () => animate(controller),
  })
}
