import { Controller } from "@hotwired/stimulus"
import Shuffle from "shufflejs"

export default class extends Controller {
  static targets = ["grid", "filter"]

  static values = {
    currentCategory: String
  }

  connect() {
    this.shuffle = new Shuffle(this.gridTarget, {
      itemSelector: ".shuffle-item",
      easing: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
    })
  }

  filter({ params }) {
    if (this.currentCategoryValue != params.category) {
      this.shuffle.filter(params.category)
      this.currentCategoryValue = params.category
    } else {
      this.shuffle.filter(Shuffle.ALL_ITEMS)
      this.currentCategoryValue = ""
    }
  }

  currentCategoryValueChanged(value) {
    if (value != "") {
      this.filterTargets.forEach(filter => {
        if (filter.dataset.policiesCategoryParam == value) {
          filter.style.opacity = 1
        } else {
          filter.style.opacity = 0.5
        }
      })
    } else {
      this.filterTargets.forEach(filter => {
        filter.style.opacity = 1
      })
    }
  }
}
