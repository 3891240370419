import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import geojsons from "../../geojsons/**/*.json"

export default class extends Controller {
  static targets = ["root", "data", "areaLink"]

  static values = {
    coordinates: Object,
    zoom: Number,
  }

  initialize() {
    this.areas = {
      type: "FeatureCollection",
      features: [],
    }
    Object.entries(geojsons).forEach(([_, geojson]) => {
      this.areas.features.push(geojson)
    })
  }

  connect() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoidG9tbWFzb25nciIsImEiOiJjazdjM2ZuMXowMG9zM2dxam10eW5qc3BsIn0._qrzztL_YLNvgnFdv89Xew"

    // const coordinates = new mapboxgl.LngLat(this.coordinatesValue.longitude, this.coordinatesValue.latitude)
    this.hoveredAreaId = null
    this.currentAreaId = null

    this.map = new mapboxgl.Map({
      container: this.rootTarget, // container ID
      style: "mapbox://styles/tommasongr/cl8sjv9g2000e16phllnuufu3",
      projection: "globe",
      center: [this.coordinatesValue.longitude, this.coordinatesValue.latitude],
      zoom: this.zoomValue,
      minZoom: 6,
      maxZoom: 10,
      // bearing: 140,
      pitch: 30,
      minPitch: 25,
      maxPitch: 55,
      maxBounds: [
        [-4.7974445937815915, 30.382992605970898],
        [26.53418619524095, 52.53258173819853],
      ],

      doubleClickZoom: false,
      // dragPan: false,
      // minPitch: 50,
      // pitchWithRotate: false,
      // scrollZoom: false,
      attributionControl: false,
    })

    this.map.on("load", () => {
      // Load terrain
      this.map.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14,
      })

      this.map.setTerrain({ source: "mapbox-dem", exaggeration: 2 })

      // Lombardy
      const lombardy = { ...this.areas }
      lombardy.features = lombardy.features.filter((_, index) => index == 0)

      this.map.addSource("lombardy", {
        type: "geojson",
        data: lombardy,
      })

      this.map.addLayer({
        id: "lombardy-line",
        type: "line",
        source: "lombardy",
        layout: {},
        paint: {
          "line-color": "rgba(255, 255, 255, 1)",
        },
      })

      // Upcoming areas
      const upcomingAreas = { ...this.areas }
      upcomingAreas.features = upcomingAreas.features.filter((area, index) => {
        if (
          !this.areaLinkTargets.find(
            (el) => el.dataset.geoId == area.properties.AREA_NAME,
          ) &&
          index != 0
        ) {
          return true
        }
      })

      this.map.addSource("upcoming-areas", {
        type: "geojson",
        data: upcomingAreas,
      })

      this.map.addLayer({
        id: "upcoming-area-fills",
        type: "fill",
        source: "upcoming-areas",
        layout: {},
        paint: {
          "fill-color": "rgba(255, 255, 255, 1)",
          "fill-opacity": 0.1,
        },
      })

      this.map.addLayer({
        id: "upcoming-area-lines",
        type: "line",
        source: "upcoming-areas",
        layout: {},
        paint: {
          "line-color": "rgba(255, 255, 255, 1)",
          "line-opacity": 0.6,
        },
      })

      // Visible areas
      const visibleAreas = { ...this.areas }
      visibleAreas.features = visibleAreas.features.filter((area) => {
        if (
          this.areaLinkTargets.find(
            (el) => el.dataset.geoId == area.properties.AREA_NAME,
          )
        ) {
          return true
        }
      })

      this.map.addSource("areas", {
        type: "geojson",
        data: visibleAreas,
      })

      this.map.addLayer({
        id: "area-fills",
        type: "fill",
        source: "areas",
        layout: {},
        paint: {
          "fill-color": "rgba(218, 20, 56, 1)",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "current"], false],
            0.6,
            ["boolean", ["feature-state", "hover"], false],
            0.4,
            0.1,
          ],
        },
      })

      this.map.addLayer({
        id: "area-lines",
        type: "line",
        source: "areas",
        layout: {},
        paint: {
          "line-color": "rgba(218, 20, 56, 1)",
        },
      })

      // States
      this.map.on("mousemove", "area-fills", (e) => {
        if (e.features.length > 0) {
          if (this.hoveredAreaId !== null) {
            this.map.setFeatureState(
              { source: "areas", id: this.hoveredAreaId },
              { hover: false },
            )
            this.areaLinkTargets.forEach((el) =>
              el.classList.remove("text-primary"),
            )
          }
          this.hoveredAreaId = e.features[0].id
          this.map.setFeatureState(
            { source: "areas", id: this.hoveredAreaId },
            { hover: true },
          )
          this.map.getCanvas().style.cursor = "pointer"
          this.currentAreaLink(e)?.classList.add("text-primary")
        }
      })

      this.map.on("mouseleave", "area-fills", () => {
        if (this.hoveredAreaId !== null) {
          this.map.setFeatureState(
            { source: "areas", id: this.hoveredAreaId },
            { hover: false },
          )
        }
        this.hoveredAreaId = null
        this.map.getCanvas().style.cursor = ""
        this.areaLinkTargets.forEach((el) =>
          el.classList.remove("text-primary"),
        )
      })

      this.map.on("click", "area-fills", (e) => {
        if (e.features.length > 0) {
          this.currentAreaLink(e)?.click()
        }
      })

      // Set initial current area
      const area = this.areas.features.filter(
        (el) => el.properties.AREA_NAME == this.dataTarget.dataset.geoId,
      )[0]
      this.highlightCurrent(area)
    })

    // Reveal the map after loading
    this.map.on("idle", () => {
      this.rootTarget.classList.remove("opacity-0")
    })
  }

  dataTargetConnected(element) {
    let area = null

    area = this.areas.features.filter(
      (el) => el.properties.AREA_NAME == element.dataset.geoId,
    )[0]
    if (!area) return

    this.coordinatesValue = area.properties.center
    this.zoomValue = area.properties.zoom

    this.fly()
    this.highlightCurrent(area)
  }

  fly() {
    this.map?.flyTo({
      center: [this.coordinatesValue.longitude, this.coordinatesValue.latitude],
      zoom: this.zoomValue,
      bearing: 0,
      pitch: 30,
      essential: true,
      duration: 2000,
      easing: function (x) {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
      },
    })
  }

  addHoverToArea({ currentTarget }) {
    const area = this.areas.features.filter(
      (el) => el.properties.AREA_NAME == currentTarget.dataset.geoId,
    )[0]

    if (area?.id) {
      this.map?.setFeatureState(
        { source: "areas", id: area.id },
        { hover: true },
      )
      this.hoveredAreaId = area.id
    }
  }

  removeHoverToArea() {
    if (this.hoveredAreaId) {
      this.map?.setFeatureState(
        { source: "areas", id: this.hoveredAreaId },
        { hover: false },
      )
    }
  }

  // Private

  currentAreaLink(e) {
    return this.areaLinkTargets?.filter(
      (el) => el.dataset.geoId == e.features[0].properties.AREA_NAME,
    )[0]
  }

  highlightCurrent(area) {
    if (this.currentAreaId) {
      this.map?.setFeatureState(
        { source: "areas", id: this.currentAreaId },
        { current: false },
      )
    }
    if (area?.id) {
      this.map?.setFeatureState(
        { source: "areas", id: area.id },
        { current: true },
      )
      this.currentAreaId = area.id
    }
  }
}
