
        import * as module0 from './controllers/blocks/map_section_controller.js';import * as module1 from './controllers/blocks/map_viewer_controller.js';import * as module2 from './controllers/blocks/scrollable_container_controller.js';import * as module3 from './controllers/blocks/slider_controller.js';import * as module4 from './controllers/blocks/video_controller.js';import * as module5 from './controllers/details_controller.js';import * as module6 from './controllers/document/jumper_controller.js';import * as module7 from './controllers/document/menu_controller.js';import * as module8 from './controllers/hidable_controller.js';import * as module9 from './controllers/home_slider_controller.js';import * as module10 from './controllers/intersection_controller.js';import * as module11 from './controllers/lazy_image_controller.js';import * as module12 from './controllers/navigation/map_controller.js';import * as module13 from './controllers/policies_controller.js';import * as module14 from './controllers/random_move_controller.js';import * as module15 from './controllers/turbo/drive_controller.js';import * as module16 from './controllers/wave_controller.js';import * as module17 from './controllers/zoom_controller.js'
        const modules = {
            "./controllers/blocks/map_section_controller.js": module0,
            "./controllers/blocks/map_viewer_controller.js": module1,
            "./controllers/blocks/scrollable_container_controller.js": module2,
            "./controllers/blocks/slider_controller.js": module3,
            "./controllers/blocks/video_controller.js": module4,
            "./controllers/details_controller.js": module5,
            "./controllers/document/jumper_controller.js": module6,
            "./controllers/document/menu_controller.js": module7,
            "./controllers/hidable_controller.js": module8,
            "./controllers/home_slider_controller.js": module9,
            "./controllers/intersection_controller.js": module10,
            "./controllers/lazy_image_controller.js": module11,
            "./controllers/navigation/map_controller.js": module12,
            "./controllers/policies_controller.js": module13,
            "./controllers/random_move_controller.js": module14,
            "./controllers/turbo/drive_controller.js": module15,
            "./controllers/wave_controller.js": module16,
            "./controllers/zoom_controller.js": module17,
        };
        export default modules;
      