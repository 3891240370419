import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "wrapper",
    "menuButton",
    "mainActions",
    "index",
    "documents",
  ]

  static values = {
    open: Boolean,
    presentIndex: Boolean,
    presentDocuments: Boolean,
  }

  showHide() {
    if (window.pageYOffset > 550) {
      this.element.style = "transform: translateX(0px); opacity: 1"
    } else {
      this.openValue = false
      this.presentIndexValue = false
      this.presentDocumentsValue = false
      this.wrapperTarget.style = "width: 44px"

      this.element.style = "transform: translateX(-80px); opacity: 0"

      setTimeout(() => {
        this.mainActionsTarget.classList.add("contents")
        this.mainActionsTarget.classList.remove("hidden")
        this.indexTarget.classList.add("hidden")
        this.indexTarget.classList.remove("contents")
        this.documentsTarget.classList.add("hidden")
        this.documentsTarget.classList.remove("contents")

        this.menuButtonTarget.querySelector("span").innerText = "menu"
        this.menuButtonTarget.title = "Apri menu"
        this.menuButtonTarget.dataset.action = "click->document--menu#toggle"
      }, 500)
    }
  }

  toggle() {
    this.openValue = !this.openValue

    if (this.openValue) {
      this.menuButtonTarget.title = "Chiudi menu"
      this.menuButtonTarget.querySelector("span").innerText = "close"
      this.wrapperTarget.style = "width: 264px"
    } else {
      this.menuButtonTarget.title = "Apri menu"
      this.menuButtonTarget.querySelector("span").innerText = "menu"
      this.wrapperTarget.style = "width: 44px"
    }
  }

  presentIndex() {
    this.wrapperTarget.style = "width: 44px"
    this.presentIndexValue = true

    this.menuButtonTarget.querySelector("span").innerText = "arrow_back"
    this.menuButtonTarget.title = "Indietro"
    this.menuButtonTarget.dataset.action = "click->document--menu#hideIndex"

    setTimeout(() => {
      this.mainActionsTarget.classList.remove("contents")
      this.mainActionsTarget.classList.add("hidden")
      this.indexTarget.classList.remove("hidden")
      this.indexTarget.classList.add("contents")
    }, 450)

    setTimeout(() => {
      this.wrapperTarget.style = "width: 528px"
    }, 500)
  }

  hideIndex() {
    this.wrapperTarget.style = "width: 44px"
    this.presentIndexValue = false

    this.menuButtonTarget.querySelector("span").innerText = "close"
    this.menuButtonTarget.title = "Chiudi menu"
    this.menuButtonTarget.dataset.action = "click->document--menu#toggle"

    setTimeout(() => {
      this.mainActionsTarget.classList.add("contents")
      this.mainActionsTarget.classList.remove("hidden")
      this.indexTarget.classList.add("hidden")
      this.indexTarget.classList.remove("contents")
    }, 450)

    setTimeout(() => {
      this.wrapperTarget.style = "width: 264px"
    }, 500)
  }

  presentDocuments() {
    this.wrapperTarget.style = "width: 44px"
    this.presentDocumentsValue = true

    this.menuButtonTarget.querySelector("span").innerText = "arrow_back"
    this.menuButtonTarget.title = "Indietro"
    this.menuButtonTarget.dataset.action = "click->document--menu#hideDocuments"

    setTimeout(() => {
      this.mainActionsTarget.classList.remove("contents")
      this.mainActionsTarget.classList.add("hidden")
      this.documentsTarget.classList.remove("hidden")
      this.documentsTarget.classList.add("contents")
    }, 450)

    setTimeout(() => {
      this.wrapperTarget.style = "width: 528px"
    }, 500)
  }

  hideDocuments() {
    this.wrapperTarget.style = "width: 44px"
    this.presentDocumentsValue = false

    this.menuButtonTarget.querySelector("span").innerText = "close"
    this.menuButtonTarget.title = "Chiudi menu"
    this.menuButtonTarget.dataset.action = "click->document--menu#toggle"

    setTimeout(() => {
      this.mainActionsTarget.classList.add("contents")
      this.mainActionsTarget.classList.remove("hidden")
      this.documentsTarget.classList.add("hidden")
      this.documentsTarget.classList.remove("contents")
    }, 450)

    setTimeout(() => {
      this.wrapperTarget.style = "width: 264px"
    }, 500)
  }

  scrollTop() {
    this.openValue = false
    this.menuButtonTarget.title = "Apri menu"
    this.menuButtonTarget.querySelector("span").innerText = "menu"
    this.wrapperTarget.style = "width: 44px"

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 450)
  }
}
