import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation, Autoplay, EffectFade } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-fade"

export default class extends Controller {
  static targets = ["root"]

  connect() {
    this.swiper = new Swiper(this.rootTarget, {
      modules: [ EffectFade, Autoplay ],
      speed: 1500,
      effect: "fade",
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    })

    this.swiper.on("slideChangeTransitionStart", (swiper) => {
      const activeSlide = swiper.slides[swiper.activeIndex]
      activeSlide.children[0].classList.add("animate-zoom")
    })

    this.swiper.on("slideChangeTransitionEnd", (swiper) => {
      const previousSlide = swiper.slides[swiper.previousIndex]
      previousSlide.children[0].classList.remove("animate-zoom")
    })
  }

  disconnect() {
    this.swiper.detachEvents()
    this.swiper.destroy(true)
  }
}
