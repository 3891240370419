import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hidable"]
  static classes = ["hidden"]

  hide() {
    if (this.hasHiddenClass) {
      this.hidableTargets.forEach((hidable) => {
        hidable.classList.add(this.hiddenClass)
      })
    } else {
      this.hidableTargets.forEach((hidable) => {
        hidable.remove()
      })
    }

    this.dispatch("hide")
  }
}
