import { Controller } from "@hotwired/stimulus"
import OpenSeadragon from "openseadragon"

export default class extends Controller {
  static targets = ["root", "zoomInButton", "zoomOutButton"]

  static values = {
    source: String,
    zoom: Number,
    center: Object,
  }

  connect() {
    // this.present()
  }

  disconnect() {
    this.dismiss()
  }

  present() {
    if (this.viewer) return

    this.viewer = OpenSeadragon({
      element: this.rootTarget,
      maxZoomLevel: 50,
      animationTime: 3,
      homeFillsViewer: true,
      visibilityRatio: 1,
      minZoomImageRatio: 0.45,
      debugMode: false,

      // Disable default controls
      showHomeControl: false,
      showFullPageControl: false,
      gestureSettingsMouse: {
        clickToZoom: false,
      },

      // Custom buttons
      zoomInButton: this.zoomInButtonTarget.id,
      zoomOutButton: this.zoomOutButtonTarget.id,
    })

    this.viewer.open(this.sourceValue)

    this.viewer.addHandler("open", () => {
      this.viewer.viewport.zoomTo(this.zoomValue, null, true)
      this.viewer.viewport.panTo(
        new OpenSeadragon.Point(this.centerValue.x, this.centerValue.y),
        true,
      )
    })
  }

  dismiss() {
    this.viewer?.destroy()
    this.viewer = null
  }

  goHome() {
    this.viewer?.viewport.zoomTo(this.zoomValue, null, false)
    this.viewer?.viewport.panTo(
      new OpenSeadragon.Point(this.centerValue.x, this.centerValue.y),
      false,
    )
  }
}
