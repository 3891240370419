import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["observable"]

  static values = {
    rootMargin: {
      type: String,
      default: "0px",
    },
    threshold: {
      type: Number,
      default: 0,
    },
  }

  connect() {
    this.observer = new IntersectionObserver(this.intersect.bind(this), {
      rootMargin: this.rootMarginValue,
      threshold: this.thresholdValue,
    })

    if (this.hasObservableTarget) {
      this.observer.observe(this.observableTarget)
    }
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }

  intersect(entries) {
    this.dispatch("intersection", { detail: entries[0] })

    if (entries[0].isIntersecting) {
      this.dispatch("intersecting")
    } else {
      this.dispatch("notIntersecting")
    }
  }
}
