import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation, Autoplay, EffectFade } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-fade"

export default class extends Controller {
  static targets = ["root", "nextButton", "prevButton", "title", "attribution"]

  static values = {
    autoplay: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
  }

  slider = null

  connect() {
    this.slider = new Swiper(this.rootTarget, {
      init: false,
      modules: [Navigation, Autoplay, EffectFade],
      lazy: true,
      effect: "fade",
      // crossFade: true,
      autoplay: {
        enabled: this.autoplayValue,
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      speed: 700,
      loop: true,
      autoHeight: this.autoHeightValue,
      // navigation: {
      //   nextEl: this.hasNextButtonTarget ? this.nextButtonTarget : null,
      //   prevEl: this.hasPrevButtonTarget ? this.prevButtonTarget : null,
      // },
    })

    this.slider.on("afterInit", (slider) => {
      this.updateTitle(slider)
      this.updateAttribution(slider)
    })
    this.slider.on("slideChange", (slider) => {
      this.updateTitle(slider)
      this.updateAttribution(slider)
    })

    this.slider.init()
  }

  disconnect() {
    this.slider.destroy({ completely: true })
    this.slider = null
  }

  update() {
    this.slider.update()
  }

  prev() {
    this.slider.slidePrev()
  }

  next() {
    this.slider.slideNext()
  }

  updateTitle(slider) {
    const slide = slider.slides[slider.activeIndex]

    this.titleTarget.innerText = slide.dataset.title
  }

  updateAttribution(slider) {
    const slide = slider.slides[slider.activeIndex]

    this.attributionTarget.innerText = slide.dataset.attribution
  }
}
