
        import * as module0 from '../../geojsons/0-lombardia.json';import * as module1 from '../../geojsons/1-valchiavenna.json';import * as module2 from '../../geojsons/10-val_seriana_e_val_di_scalve.json';import * as module3 from '../../geojsons/11-valle_brembana_e_valtellina_di_morbegno.json';import * as module4 from '../../geojsons/12-lomellina.json';import * as module5 from '../../geojsons/13-laghi_bergamaschi_sebino_bresciano.json';import * as module6 from '../../geojsons/14-lario_orientale_valle_s_martino_e_valle_imagna.json';import * as module7 from '../../geojsons/2-oltrepo_pavese.json';import * as module8 from '../../geojsons/3-alto_lago_di_como_e_valli_del_lario.json';import * as module9 from '../../geojsons/4-lario_intelvese_e_valli_del_ceresio.json';import * as module10 from '../../geojsons/5-valcamonica.json';import * as module11 from '../../geojsons/6-valtrompia.json';import * as module12 from '../../geojsons/7-valle_sabbia_e_alto_garda.json';import * as module13 from '../../geojsons/8-piambello_e_valli_del_verbano.json';import * as module14 from '../../geojsons/9-oltrepo_mantovano.json'
        const modules = {
            "../../geojsons/0-lombardia.json": module0,
            "../../geojsons/1-valchiavenna.json": module1,
            "../../geojsons/10-val_seriana_e_val_di_scalve.json": module2,
            "../../geojsons/11-valle_brembana_e_valtellina_di_morbegno.json": module3,
            "../../geojsons/12-lomellina.json": module4,
            "../../geojsons/13-laghi_bergamaschi_sebino_bresciano.json": module5,
            "../../geojsons/14-lario_orientale_valle_s_martino_e_valle_imagna.json": module6,
            "../../geojsons/2-oltrepo_pavese.json": module7,
            "../../geojsons/3-alto_lago_di_como_e_valli_del_lario.json": module8,
            "../../geojsons/4-lario_intelvese_e_valli_del_ceresio.json": module9,
            "../../geojsons/5-valcamonica.json": module10,
            "../../geojsons/6-valtrompia.json": module11,
            "../../geojsons/7-valle_sabbia_e_alto_garda.json": module12,
            "../../geojsons/8-piambello_e_valli_del_verbano.json": module13,
            "../../geojsons/9-oltrepo_mantovano.json": module14,
        };
        export default modules;
      