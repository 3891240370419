import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "mapContainer",
    "fullOverlay",
    "cornerOverlay",
    "safeOverlay",
    "legend",
  ]

  static values = {
    isMapExpanded: Boolean,
    isLegendVisible: Boolean,
    isSafeOverlayVisible: {
      type: Boolean,
      default: true,
    },
  }

  toggleFullscreen({ currentTarget }) {
    this.mapContainerTarget.style.transition = "all 500ms"
    this.isMapExpandedValue = !this.isMapExpandedValue

    if (this.isMapExpandedValue) {
      currentTarget.title = "Contrai mappa"
      currentTarget.dataset.isMapExpanded = true
    } else {
      currentTarget.title = "Espandi mappa"
      currentTarget.dataset.isMapExpanded = false
    }
  }

  toggleLegend({ currentTarget }) {
    this.isLegendVisibleValue = !this.isLegendVisibleValue

    if (this.isLegendVisibleValue) {
      currentTarget.title = "Nascondi legenda"
      currentTarget.dataset.isLegendVisible = true
    } else {
      currentTarget.title = "Mostra legenda"
      currentTarget.dataset.isLegendVisible = false
    }
  }

  toggleSafeOverlay({ currentTarget }) {
    if (this.isSafeOverlayVisibleValue) {
      this.isSafeOverlayVisibleValue = false
      currentTarget.dataset.safeOverlayVisible = false
      this.safeOverlayTarget?.classList.add("hidden")
    } else {
      this.isSafeOverlayVisibleValue = true
      currentTarget.dataset.safeOverlayVisible = true
      this.safeOverlayTarget?.classList.remove("hidden")
    }
  }

  isMapExpandedValueChanged(value) {
    if (value == true) {
      this.mapContainerTarget.style.width = "100vw"

      this.fullOverlayTarget.classList.add("opacity-0")
      this.fullOverlayTarget.classList.remove("opacity-100")

      this.cornerOverlayTarget.classList.add("opacity-100")
      this.cornerOverlayTarget.classList.remove("opacity-0")
    } else {
      this.mapContainerTarget.style.width = "100%"

      this.fullOverlayTarget.classList.add("opacity-100")
      this.fullOverlayTarget.classList.remove("opacity-0")

      this.cornerOverlayTarget.classList.add("opacity-0")
      this.cornerOverlayTarget.classList.remove("opacity-100")
    }

    setTimeout(() => {
      this.dispatch("mapContainerResized")
    }, 550)
  }

  isLegendVisibleValueChanged(value) {
    if (value == true) {
      if (this.hasLegendTarget) this.legendTarget.classList.remove("hidden")
    } else {
      if (this.hasLegendTarget) this.legendTarget.classList.add("hidden")
    }
  }
}
