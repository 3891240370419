import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = ["path"]

  connect() {
    anime({
      targets: this.pathTarget,
      d: [
        {
          value:
            "M0,160.529L0,0L500,0C500,0 492.719,103.235 345.313,100C168.026,96.109 205.124,235.515 0,160.529Z",
        },
      ],
      easing: "easeOutExpo",
      duration: 4000,
      delay: 500,
    })

    setTimeout(() => {
      anime({
        targets: this.pathTarget,
        d: [
          {
            value:
              "M0,160.529L0,0L500,0C500,0 495.252,115.312 345.313,100C168.9,81.984 183.162,215.45 0,160.529Z",
          },
          {
            value:
              "M0,160.529L0,0L500,0C500,0 490.778,129.751 345.313,100C164.193,62.956 141.462,192.229 0,160.529Z",
          },
          {
            value:
              "M0,160.529L0,0L500,0C500,0 492.719,103.235 345.313,100C168.026,96.109 205.124,235.515 0,160.529Z",
          },
        ],
        easing: "linear",
        direction: "alternate",
        duration: 8000,
        loop: true,
      })
    }, 4500)
  }
}
