import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = ["placeholder"]

  observer = null

  connect() {
    this.addPlaceholder()

    this.observer = new IntersectionObserver(
      (entries) => {
        const video = entries[0]

        if (!video.isIntersecting) return

        video.target.src = video.target.dataset.src
      },
      {
        rootMargin: "200px",
      },
    )

    this.observer.observe(this.video)
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }

  addPlaceholder() {
    if (this.hasPlaceholderClass) {
      this.placeholderClasses.forEach((className) => {
        this.video.classList.add(className)
      })
    }
  }

  removePlaceholder() {
    if (this.hasPlaceholderClass) {
      this.placeholderClasses.forEach((className) => {
        this.video.classList.remove(className)
      })
    }
  }

  // Private

  get video() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }
}
