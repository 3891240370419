{
  "type": "Feature",
  "properties": {
    "AREA_ID": 4,
    "AREA_NAME": "Lario Intelvese e Valli Lario Ceresio",
    "center": {
      "longitude": 9.14236438458164,
      "latitude": 46.01405030703219
    },
    "zoom": 9
  },
  "id": 4,
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [
          9.095577,
          45.8480124
        ],
        [
          9.096841,
          45.8490734
        ],
        [
          9.0981953,
          45.8501838
        ],
        [
          9.0994721,
          45.8511682
        ],
        [
          9.1015485,
          45.8526605
        ],
        [
          9.1039962,
          45.8535404
        ],
        [
          9.104139,
          45.8539182
        ],
        [
          9.1057256,
          45.8553749
        ],
        [
          9.1077637,
          45.8568851
        ],
        [
          9.1115437,
          45.8603466
        ],
        [
          9.1134287,
          45.8622529
        ],
        [
          9.1176952,
          45.8635536
        ],
        [
          9.1217548,
          45.8646384
        ],
        [
          9.1249126,
          45.8654811
        ],
        [
          9.1294353,
          45.8662951
        ],
        [
          9.1332636,
          45.8670827
        ],
        [
          9.1366406,
          45.8676908
        ],
        [
          9.1411298,
          45.8705565
        ],
        [
          9.1439309,
          45.8725871
        ],
        [
          9.1446829,
          45.8744042
        ],
        [
          9.1455274,
          45.8770403
        ],
        [
          9.1466446,
          45.8804771
        ],
        [
          9.1477484,
          45.8841298
        ],
        [
          9.1493593,
          45.888889
        ],
        [
          9.1500761,
          45.8918492
        ],
        [
          9.1492022,
          45.8927954
        ],
        [
          9.1481247,
          45.8947949
        ],
        [
          9.147217,
          45.8976132
        ],
        [
          9.1460757,
          45.8998918
        ],
        [
          9.1457812,
          45.9004682
        ],
        [
          9.1454017,
          45.9012111
        ],
        [
          9.1443321,
          45.9033051
        ],
        [
          9.1434478,
          45.9053313
        ],
        [
          9.142498,
          45.9069976
        ],
        [
          9.1422294,
          45.907826
        ],
        [
          9.1425064,
          45.9102648
        ],
        [
          9.1425414,
          45.9138559
        ],
        [
          9.1423925,
          45.9159802
        ],
        [
          9.1423647,
          45.9169342
        ],
        [
          9.1422666,
          45.9173124
        ],
        [
          9.1421004,
          45.9178796
        ],
        [
          9.1419728,
          45.9184468
        ],
        [
          9.1417043,
          45.9193292
        ],
        [
          9.1413721,
          45.9205446
        ],
        [
          9.1411432,
          45.921823
        ],
        [
          9.1409009,
          45.9229033
        ],
        [
          9.1405559,
          45.9241548
        ],
        [
          9.1402753,
          45.9254152
        ],
        [
          9.1399695,
          45.9268736
        ],
        [
          9.1397398,
          45.927855
        ],
        [
          9.139768,
          45.928656
        ],
        [
          9.1399391,
          45.9300418
        ],
        [
          9.1399939,
          45.9313018
        ],
        [
          9.1400752,
          45.9328588
        ],
        [
          9.1401556,
          45.9340467
        ],
        [
          9.1389172,
          45.9340032
        ],
        [
          9.1387298,
          45.934008
        ],
        [
          9.1391276,
          45.934975
        ],
        [
          9.1403354,
          45.9372596
        ],
        [
          9.1415232,
          45.9397243
        ],
        [
          9.141636,
          45.9398502
        ],
        [
          9.1422378,
          45.9401374
        ],
        [
          9.143601,
          45.9409727
        ],
        [
          9.143987,
          45.9412018
        ],
        [
          9.1442119,
          45.941341
        ],
        [
          9.1447158,
          45.9416464
        ],
        [
          9.1457512,
          45.9422751
        ],
        [
          9.1479741,
          45.9436223
        ],
        [
          9.1484468,
          45.9430906
        ],
        [
          9.1489247,
          45.943288
        ],
        [
          9.1517521,
          45.9444724
        ],
        [
          9.1541547,
          45.9455132
        ],
        [
          9.1560017,
          45.9462667
        ],
        [
          9.1575904,
          45.9468676
        ],
        [
          9.1596302,
          45.9477558
        ],
        [
          9.162136,
          45.9486973
        ],
        [
          9.1647842,
          45.9497735
        ],
        [
          9.1676903,
          45.9508853
        ],
        [
          9.1679874,
          45.9509929
        ],
        [
          9.1715138,
          45.9525177
        ],
        [
          9.1722632,
          45.9528406
        ],
        [
          9.1759184,
          45.9540951
        ],
        [
          9.1776501,
          45.9547675
        ],
        [
          9.1804363,
          45.9548171
        ],
        [
          9.1838563,
          45.9550726
        ],
        [
          9.1888902,
          45.9554875
        ],
        [
          9.1949041,
          45.9559904
        ],
        [
          9.1991887,
          45.95646
        ],
        [
          9.2017579,
          45.9567885
        ],
        [
          9.2031863,
          45.95801
        ],
        [
          9.2038163,
          45.9585759
        ],
        [
          9.2052396,
          45.9596264
        ],
        [
          9.2068058,
          45.9608836
        ],
        [
          9.2079331,
          45.9620786
        ],
        [
          9.2092413,
          45.9633363
        ],
        [
          9.2107432,
          45.9646116
        ],
        [
          9.2132034,
          45.9667131
        ],
        [
          9.214564,
          45.9681056
        ],
        [
          9.2157955,
          45.9694803
        ],
        [
          9.2163384,
          45.9696773
        ],
        [
          9.2177605,
          45.9702686
        ],
        [
          9.2192993,
          45.9709677
        ],
        [
          9.2214068,
          45.9718727
        ],
        [
          9.224071,
          45.9731455
        ],
        [
          9.226308,
          45.9741042
        ],
        [
          9.2287779,
          45.9751883
        ],
        [
          9.2307052,
          45.9761295
        ],
        [
          9.23187,
          45.9768471
        ],
        [
          9.2333841,
          45.9777261
        ],
        [
          9.2348205,
          45.9785512
        ],
        [
          9.2363479,
          45.97952
        ],
        [
          9.2383932,
          45.9808118
        ],
        [
          9.2390531,
          45.9811705
        ],
        [
          9.2399331,
          45.9816546
        ],
        [
          9.2416672,
          45.982605
        ],
        [
          9.2430002,
          45.9833492
        ],
        [
          9.2448567,
          45.9856854
        ],
        [
          9.2474136,
          45.9886769
        ],
        [
          9.2499839,
          45.9917404
        ],
        [
          9.2525552,
          45.9949569
        ],
        [
          9.2562545,
          45.9990888
        ],
        [
          9.262006,
          46.0002503
        ],
        [
          9.2645769,
          46.0007709
        ],
        [
          9.2641044,
          46.001897
        ],
        [
          9.2633513,
          46.0037618
        ],
        [
          9.2625602,
          46.0058157
        ],
        [
          9.2619607,
          46.0074011
        ],
        [
          9.26118,
          46.0089329
        ],
        [
          9.2609488,
          46.0144506
        ],
        [
          9.2617816,
          46.0157268
        ],
        [
          9.2625514,
          46.0173361
        ],
        [
          9.2631643,
          46.0185587
        ],
        [
          9.2635485,
          46.0205648
        ],
        [
          9.2638948,
          46.0227511
        ],
        [
          9.2631407,
          46.0244629
        ],
        [
          9.2621807,
          46.0263641
        ],
        [
          9.2628588,
          46.0276856
        ],
        [
          9.264109,
          46.0297438
        ],
        [
          9.2647698,
          46.0301203
        ],
        [
          9.2657929,
          46.0306129
        ],
        [
          9.2668566,
          46.0312045
        ],
        [
          9.2679192,
          46.031841
        ],
        [
          9.2684644,
          46.0323527
        ],
        [
          9.2682173,
          46.0346934
        ],
        [
          9.2698901,
          46.0358775
        ],
        [
          9.2714336,
          46.0370528
        ],
        [
          9.2733924,
          46.0385512
        ],
        [
          9.2753121,
          46.0399776
        ],
        [
          9.2756396,
          46.0408589
        ],
        [
          9.2770578,
          46.0427455
        ],
        [
          9.2788137,
          46.0449552
        ],
        [
          9.2811836,
          46.0481805
        ],
        [
          9.2830966,
          46.0507138
        ],
        [
          9.2848017,
          46.0530047
        ],
        [
          9.2860899,
          46.0546395
        ],
        [
          9.2870138,
          46.0558162
        ],
        [
          9.28855,
          46.0578824
        ],
        [
          9.2896048,
          46.0593467
        ],
        [
          9.2896863,
          46.0600935
        ],
        [
          9.2899419,
          46.0617759
        ],
        [
          9.2902243,
          46.0638453
        ],
        [
          9.2905714,
          46.0659324
        ],
        [
          9.2907726,
          46.0672999
        ],
        [
          9.2902246,
          46.0688314
        ],
        [
          9.2897333,
          46.0713257
        ],
        [
          9.2893266,
          46.0726767
        ],
        [
          9.2889823,
          46.0736136
        ],
        [
          9.2898833,
          46.0752764
        ],
        [
          9.2902098,
          46.0758695
        ],
        [
          9.288231,
          46.0758475
        ],
        [
          9.2855158,
          46.0759443
        ],
        [
          9.2819846,
          46.0758091
        ],
        [
          9.279826,
          46.0760214
        ],
        [
          9.2784562,
          46.0762093
        ],
        [
          9.2784085,
          46.0762118
        ],
        [
          9.2783666,
          46.076214
        ],
        [
          9.2782776,
          46.0762187
        ],
        [
          9.2779184,
          46.0762331
        ],
        [
          9.2770598,
          46.0762712
        ],
        [
          9.2750045,
          46.0764471
        ],
        [
          9.2729359,
          46.0765601
        ],
        [
          9.2718231,
          46.0764367
        ],
        [
          9.2713722,
          46.0767888
        ],
        [
          9.270403,
          46.0769351
        ],
        [
          9.2693419,
          46.0768116
        ],
        [
          9.2682331,
          46.0775162
        ],
        [
          9.2672147,
          46.0781936
        ],
        [
          9.2670087,
          46.0783831
        ],
        [
          9.2659847,
          46.0778905
        ],
        [
          9.2650515,
          46.0774607
        ],
        [
          9.2644169,
          46.0772821
        ],
        [
          9.2640207,
          46.0782551
        ],
        [
          9.2633675,
          46.0795976
        ],
        [
          9.2626853,
          46.0802742
        ],
        [
          9.2618456,
          46.0804786
        ],
        [
          9.2606432,
          46.0805578
        ],
        [
          9.2610219,
          46.08134
        ],
        [
          9.2594045,
          46.0811727
        ],
        [
          9.2578642,
          46.0809152
        ],
        [
          9.2562465,
          46.0806938
        ],
        [
          9.2551715,
          46.0803632
        ],
        [
          9.2538902,
          46.08015
        ],
        [
          9.2532939,
          46.0798634
        ],
        [
          9.252195,
          46.0799558
        ],
        [
          9.2506953,
          46.0800671
        ],
        [
          9.2495421,
          46.0795836
        ],
        [
          9.2483515,
          46.0794152
        ],
        [
          9.2467906,
          46.0803006
        ],
        [
          9.2464679,
          46.0806063
        ],
        [
          9.2459148,
          46.0811305
        ],
        [
          9.2449452,
          46.0812226
        ],
        [
          9.2436382,
          46.0810634
        ],
        [
          9.2417245,
          46.0811574
        ],
        [
          9.2398618,
          46.0810804
        ],
        [
          9.23845,
          46.0806963
        ],
        [
          9.2374507,
          46.0798974
        ],
        [
          9.2357027,
          46.079415
        ],
        [
          9.2344208,
          46.0790486
        ],
        [
          9.2330608,
          46.0785654
        ],
        [
          9.232261,
          46.078576
        ],
        [
          9.2316642,
          46.0785862
        ],
        [
          9.229637,
          46.0793733
        ],
        [
          9.227636,
          46.0802594
        ],
        [
          9.2260098,
          46.0810726
        ],
        [
          9.2247055,
          46.0815612
        ],
        [
          9.2244343,
          46.0816787
        ],
        [
          9.224684,
          46.0825512
        ],
        [
          9.2250892,
          46.0835764
        ],
        [
          9.2251056,
          46.0844494
        ],
        [
          9.2249802,
          46.0854217
        ],
        [
          9.224648,
          46.0864124
        ],
        [
          9.2245358,
          46.0874656
        ],
        [
          9.2243985,
          46.0886809
        ],
        [
          9.2238222,
          46.090122
        ],
        [
          9.2234536,
          46.0917338
        ],
        [
          9.2234509,
          46.0942718
        ],
        [
          9.2232487,
          46.0954422
        ],
        [
          9.2226054,
          46.0963255
        ],
        [
          9.2215342,
          46.0969666
        ],
        [
          9.2209147,
          46.0973368
        ],
        [
          9.219918,
          46.0972127
        ],
        [
          9.218147,
          46.0975671
        ],
        [
          9.2147345,
          46.0982936
        ],
        [
          9.2132729,
          46.0983863
        ],
        [
          9.2113974,
          46.0984888
        ],
        [
          9.2103763,
          46.0987517
        ],
        [
          9.2085402,
          46.0990161
        ],
        [
          9.2074296,
          46.0995671
        ],
        [
          9.2066166,
          46.1000996
        ],
        [
          9.2054661,
          46.1003627
        ],
        [
          9.2050137,
          46.1004805
        ],
        [
          9.2044838,
          46.1006254
        ],
        [
          9.2038643,
          46.1010405
        ],
        [
          9.2033616,
          46.1015454
        ],
        [
          9.2029968,
          46.1018611
        ],
        [
          9.2026135,
          46.1022038
        ],
        [
          9.2020974,
          46.1025737
        ],
        [
          9.2019952,
          46.1029429
        ],
        [
          9.2021008,
          46.1035007
        ],
        [
          9.2020251,
          46.1040408
        ],
        [
          9.2015363,
          46.1043927
        ],
        [
          9.2005033,
          46.1049615
        ],
        [
          9.199342,
          46.1058456
        ],
        [
          9.1984776,
          46.1065671
        ],
        [
          9.1975997,
          46.1070906
        ],
        [
          9.1966699,
          46.1076322
        ],
        [
          9.1959861,
          46.1081734
        ],
        [
          9.1950826,
          46.108841
        ],
        [
          9.1941683,
          46.1097515
        ],
        [
          9.1933946,
          46.1105269
        ],
        [
          9.192427,
          46.1113475
        ],
        [
          9.1913816,
          46.1121503
        ],
        [
          9.190478,
          46.1127908
        ],
        [
          9.1889268,
          46.1129463
        ],
        [
          9.1872828,
          46.112796
        ],
        [
          9.1860138,
          46.1125461
        ],
        [
          9.1855867,
          46.1125288
        ],
        [
          9.1842537,
          46.112495
        ],
        [
          9.1828067,
          46.1128483
        ],
        [
          9.1814371,
          46.1134985
        ],
        [
          9.1801193,
          46.1141666
        ],
        [
          9.1786848,
          46.1147448
        ],
        [
          9.1766821,
          46.115369
        ],
        [
          9.1751181,
          46.1159834
        ],
        [
          9.1732176,
          46.1165893
        ],
        [
          9.1713939,
          46.116916
        ],
        [
          9.1696232,
          46.1171437
        ],
        [
          9.1673591,
          46.11739
        ],
        [
          9.164914,
          46.1176816
        ],
        [
          9.1621596,
          46.1180365
        ],
        [
          9.1598305,
          46.1181838
        ],
        [
          9.1575791,
          46.1183759
        ],
        [
          9.156637,
          46.1192952
        ],
        [
          9.1554365,
          46.1199989
        ],
        [
          9.154287,
          46.1208374
        ],
        [
          9.1532534,
          46.1214868
        ],
        [
          9.1520516,
          46.1221725
        ],
        [
          9.1504493,
          46.1227056
        ],
        [
          9.1484444,
          46.1231582
        ],
        [
          9.1462185,
          46.1233051
        ],
        [
          9.1449769,
          46.1236577
        ],
        [
          9.1450062,
          46.1245036
        ],
        [
          9.1449186,
          46.1256557
        ],
        [
          9.1447143,
          46.126727
        ],
        [
          9.1441478,
          46.1278617
        ],
        [
          9.1432057,
          46.1289789
        ],
        [
          9.141332,
          46.1302413
        ],
        [
          9.1404426,
          46.1310794
        ],
        [
          9.1387885,
          46.1322514
        ],
        [
          9.1365385,
          46.1332441
        ],
        [
          9.1344819,
          46.1339486
        ],
        [
          9.1329447,
          46.1348234
        ],
        [
          9.1321687,
          46.1351483
        ],
        [
          9.1311834,
          46.1345464
        ],
        [
          9.1301849,
          46.1338635
        ],
        [
          9.1292129,
          46.1333696
        ],
        [
          9.1279704,
          46.133524
        ],
        [
          9.1263417,
          46.1340389
        ],
        [
          9.124853,
          46.1340675
        ],
        [
          9.123558,
          46.1338889
        ],
        [
          9.1226523,
          46.1340879
        ],
        [
          9.122407,
          46.1343941
        ],
        [
          9.1219025,
          46.1345927
        ],
        [
          9.1213327,
          46.1345123
        ],
        [
          9.1208022,
          46.1346118
        ],
        [
          9.1203109,
          46.1347249
        ],
        [
          9.1190564,
          46.1351312
        ],
        [
          9.1181376,
          46.1353031
        ],
        [
          9.1171544,
          46.1354481
        ],
        [
          9.1164553,
          46.1354039
        ],
        [
          9.1159242,
          46.1352874
        ],
        [
          9.1156655,
          46.1351887
        ],
        [
          9.1153284,
          46.134955
        ],
        [
          9.1144473,
          46.1347714
        ],
        [
          9.1129855,
          46.1346738
        ],
        [
          9.1124408,
          46.1342424
        ],
        [
          9.1115722,
          46.1336312
        ],
        [
          9.110704,
          46.1331821
        ],
        [
          9.1092795,
          46.1329224
        ],
        [
          9.1077382,
          46.1325279
        ],
        [
          9.1062484,
          46.1319713
        ],
        [
          9.1051622,
          46.1317653
        ],
        [
          9.1044236,
          46.131388
        ],
        [
          9.1036976,
          46.1307766
        ],
        [
          9.1028293,
          46.1302554
        ],
        [
          9.1020644,
          46.1296351
        ],
        [
          9.1013774,
          46.1291047
        ],
        [
          9.1005994,
          46.1284034
        ],
        [
          9.1001577,
          46.1277467
        ],
        [
          9.1002476,
          46.1273417
        ],
        [
          9.1003375,
          46.1269546
        ],
        [
          9.0998973,
          46.126901
        ],
        [
          9.0991217,
          46.1272436
        ],
        [
          9.0980087,
          46.1273706
        ],
        [
          9.0969079,
          46.1268495
        ],
        [
          9.0957674,
          46.1262655
        ],
        [
          9.0940077,
          46.125925
        ],
        [
          9.0927128,
          46.125872
        ],
        [
          9.0914832,
          46.125612
        ],
        [
          9.0899556,
          46.1252532
        ],
        [
          9.08844,
          46.1247414
        ],
        [
          9.0870555,
          46.1242654
        ],
        [
          9.085837,
          46.1233664
        ],
        [
          9.0853186,
          46.1229077
        ],
        [
          9.0842443,
          46.1226295
        ],
        [
          9.0829752,
          46.1222165
        ],
        [
          9.0820043,
          46.1217942
        ],
        [
          9.0810589,
          46.1216598
        ],
        [
          9.0804633,
          46.1215073
        ],
        [
          9.0797135,
          46.1213368
        ],
        [
          9.0786775,
          46.1211755
        ],
        [
          9.0784706,
          46.1210496
        ],
        [
          9.0770668,
          46.1213746
        ],
        [
          9.0765036,
          46.121249
        ],
        [
          9.0752745,
          46.1206918
        ],
        [
          9.0741058,
          46.1201795
        ],
        [
          9.0735391,
          46.1198739
        ],
        [
          9.0725395,
          46.1196675
        ],
        [
          9.0716349,
          46.1195151
        ],
        [
          9.0715198,
          46.1192902
        ],
        [
          9.072072,
          46.1181828
        ],
        [
          9.0719826,
          46.1172289
        ],
        [
          9.0721371,
          46.1165628
        ],
        [
          9.0724193,
          46.1156086
        ],
        [
          9.0728299,
          46.1147623
        ],
        [
          9.0733755,
          46.1136099
        ],
        [
          9.0739273,
          46.1128176
        ],
        [
          9.0746891,
          46.1121961
        ],
        [
          9.075248,
          46.1116107
        ],
        [
          9.0758683,
          46.1110163
        ],
        [
          9.0780883,
          46.1098223
        ],
        [
          9.0783885,
          46.1096196
        ],
        [
          9.0792917,
          46.1077739
        ],
        [
          9.0801428,
          46.1057933
        ],
        [
          9.0807595,
          46.1035968
        ],
        [
          9.0816882,
          46.1016972
        ],
        [
          9.082708,
          46.1001214
        ],
        [
          9.083844,
          46.0985006
        ],
        [
          9.0844897,
          46.0976811
        ],
        [
          9.0853661,
          46.0963304
        ],
        [
          9.0863081,
          46.0948087
        ],
        [
          9.0868762,
          46.0940792
        ],
        [
          9.0873792,
          46.0931518
        ],
        [
          9.0882952,
          46.0915761
        ],
        [
          9.0892112,
          46.0900724
        ],
        [
          9.0898425,
          46.0884878
        ],
        [
          9.0899315,
          46.0875698
        ],
        [
          9.0899416,
          46.0868317
        ],
        [
          9.0897982,
          46.0860848
        ],
        [
          9.0896162,
          46.085518
        ],
        [
          9.0894343,
          46.0850231
        ],
        [
          9.0893434,
          46.0848432
        ],
        [
          9.0889291,
          46.0845465
        ],
        [
          9.0884127,
          46.0841599
        ],
        [
          9.0880371,
          46.0838452
        ],
        [
          9.0879074,
          46.0836113
        ],
        [
          9.0878685,
          46.0835753
        ],
        [
          9.0875706,
          46.0832965
        ],
        [
          9.0864827,
          46.0823523
        ],
        [
          9.0855371,
          46.081408
        ],
        [
          9.0846303,
          46.0804817
        ],
        [
          9.0839698,
          46.0798702
        ],
        [
          9.0837878,
          46.0792493
        ],
        [
          9.0837223,
          46.0786823
        ],
        [
          9.0833334,
          46.0780886
        ],
        [
          9.0828155,
          46.077702
        ],
        [
          9.0819225,
          46.0772346
        ],
        [
          9.08081,
          46.0770104
        ],
        [
          9.0797878,
          46.0766601
        ],
        [
          9.0793733,
          46.0762014
        ],
        [
          9.0787647,
          46.0756348
        ],
        [
          9.0784274,
          46.07487
        ],
        [
          9.078375,
          46.074366
        ],
        [
          9.0783608,
          46.07352
        ],
        [
          9.0783082,
          46.0729081
        ],
        [
          9.0783978,
          46.072251
        ],
        [
          9.0782679,
          46.0717921
        ],
        [
          9.0780132,
          46.0711802
        ],
        [
          9.077881,
          46.0708608
        ],
        [
          9.0776338,
          46.0697765
        ],
        [
          9.0777362,
          46.0689844
        ],
        [
          9.0780123,
          46.0684892
        ],
        [
          9.0781465,
          46.0682551
        ],
        [
          9.0783392,
          46.0673549
        ],
        [
          9.0782086,
          46.066455
        ],
        [
          9.0780656,
          46.0659421
        ],
        [
          9.078012,
          46.0657351
        ],
        [
          9.0779487,
          46.0655462
        ],
        [
          9.0778724,
          46.0653167
        ],
        [
          9.0771608,
          46.0649932
        ],
        [
          9.0766947,
          46.0644805
        ],
        [
          9.0760733,
          46.0639229
        ],
        [
          9.0751163,
          46.0637435
        ],
        [
          9.0742231,
          46.0629701
        ],
        [
          9.0733694,
          46.0626196
        ],
        [
          9.0725166,
          46.0627462
        ],
        [
          9.0714565,
          46.0627649
        ],
        [
          9.0705772,
          46.0627294
        ],
        [
          9.0691811,
          46.0625863
        ],
        [
          9.0679654,
          46.062236
        ],
        [
          9.0667756,
          46.0619487
        ],
        [
          9.0654574,
          46.0620664
        ],
        [
          9.0644487,
          46.061923
        ],
        [
          9.063065,
          46.0615818
        ],
        [
          9.0615782,
          46.0612316
        ],
        [
          9.0604143,
          46.0609262
        ],
        [
          9.0594833,
          46.0608817
        ],
        [
          9.0584368,
          46.0610982
        ],
        [
          9.0576998,
          46.0611166
        ],
        [
          9.0567438,
          46.0613691
        ],
        [
          9.055852,
          46.0616755
        ],
        [
          9.0549739,
          46.062306
        ],
        [
          9.0537586,
          46.0623335
        ],
        [
          9.0525951,
          46.0624241
        ],
        [
          9.0514448,
          46.0624336
        ],
        [
          9.0504106,
          46.0625061
        ],
        [
          9.0493762,
          46.0624075
        ],
        [
          9.0484325,
          46.0620569
        ],
        [
          9.0477727,
          46.0616612
        ],
        [
          9.0469836,
          46.0611125
        ],
        [
          9.0462719,
          46.0603568
        ],
        [
          9.0453275,
          46.0596461
        ],
        [
          9.0446809,
          46.0593674
        ],
        [
          9.0440345,
          46.0593316
        ],
        [
          9.0429361,
          46.0594761
        ],
        [
          9.0421603,
          46.0593954
        ],
        [
          9.0413587,
          46.0593146
        ],
        [
          9.0402985,
          46.059216
        ],
        [
          9.0396395,
          46.0590902
        ],
        [
          9.0388247,
          46.0586225
        ],
        [
          9.0378805,
          46.0579838
        ],
        [
          9.0368848,
          46.0576151
        ],
        [
          9.0358115,
          46.0570665
        ],
        [
          9.0350097,
          46.0566617
        ],
        [
          9.0342472,
          46.0563919
        ],
        [
          9.0333162,
          46.0559782
        ],
        [
          9.0324884,
          46.0553124
        ],
        [
          9.0314021,
          46.0546737
        ],
        [
          9.0304841,
          46.054233
        ],
        [
          9.0297339,
          46.0534501
        ],
        [
          9.0288159,
          46.0531714
        ],
        [
          9.0276528,
          46.0529106
        ],
        [
          9.026541,
          46.0527309
        ],
        [
          9.0257394,
          46.0524881
        ],
        [
          9.0249509,
          46.0525063
        ],
        [
          9.0238138,
          46.0526145
        ],
        [
          9.0229478,
          46.0526507
        ],
        [
          9.0220816,
          46.0523809
        ],
        [
          9.0211898,
          46.051841
        ],
        [
          9.0203236,
          46.0515532
        ],
        [
          9.019535,
          46.0511933
        ],
        [
          9.018811,
          46.0508514
        ],
        [
          9.0181775,
          46.0505365
        ],
        [
          9.0173372,
          46.0501586
        ],
        [
          9.0167942,
          46.0496997
        ],
        [
          9.0162898,
          46.0489258
        ],
        [
          9.0159792,
          46.0476388
        ],
        [
          9.0157206,
          46.0470538
        ],
        [
          9.0157717,
          46.0464418
        ],
        [
          9.0163143,
          46.0456767
        ],
        [
          9.0167665,
          46.0450286
        ],
        [
          9.0168823,
          46.0444616
        ],
        [
          9.0166365,
          46.0439306
        ],
        [
          9.0165071,
          46.0433006
        ],
        [
          9.0161709,
          46.0427246
        ],
        [
          9.0156926,
          46.0424007
        ],
        [
          9.0149687,
          46.0418608
        ],
        [
          9.0141543,
          46.0411948
        ],
        [
          9.013327,
          46.0407179
        ],
        [
          9.0123835,
          46.040061
        ],
        [
          9.0115566,
          46.0394581
        ],
        [
          9.0109357,
          46.0388011
        ],
        [
          9.0102511,
          46.0381892
        ],
        [
          9.0099379,
          46.0378759
        ],
        [
          9.0096307,
          46.0375682
        ],
        [
          9.0089715,
          46.0369833
        ],
        [
          9.0088805,
          46.0362003
        ],
        [
          9.0088803,
          46.0349852
        ],
        [
          9.0087897,
          46.0340852
        ],
        [
          9.0086211,
          46.0329692
        ],
        [
          9.008453,
          46.0320782
        ],
        [
          9.0082332,
          46.0312772
        ],
        [
          9.0078325,
          46.0303592
        ],
        [
          9.0075094,
          46.0300352
        ],
        [
          9.0071993,
          46.0297022
        ],
        [
          9.0074319,
          46.0296392
        ],
        [
          9.0081679,
          46.0291351
        ],
        [
          9.00862,
          46.0283701
        ],
        [
          9.0090459,
          46.027713
        ],
        [
          9.0090975,
          46.027173
        ],
        [
          9.0089682,
          46.026894
        ],
        [
          9.0091233,
          46.026723
        ],
        [
          9.0101306,
          46.0261379
        ],
        [
          9.0110995,
          46.0254718
        ],
        [
          9.0119517,
          46.0248327
        ],
        [
          9.0129334,
          46.0241126
        ],
        [
          9.0136176,
          46.0235725
        ],
        [
          9.0145218,
          46.0226273
        ],
        [
          9.0149734,
          46.0221233
        ],
        [
          9.0155547,
          46.0216912
        ],
        [
          9.0165748,
          46.021304
        ],
        [
          9.0172336,
          46.0208359
        ],
        [
          9.018331,
          46.0198457
        ],
        [
          9.0193126,
          46.0191165
        ],
        [
          9.0201903,
          46.0181983
        ],
        [
          9.020991,
          46.0174872
        ],
        [
          9.0216105,
          46.0169291
        ],
        [
          9.0219592,
          46.016677
        ],
        [
          9.0222867,
          46.0165425
        ],
        [
          9.0224088,
          46.0164924
        ],
        [
          9.0224629,
          46.0164699
        ],
        [
          9.0225396,
          46.0156058
        ],
        [
          9.0228354,
          46.0132297
        ],
        [
          9.0231057,
          46.0110246
        ],
        [
          9.0233501,
          46.0095665
        ],
        [
          9.0236073,
          46.0078294
        ],
        [
          9.0237615,
          46.0060293
        ],
        [
          9.0240447,
          46.0045802
        ],
        [
          9.0242508,
          46.0034011
        ],
        [
          9.0244047,
          46.00197
        ],
        [
          9.0244043,
          46.001079
        ],
        [
          9.0244531,
          46.0007955
        ],
        [
          9.0244896,
          46.0005815
        ],
        [
          9.0245199,
          46.000404
        ],
        [
          9.0246332,
          46.00026
        ],
        [
          9.0252556,
          45.9994678
        ],
        [
          9.0258234,
          45.9985226
        ],
        [
          9.0264298,
          45.9973615
        ],
        [
          9.0272426,
          45.9957412
        ],
        [
          9.027913,
          45.994418
        ],
        [
          9.0282871,
          45.9936619
        ],
        [
          9.0277064,
          45.99359
        ],
        [
          9.0261825,
          45.9933024
        ],
        [
          9.0243104,
          45.9929788
        ],
        [
          9.0226445,
          45.9926731
        ],
        [
          9.022541,
          45.9920701
        ],
        [
          9.0223857,
          45.9912151
        ],
        [
          9.0223595,
          45.9902476
        ],
        [
          9.0219206,
          45.9894377
        ],
        [
          9.0211585,
          45.9889248
        ],
        [
          9.0196218,
          45.98834
        ],
        [
          9.0182147,
          45.9879263
        ],
        [
          9.017143,
          45.9878274
        ],
        [
          9.0155941,
          45.9879357
        ],
        [
          9.0140319,
          45.9885344
        ],
        [
          9.0127799,
          45.9887055
        ],
        [
          9.0119793,
          45.9882016
        ],
        [
          9.0112303,
          45.9877607
        ],
        [
          9.0111915,
          45.9870991
        ],
        [
          9.0111396,
          45.9862621
        ],
        [
          9.0108945,
          45.9850471
        ],
        [
          9.0108297,
          45.9837331
        ],
        [
          9.0108683,
          45.982986
        ],
        [
          9.0103001,
          45.9825091
        ],
        [
          9.0090993,
          45.9819512
        ],
        [
          9.0081184,
          45.9809882
        ],
        [
          9.0068532,
          45.9802503
        ],
        [
          9.0055751,
          45.9801873
        ],
        [
          9.004504,
          45.9798364
        ],
        [
          9.0030064,
          45.9796294
        ],
        [
          9.0019353,
          45.9800524
        ],
        [
          9.0006573,
          45.9803495
        ],
        [
          8.9990698,
          45.9803225
        ],
        [
          8.996888,
          45.9804574
        ],
        [
          8.99552,
          45.9804304
        ],
        [
          8.995378,
          45.9796293
        ],
        [
          8.9957396,
          45.9788193
        ],
        [
          8.995533,
          45.9782613
        ],
        [
          8.9953523,
          45.9777123
        ],
        [
          8.9950426,
          45.9770552
        ],
        [
          8.9951201,
          45.9765692
        ],
        [
          8.9958043,
          45.9757502
        ],
        [
          8.996011,
          45.9746792
        ],
        [
          8.9959593,
          45.9739592
        ],
        [
          8.9958949,
          45.9727891
        ],
        [
          8.9954174,
          45.9718801
        ],
        [
          8.9945268,
          45.971682
        ],
        [
          8.9935332,
          45.971826
        ],
        [
          8.9923844,
          45.9723479
        ],
        [
          8.9917132,
          45.9721409
        ],
        [
          8.9907714,
          45.9713938
        ],
        [
          8.9898292,
          45.9713487
        ],
        [
          8.9890287,
          45.9721046
        ],
        [
          8.9884095,
          45.9726086
        ],
        [
          8.9880739,
          45.9724106
        ],
        [
          8.9882548,
          45.9715645
        ],
        [
          8.9887709,
          45.9705836
        ],
        [
          8.9893777,
          45.9696476
        ],
        [
          8.9901523,
          45.9690626
        ],
        [
          8.9914818,
          45.9681807
        ],
        [
          8.9919164,
          45.9678591
        ],
        [
          8.9927465,
          45.9672448
        ],
        [
          8.9939856,
          45.9665878
        ],
        [
          8.9956115,
          45.9661649
        ],
        [
          8.9972635,
          45.9656609
        ],
        [
          8.9995346,
          45.9650129
        ],
        [
          9.0020122,
          45.9644279
        ],
        [
          9.0042058,
          45.9639058
        ],
        [
          9.0062965,
          45.9631677
        ],
        [
          9.008761,
          45.962362
        ],
        [
          9.0112641,
          45.9614977
        ],
        [
          9.0135349,
          45.9606694
        ],
        [
          9.0140639,
          45.9601564
        ],
        [
          9.0142441,
          45.9584103
        ],
        [
          9.0144113,
          45.9564572
        ],
        [
          9.0146559,
          45.9541171
        ],
        [
          9.0151199,
          45.9520199
        ],
        [
          9.0151062,
          45.9505574
        ],
        [
          9.0163188,
          45.9496841
        ],
        [
          9.0163962,
          45.9495581
        ],
        [
          9.0166282,
          45.9489191
        ],
        [
          9.0166152,
          45.9485051
        ],
        [
          9.0160473,
          45.9476231
        ],
        [
          9.0154148,
          45.9468852
        ],
        [
          9.0146146,
          45.9458412
        ],
        [
          9.0134802,
          45.9446533
        ],
        [
          9.0129124,
          45.9440324
        ],
        [
          9.0127703,
          45.9434023
        ],
        [
          9.0128218,
          45.9430513
        ],
        [
          9.0129378,
          45.9423313
        ],
        [
          9.0131828,
          45.9417372
        ],
        [
          9.0139696,
          45.9414671
        ],
        [
          9.0146403,
          45.940855
        ],
        [
          9.0152453,
          45.9403059
        ],
        [
          9.0162644,
          45.9403238
        ],
        [
          9.0174512,
          45.9400536
        ],
        [
          9.0189734,
          45.9397833
        ],
        [
          9.0195149,
          45.9388742
        ],
        [
          9.0199274,
          45.9380821
        ],
        [
          9.0213846,
          45.9377038
        ],
        [
          9.0218489,
          45.9376497
        ],
        [
          9.0218745,
          45.9370107
        ],
        [
          9.0218226,
          45.9362997
        ],
        [
          9.0214741,
          45.9356067
        ],
        [
          9.0209196,
          45.9350668
        ],
        [
          9.0204808,
          45.9345719
        ],
        [
          9.0201839,
          45.9337979
        ],
        [
          9.0199644,
          45.9333119
        ],
        [
          9.0201447,
          45.9326818
        ],
        [
          9.0198994,
          45.9319438
        ],
        [
          9.0197315,
          45.9312959
        ],
        [
          9.0194604,
          45.9306209
        ],
        [
          9.0194472,
          45.9297658
        ],
        [
          9.0196533,
          45.9290728
        ],
        [
          9.0193952,
          45.9285778
        ],
        [
          9.0189577,
          45.9282088
        ],
        [
          9.0201174,
          45.9283527
        ],
        [
          9.0211751,
          45.9285685
        ],
        [
          9.022284,
          45.9287573
        ],
        [
          9.0234835,
          45.928838
        ],
        [
          9.024683,
          45.9287748
        ],
        [
          9.0258304,
          45.9285315
        ],
        [
          9.0267842,
          45.9281983
        ],
        [
          9.0281123,
          45.9274689
        ],
        [
          9.0291308,
          45.9266046
        ],
        [
          9.0293887,
          45.9264426
        ],
        [
          9.0303689,
          45.9265143
        ],
        [
          9.0317098,
          45.92655
        ],
        [
          9.0332704,
          45.9265045
        ],
        [
          9.0347918,
          45.9263241
        ],
        [
          9.0363781,
          45.9263055
        ],
        [
          9.0372671,
          45.9261703
        ],
        [
          9.0380153,
          45.926359
        ],
        [
          9.0391892,
          45.9266646
        ],
        [
          9.0406961,
          45.9269319
        ],
        [
          9.041898,
          45.9271497
        ],
        [
          9.0430322,
          45.9267353
        ],
        [
          9.0440121,
          45.9262579
        ],
        [
          9.0451592,
          45.9253349
        ],
        [
          9.0459572,
          45.924376
        ],
        [
          9.0464339,
          45.9237818
        ],
        [
          9.047465,
          45.9230163
        ],
        [
          9.0489088,
          45.9223947
        ],
        [
          9.0494756,
          45.9216654
        ],
        [
          9.0496042,
          45.9212153
        ],
        [
          9.0491008,
          45.9207565
        ],
        [
          9.0489456,
          45.9202166
        ],
        [
          9.0496031,
          45.9199643
        ],
        [
          9.0512023,
          45.9200626
        ],
        [
          9.0524275,
          45.920215
        ],
        [
          9.0542716,
          45.9202232
        ],
        [
          9.0557148,
          45.9202585
        ],
        [
          9.0576106,
          45.9204016
        ],
        [
          9.0591583,
          45.9205358
        ],
        [
          9.0596741,
          45.9205355
        ],
        [
          9.0595571,
          45.9196085
        ],
        [
          9.0594396,
          45.9182585
        ],
        [
          9.0591938,
          45.9175116
        ],
        [
          9.0590437,
          45.9169357
        ],
        [
          9.0589908,
          45.9168727
        ],
        [
          9.0587673,
          45.9166388
        ],
        [
          9.0588315,
          45.9163958
        ],
        [
          9.0592698,
          45.9162245
        ],
        [
          9.060159,
          45.915765
        ],
        [
          9.061319,
          45.9152244
        ],
        [
          9.0624921,
          45.9149267
        ],
        [
          9.0635753,
          45.9149892
        ],
        [
          9.0648256,
          45.9146284
        ],
        [
          9.0661531,
          45.9140966
        ],
        [
          9.0674276,
          45.9133579
        ],
        [
          9.0682385,
          45.9131864
        ],
        [
          9.0681658,
          45.9130604
        ],
        [
          9.0677867,
          45.9124126
        ],
        [
          9.0672429,
          45.9108559
        ],
        [
          9.0677844,
          45.9108825
        ],
        [
          9.0689869,
          45.9106928
        ],
        [
          9.0689883,
          45.9118269
        ],
        [
          9.0698605,
          45.9119703
        ],
        [
          9.0707629,
          45.9121768
        ],
        [
          9.0711544,
          45.9126806
        ],
        [
          9.0716962,
          45.9129142
        ],
        [
          9.0721774,
          45.9129139
        ],
        [
          9.0730797,
          45.9127064
        ],
        [
          9.0735001,
          45.9128321
        ],
        [
          9.0750925,
          45.911886
        ],
        [
          9.0755735,
          45.9117957
        ],
        [
          9.0763245,
          45.9114397
        ],
        [
          9.0763235,
          45.9107691
        ],
        [
          9.0765033,
          45.91022
        ],
        [
          9.0770132,
          45.9095311
        ],
        [
          9.0769534,
          45.9093647
        ],
        [
          9.0767723,
          45.9089418
        ],
        [
          9.0749645,
          45.9062968
        ],
        [
          9.0762142,
          45.9036004
        ],
        [
          9.0762544,
          45.9035148
        ],
        [
          9.0762555,
          45.9034879
        ],
        [
          9.0762595,
          45.9034428
        ],
        [
          9.0763097,
          45.9027363
        ],
        [
          9.0764504,
          45.9016156
        ],
        [
          9.0765131,
          45.9003555
        ],
        [
          9.0766794,
          45.8993834
        ],
        [
          9.0780178,
          45.8998325
        ],
        [
          9.0789222,
          45.9000929
        ],
        [
          9.0800571,
          45.9004341
        ],
        [
          9.0816433,
          45.900829
        ],
        [
          9.0832167,
          45.9012329
        ],
        [
          9.0851513,
          45.9018165
        ],
        [
          9.0865828,
          45.9021304
        ],
        [
          9.08724,
          45.9019949
        ],
        [
          9.0881155,
          45.9012742
        ],
        [
          9.0887576,
          45.9007157
        ],
        [
          9.0884685,
          45.9003109
        ],
        [
          9.0884233,
          45.9002479
        ],
        [
          9.0880748,
          45.8999872
        ],
        [
          9.0875584,
          45.8995106
        ],
        [
          9.087673,
          45.8985744
        ],
        [
          9.0883414,
          45.8973679
        ],
        [
          9.088212,
          45.8970799
        ],
        [
          9.0867288,
          45.89655
        ],
        [
          9.0853358,
          45.8960201
        ],
        [
          9.0851412,
          45.8951742
        ],
        [
          9.0847014,
          45.8941844
        ],
        [
          9.0836947,
          45.8933211
        ],
        [
          9.0827403,
          45.8929528
        ],
        [
          9.0817724,
          45.8921795
        ],
        [
          9.0811789,
          45.8917839
        ],
        [
          9.080148,
          45.8919286
        ],
        [
          9.0784726,
          45.8920918
        ],
        [
          9.0780183,
          45.8918221
        ],
        [
          9.0777889,
          45.8916782
        ],
        [
          9.0776732,
          45.8909943
        ],
        [
          9.0780181,
          45.890535
        ],
        [
          9.0785982,
          45.8897696
        ],
        [
          9.0793961,
          45.888914
        ],
        [
          9.079524,
          45.8882029
        ],
        [
          9.0787888,
          45.8878074
        ],
        [
          9.0783243,
          45.8874567
        ],
        [
          9.078018,
          45.8869709
        ],
        [
          9.0778214,
          45.8863769
        ],
        [
          9.0777941,
          45.8855939
        ],
        [
          9.0770971,
          45.8848114
        ],
        [
          9.0762843,
          45.8841639
        ],
        [
          9.074982,
          45.8834087
        ],
        [
          9.0734603,
          45.8826626
        ],
        [
          9.0723002,
          45.8821414
        ],
        [
          9.071333,
          45.8815749
        ],
        [
          9.0708816,
          45.8813142
        ],
        [
          9.069875,
          45.8799198
        ],
        [
          9.0698357,
          45.8793437
        ],
        [
          9.0689713,
          45.8785162
        ],
        [
          9.0677973,
          45.8774279
        ],
        [
          9.0666237,
          45.8762405
        ],
        [
          9.0661462,
          45.8756558
        ],
        [
          9.0655538,
          45.8760071
        ],
        [
          9.0648837,
          45.8759355
        ],
        [
          9.063866,
          45.875765
        ],
        [
          9.0624229,
          45.8758648
        ],
        [
          9.0614044,
          45.8753884
        ],
        [
          9.0604896,
          45.8751099
        ],
        [
          9.0588018,
          45.8752817
        ],
        [
          9.0569338,
          45.8753007
        ],
        [
          9.0562686,
          45.8749371
        ],
        [
          9.0558638,
          45.8747162
        ],
        [
          9.0546778,
          45.8737627
        ],
        [
          9.0540069,
          45.872899
        ],
        [
          9.0543155,
          45.8722868
        ],
        [
          9.0545855,
          45.8716746
        ],
        [
          9.0537599,
          45.870586
        ],
        [
          9.0528959,
          45.8698123
        ],
        [
          9.0524189,
          45.8691645
        ],
        [
          9.052869,
          45.8685973
        ],
        [
          9.0526629,
          45.8682824
        ],
        [
          9.0517348,
          45.8677788
        ],
        [
          9.0511415,
          45.867032
        ],
        [
          9.0504579,
          45.8661863
        ],
        [
          9.0499808,
          45.8653224
        ],
        [
          9.0498124,
          45.8643054
        ],
        [
          9.0495542,
          45.8636395
        ],
        [
          9.048858,
          45.8630548
        ],
        [
          9.048471,
          45.8625059
        ],
        [
          9.0485215,
          45.8612548
        ],
        [
          9.0487911,
          45.8602466
        ],
        [
          9.0488806,
          45.8594816
        ],
        [
          9.0490087,
          45.8586085
        ],
        [
          9.0495878,
          45.8579422
        ],
        [
          9.0503468,
          45.8569158
        ],
        [
          9.0507452,
          45.8558806
        ],
        [
          9.0509506,
          45.8550524
        ],
        [
          9.0507054,
          45.8546115
        ],
        [
          9.0499065,
          45.8542068
        ],
        [
          9.0490819,
          45.8540002
        ],
        [
          9.0483222,
          45.8537755
        ],
        [
          9.047871,
          45.8533706
        ],
        [
          9.0483861,
          45.8532444
        ],
        [
          9.0491453,
          45.8529021
        ],
        [
          9.0494285,
          45.8526769
        ],
        [
          9.0493251,
          45.852245
        ],
        [
          9.0496985,
          45.8520918
        ],
        [
          9.0500329,
          45.8516146
        ],
        [
          9.0503803,
          45.8512184
        ],
        [
          9.0511529,
          45.8509121
        ],
        [
          9.052105,
          45.8503266
        ],
        [
          9.05307,
          45.8493181
        ],
        [
          9.0535068,
          45.8481568
        ],
        [
          9.0538537,
          45.8472386
        ],
        [
          9.0541106,
          45.8466085
        ],
        [
          9.0553464,
          45.8464054
        ],
        [
          9.0557967,
          45.8459191
        ],
        [
          9.0556802,
          45.8453521
        ],
        [
          9.0565558,
          45.8452347
        ],
        [
          9.0568516,
          45.8447755
        ],
        [
          9.0566964,
          45.8441096
        ],
        [
          9.0566657,
          45.8437974
        ],
        [
          9.0566183,
          45.8433176
        ],
        [
          9.0563345,
          45.8428227
        ],
        [
          9.0563559,
          45.8425077
        ],
        [
          9.0563852,
          45.8420756
        ],
        [
          9.0563666,
          45.8420261
        ],
        [
          9.0562432,
          45.8416976
        ],
        [
          9.0557666,
          45.8411669
        ],
        [
          9.0555606,
          45.8409571
        ],
        [
          9.0563228,
          45.8407823
        ],
        [
          9.0567721,
          45.8408706
        ],
        [
          9.0570907,
          45.8409924
        ],
        [
          9.0570741,
          45.8408613
        ],
        [
          9.0569065,
          45.8405755
        ],
        [
          9.0566664,
          45.8399873
        ],
        [
          9.0565937,
          45.8397524
        ],
        [
          9.0564855,
          45.8394024
        ],
        [
          9.0562396,
          45.8386735
        ],
        [
          9.0571715,
          45.838628
        ],
        [
          9.0572435,
          45.8386238
        ],
        [
          9.0574625,
          45.8386124
        ],
        [
          9.0576456,
          45.8386028
        ],
        [
          9.059085,
          45.838528
        ],
        [
          9.0597161,
          45.8384969
        ],
        [
          9.0612995,
          45.8384189
        ],
        [
          9.0624678,
          45.8382787
        ],
        [
          9.063089,
          45.8382019
        ],
        [
          9.0641703,
          45.8379133
        ],
        [
          9.0647754,
          45.8377509
        ],
        [
          9.0658307,
          45.8374263
        ],
        [
          9.0669378,
          45.8372006
        ],
        [
          9.0673181,
          45.8371217
        ],
        [
          9.0675262,
          45.8370788
        ],
        [
          9.067662,
          45.8370517
        ],
        [
          9.0682638,
          45.8369298
        ],
        [
          9.0699756,
          45.8365238
        ],
        [
          9.0712495,
          45.8357849
        ],
        [
          9.0726004,
          45.835064
        ],
        [
          9.0727172,
          45.835028
        ],
        [
          9.0728571,
          45.8349845
        ],
        [
          9.0729736,
          45.8349468
        ],
        [
          9.0735452,
          45.8347669
        ],
        [
          9.0736275,
          45.8347367
        ],
        [
          9.0746083,
          45.8345137
        ],
        [
          9.0754448,
          45.8343332
        ],
        [
          9.0761784,
          45.8341077
        ],
        [
          9.0764678,
          45.834022
        ],
        [
          9.0765596,
          45.8339945
        ],
        [
          9.0772135,
          45.8337979
        ],
        [
          9.0778784,
          45.833598
        ],
        [
          9.0789225,
          45.8330257
        ],
        [
          9.0809669,
          45.8323133
        ],
        [
          9.0833228,
          45.831593
        ],
        [
          9.0854451,
          45.8309509
        ],
        [
          9.0869504,
          45.8303557
        ],
        [
          9.088353,
          45.8299496
        ],
        [
          9.0894983,
          45.8296157
        ],
        [
          9.090749,
          45.8294005
        ],
        [
          9.0908726,
          45.8293989
        ],
        [
          9.0902583,
          45.8301372
        ],
        [
          9.0893461,
          45.8310649
        ],
        [
          9.0886266,
          45.8320375
        ],
        [
          9.0882932,
          45.8326318
        ],
        [
          9.0880876,
          45.832911
        ],
        [
          9.0857835,
          45.8335968
        ],
        [
          9.08653,
          45.8346763
        ],
        [
          9.0870334,
          45.835738
        ],
        [
          9.0878208,
          45.8369615
        ],
        [
          9.0886209,
          45.8380859
        ],
        [
          9.0897173,
          45.8394531
        ],
        [
          9.0903498,
          45.8403887
        ],
        [
          9.0911748,
          45.8409371
        ],
        [
          9.0925279,
          45.841782
        ],
        [
          9.094461,
          45.8425815
        ],
        [
          9.0956719,
          45.8430665
        ],
        [
          9.0961237,
          45.8436692
        ],
        [
          9.0967052,
          45.8448028
        ],
        [
          9.0976599,
          45.846035
        ],
        [
          9.0985248,
          45.8471773
        ],
        [
          9.095577,
          45.8480124
        ]
      ]
    ]
  }
}
